import { useState } from "react";
import { Box, Button } from "@mui/material";
import usePostMessage from "../components/play/usePostMessage.js";
import {
  //   dinoConfig,
  //   dinoEmbodiment,
  creepsConfig,
  creepsEmbodiment,
} from "../util/configs.js";

const PostMessageTest = () => {
  const config = creepsConfig;
  const embodiment = creepsEmbodiment;
  const clusterId = "feagi-k8s-staging";
  const sessionId = "user-cuwohhrnvvjufrbkczoo";
  // Postmessage
  const [sendMessage, setSendMessage] = useState(null);
  usePostMessage(false, embodiment?.embodiment_id, setSendMessage);

  const handleSend = () => {
    const message = JSON.stringify({ motion_control: { 0: { move_up: 1.0 } } });
    console.log("sending message!");
    sendMessage(message);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
      }}
    >
      <Button variant="contained" onClick={handleSend}>
        Send Message
      </Button>

      <iframe
        title={embodiment.embodiment_id}
        sandbox="allow-scripts allow-popups allow-same-origin allow-forms allow-top-navigation-by-user-activation allow-top-navigation allow-modals allow-popups-to-escape-sandbox"
        allow="cross-origin-isolation"
        src={`${
          // @ts-ignore
          process.env.PUBLIC_URL
        }/static/${
          embodiment.embodiment_id
        }/index.html?feagi_url=${sessionId}-feagi.${clusterId}.neurorobotics.studio&port_disabled=true&ws_url=wss://${sessionId}-feagi.${clusterId}.neurorobotics.studio/p9055&http_type=HTTPS://&muted=false&capabilities=input%2Ecamera%2E0%2Efeagi_index%3D${
          config.player_1.capabilities?.input?.camera?.[0]?.feagi_index
        }%7Coutput%2Emotion_control%2E0%2Efeagi_index%3D${
          config.player_1.capabilities?.output?.motion_control?.[0]?.feagi_index
        }%7Coutput%2Emotor%2E0%2Efeagi_index%3D${
          config.player_1.capabilities?.output?.motor?.[0]?.feagi_index
        }&player=1&postmessage=1`}
        style={{
          width: "400px",
          aspectRatio: 1,
        }}
      />
    </Box>
  );
};

export default PostMessageTest;
