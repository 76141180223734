import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import { requireAuth, useAuth } from "../util/auth";
import { logger } from "../util/logger";
import {
  getExperimentIdBySessionId,
  getSessionFeagiStatus,
} from "../api/feagiSessionManagement";
import { getExperimentInfo } from "../api/experimentManagement";

const NewWindowEmbodiment = () => {
  const auth = useAuth();
  const location = useLocation();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get("session_id")?.toLowerCase();
  const clusterId = searchParams.get("cluster_id")?.toLowerCase();
  const [embodimentId, setEmbodimentId] = useState("");
  // const [sessionClosed, setSessionClosed] = useState(false);
  // const allowableDelay = 30000;

  // Interval to check FEAGI session & store game open state
  // function startInterval() {
  //   const interval = setInterval(() => {
  //     // Function to clear interval, close iframe, & remove storage
  //     const shutdown = () => {
  //       clearInterval(interval);
  //       // localStorage.removeItem("gameOpen");
  //       // localStorage.removeItem("sessionOpen");
  //       setSessionClosed(true);
  //       return;
  //     };

  //     // Get data from storage
  //     const gameTime = new Date().getTime();
  //     const sessionOpen = localStorage.getItem("sessionOpen");
  //     const sessionOpenData = sessionOpen ? JSON.parse(sessionOpen) : undefined;
  //     logger("sessionOpenData:", sessionOpenData);
  //     // Stop if data not found
  //     if (!sessionOpenData) {
  //       console.log("no session data found");
  //       shutdown();
  //       return;
  //     }
  //     // Parse data
  //     const { sessionTime, sessionId: storedSessionId } = sessionOpenData;
  //     // Stop the interval if missing data
  //     if (!sessionTime || !storedSessionId) {
  //       console.log("No session time or session ID found.");
  //       shutdown();
  //       return;
  //     }
  //     // Stop the interval if session ID mismatch
  //     if (storedSessionId !== sessionId) {
  //       console.log("Session ID mismatch. Stopping interval.");
  //       shutdown();
  //       return;
  //     }
  //     // Stop the interval if BV hasn't updated for x amount of time
  //     if (gameTime - sessionTime > allowableDelay) {
  //       console.log("Session over time. Stopping interval.");
  //       shutdown();
  //       return;
  //       // Else set game open time
  //     } else {
  //       localStorage.setItem(
  //         "gameOpen",
  //         JSON.stringify({ sessionId, gameTime })
  //       );
  //     }
  //   }, 3000);
  //   // Clear interval
  //   return () => clearInterval(interval);
  // }

  // Validate session & get embodiment ID on load
  useEffect(() => {
    async function getEmbodimentType() {
      try {
        // Validate session ID & cluster ID
        if (!sessionId || !clusterId) {
          setError(
            "Sorry, this URL is invalid. Please return to the brain visualizer and retry."
          );
          setLoading(false);
          return;
        }
        // Validate access token
        const accessToken = auth?.user?.accessToken;
        if (!accessToken) {
          logger("auth:", auth);
          setError(
            "Sorry, you must be logged in to view this page. Please log in and retry."
          );
          setLoading(false);
          return;
        }
        // Validate session
        const sessionStatus = await getSessionFeagiStatus(
          accessToken,
          sessionId
        );
        if (!sessionStatus.data) {
          setError(
            "This session is invalid (likely expired). Please return to the lab or brain visualizer to start fresh."
          );
          setLoading(false);
          return;
        }
        // Get experiment ID by session ID
        const sessionInfo = await getExperimentIdBySessionId(
          sessionId,
          accessToken
        );
        const experimentId = sessionInfo.data.experiment_id;
        // Get embodiment ID by experiment ID
        const experimentInfo = await getExperimentInfo(
          experimentId,
          accessToken
        );
        const resEmbodimentId = experimentInfo.data.embodiment_id;
        // Set type using embodiment ID
        if (!resEmbodimentId) {
          setError(
            "Error getting embodiment ID. Please return to the brain visualizer and retry."
          );
        }
        setEmbodimentId(resEmbodimentId);
        setLoading(false);
        // setTimeout(() => {
        //   startInterval();
        // }, 5000);
      } catch (err) {
        console.error(err);
        setError(
          "Sorry, an unexpected error occurred. Please return to the brain visualizer and retry."
        );
        setLoading(false);
      }
    }

    getEmbodimentType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100% - 64px)",
        maxWidth: "100vw",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading && <CircularProgress />}

      {error && <Typography>{error}</Typography>}

      {/* {sessionClosed && (
        <Typography>
          The brain visualizer session for this game has ended. You may close
          this window and return to the lab.
        </Typography>
      )} */}

      {/* {!error && embodimentId && !sessionClosed && ( */}
      {!error && embodimentId && (
        <iframe
          width="100%"
          height="100%"
          title={embodimentId}
          src={`${process.env.PUBLIC_URL}/static/${embodimentId}/index.html?feagi_url=${sessionId}-feagi.${clusterId}.neurorobotics.studio&port_disabled=true&ws_url=wss://${sessionId}-feagi.${clusterId}.neurorobotics.studio/p9055&http_type=HTTPS://`}
          sandbox="allow-scripts allow-popups allow-same-origin allow-forms allow-top-navigation-by-user-activation allow-top-navigation allow-modals allow-popups-to-escape-sandbox"
          allow="cross-origin-isolation"
        />
      )}
    </Box>
  );
};

export default requireAuth(NewWindowEmbodiment);
