export default function getGridPoints(config) {
  const camera = config?.capabilities?.input?.camera?.[0];

  if (!camera) return [];

  const eccentricityX = camera.eccentricity_control?.["X offset percentage"];
  const eccentricityY = camera.eccentricity_control?.["Y offset percentage"];
  const modulationX = camera.modulation_control?.["X offset percentage"];
  const modulationY = camera.modulation_control?.["Y offset percentage"];

  return {
    x: [eccentricityX, modulationX],
    y: [eccentricityY, modulationY],
  };
}
