import { forwardRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Switch,
  Tooltip,
  Typography,
  TextField,
  Card,
  CardContent,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Link,
} from "@mui/material";
import {
  Cancel as CancelIcon,
  CheckBox as CheckBoxIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Star as StarIcon,
  StarOutline as StarOutlineIcon,
  AccountTree,
  PersonAdd,
} from "@mui/icons-material";
import {
  augmentGenome,
  getGenomeFilters,
  toggleGenomeFavorite,
  toggleGenomePublic,
  updateGenome,
} from "../../api/genomeManagement";
import { deployGenome } from "../../api/feagiSessionManagement";
import { deleteGenome } from "../../api/genomeManagement";
import Parentage from "../Parentage";
import CustomDialog from "../CustomDialog";
import { useAuth } from "../../util/auth";
import { logger } from "../../util/logger";
import TogglePublic from "../TogglePublic";
import ShareGenome from "../ShareGenome";
import { downloadGenome } from "../../api/hub";
import SignupModal from "../../util/SignupModal";

const FeaturesDropdown = ({
  features,
  selectedFeatures,
  setSelectedFeatures,
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedFeatures(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl sx={{ m: "8px 0", width: "100%" }}>
      <InputLabel id="features-label">Features</InputLabel>
      <Select
        labelId="features-label"
        id="features"
        multiple
        value={selectedFeatures}
        onChange={handleChange}
        input={<OutlinedInput label="Feature" />}
        MenuProps={MenuProps}
      >
        {features.map((feature) => (
          <MenuItem key={feature} value={feature}>
            {feature}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// Example genome object
// {"base_genome_id": "2022-07-10_21:53:10_961677_111111_G", "genome_id": "g-evffncgfdj", "created_at": "2023-11-03T19:05:10.485000", "created_by": "Neuraville", "updated_at": "2023-11-03T19:05:10.485000", "updated_by": "NU-6862455679", "tags": [], "stars": 0, "public": true, "genome_title": "Base Genome", "species": "None", "performance_metrics": {}, "age_limit": 999999999, "state": "active", "version": "2.0", "seed_genome_id": "g-huuhhuxglp", "is_favorite": false, "owned": false, "creator_display_name": "70381"}

const NewExperimentGenomeCard = forwardRef(
  (
    {
      accessToken,
      row,
      index,
      onComplete,
      collectedData,
      setCollectedData,
      setError,
      setSeedOpen,
      handleSeedChangeClose,
      handleCurrentClose,
      handleCurrentAugmentClose,
      setAwaitAugment,
      setIsCalling,
      updateItem,
      setRefetchGenome,
      isHub,
    },
    ref
  ) => {
    const auth = useAuth();
    const roles = auth.user?.roles;
    const [signupModalOpen, setSignupModalOpen] = useState(false);
    // Whether user has a high enough pricing tier to access this genome
    const canChooseGenome =
      row?.genome_tier === "starter-user" ||
      (row?.genome_tier === "pro-user" &&
        (roles?.includes("pro-user") || roles?.includes("ultimate-user"))) ||
      (row?.genome_tier === "ultimate-user" &&
        roles?.includes("ultimate-user"));
    const [showMore, setShowMore] = useState(false);
    const [isPublic, setIsPublic] = useState(row.public);
    const [shareOpen, setShareOpen] = useState(false);
    const [isFave, setIsFave] = useState(row.is_favorite);
    const [faveCount, setFaveCount] = useState(row.stars);
    const [genealogyOpen, setGenealogyOpen] = useState(false);
    const [timeoutAugment, setTimeoutAugment] = useState(false);
    const [downloadOpen, setDownloadOpen] = useState(false);
    // Editing
    const [isEditing, setIsEditing] = useState(false);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [features, setFeatures] = useState([]); // ALL features for selection dropdown
    const [selectedFeatures, setSelectedFeatures] = useState(row.tags || []);
    const [openDeleteCheck, setOpenDeleteCheck] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [confirmTogglePublicOpen, setConfirmTogglePublicOpen] =
      useState(false);
    const [confirmTogglePrivateOpen, setConfirmTogglePrivateOpen] =
      useState(false);
    // Stuff for brain-vis page
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const sessionId = queryParameters.get("id");

    useEffect(() => {
      setRefetchGenome && setRefetchGenome((prev) => !prev);
    }, [isPublic, isFave, faveCount, setRefetchGenome]);

    // Handle user selection
    async function handleSelect(row) {
      if (onComplete) {
        try {
          setError("");
          setCollectedData({
            ...collectedData,
            genomeId: row.genome_id,
            genomeTitle: row.genome_title,
          });
          onComplete();
        } catch (err) {
          console.error(err);
          setError("Sorry, there was an error setting your genome selection.");
        }
      } else if (handleSeedChangeClose) {
        try {
          setError("");
          setConfirmationOpen(true);
        } catch (err) {
          console.error(err);
          setError("Sorry, there was an error confirming your change.");
        }
      } else if (handleCurrentAugmentClose) {
        handleCurrentAugment();
      }
    }

    // Turn off awaitAugment after 10 secs
    useEffect(() => {
      if (timeoutAugment) {
        setTimeout(() => {
          setAwaitAugment(false);
        }, 10000);
      }
    }, [setAwaitAugment, timeoutAugment]);

    // Augment current genome in BV
    async function handleCurrentAugment() {
      try {
        setError("");
        if (!accessToken) throw new Error("Unable to retrieve access token.");
        if (!sessionId) throw new Error("Unable to retrieve session ID.");
        setIsCalling(true);
        await augmentGenome(accessToken, row.genome_id, sessionId);
        handleCurrentAugmentClose();
        // Set await augment & trigger its timeout
        setAwaitAugment(true);
        setTimeoutAugment(true);
      } catch (err) {
        console.error(err);
        if (err.status === 409) {
          setError(
            "An augmentation is already in progress. If you have completed augmentation steps in the brain visualizer, please wait a moment."
          );
        } else {
          setError(
            "There was an error adding the genome. Please reload and retry."
          );
        }
      } finally {
        setIsCalling(false);
      }
    }

    // Change seed genome in BV
    async function handleSeedChange() {
      try {
        setError("");
        if (!accessToken) throw new Error("Unable to retrieve access token.");
        if (!sessionId) throw new Error("Unable to retrieve session ID.");
        await deployGenome(accessToken, sessionId, row.genome_id);
        handleConfirmationClose();
        handleSeedChangeClose();
      } catch (err) {
        console.error(err);
        handleConfirmationClose();
        setError(
          "There was an error changing your genome. Please reload and retry."
        );
      }
    }

    // Close seed genome change confirmation
    const handleConfirmationClose = () => {
      setConfirmationOpen(false);
    };

    // Genome to private handling
    const handleConfirmTogglePrivate = () => {
      setConfirmTogglePrivateOpen(false);
      togglePublic();
    };

    const handleConfirmTogglePrivateClose = () => {
      setConfirmTogglePrivateOpen(false);
    };

    // Toggle genome public/private
    async function togglePublic() {
      try {
        setError("");
        const genomeId = row.genome_id;
        if (!accessToken) throw new Error("Unable to get user data");
        if (!genomeId) throw new Error("Unable to get genome ID");
        await toggleGenomePublic(accessToken, genomeId);
        setIsPublic((prev) => !prev);
      } catch (error) {
        console.error(error);
        setSeedOpen && setSeedOpen(false);
        setError(
          "Sorry, something went wrong! Please try changing your experiment visibility again later."
        );
      }
    }

    // Toggle genome fave
    async function toggleFave() {
      try {
        setError("");
        const genomeId = row.genome_id;
        if (!accessToken) {
          setSignupModalOpen(true);
          return;
        }
        if (!genomeId) throw new Error("Unable to get genome ID");
        await toggleGenomeFavorite(accessToken, genomeId);
        isFave
          ? setFaveCount((prev) => prev - 1)
          : setFaveCount((prev) => prev + 1);
        setIsFave((prev) => !prev);
      } catch (error) {
        console.error(error);
        setSeedOpen && setSeedOpen(false);
        setError(
          "Sorry, something went wrong! Please try toggling your favorite again later."
        );
      }
    }

    // Get list of genome features for user to pick from in edit
    const fetchFeatures = async () => {
      try {
        if (!accessToken) throw new Error("Unable to retrieve access token.");
        const response = await getGenomeFilters(accessToken);
        if (!response.data) {
          throw new Error("Received no data from the server.");
        }
        if (!response.data.genomes?.capabilities) {
          throw new Error("Filter list didn't return genome capabilities.");
        }
        setFeatures(response.data.genomes.capabilities.values);
      } catch (error) {
        console.error("Error fetching filters:", error);
      }
    };

    // Change to editing view
    const handleStartEdit = async () => {
      await fetchFeatures();
      setIsEditing(true);
    };

    // Submit title/description edits
    const handleEdit = async () => {
      logger("title:", title);
      logger("description:", description);
      logger("selected features:", selectedFeatures);
      try {
        // Update genome in backend
        await updateGenome({
          accessToken,
          genomeId: row.genome_id,
          title,
          description,
          features: selectedFeatures,
        });
        setRefetchGenome && setRefetchGenome((prev) => !prev);
        // Update this card in LazyLoader (if in all cards view)
        if (updateItem) {
          const updatedItem = {
            ...row,
            genome_title: title || row.genome_title,
            description: description || row.genome_description,
            tags: selectedFeatures,
          };
          updateItem(updatedItem);
        }
        // Reset states
        setIsEditing(false);
        setTitle(null);
        setDescription(null);
      } catch (err) {
        console.error(err);
        if (err.status && err.status === 418) {
          setError(
            "Your changes are detected to contain inappropriate language. Please edit and try again. (If you feel this is incorrect, please submit an issue report through the message icon in the navbar.)"
          );
        } else {
          setError(
            "Sorry, there was an error updating your genome. Please refresh and/or try again soon."
          );
        }
      }
    };

    // Cancel editing
    const handleCancelEdit = () => {
      // clearAlerts();
      // setTitle(experiment?.experiment_title);
      // setDescription(experiment?.experiment_description);
      setIsEditing(false);
    };

    // Delete genome
    const handleDelete = async () => {
      try {
        if (!accessToken || !row.genome_id) {
          throw new Error("Unable to get required data to delete genome.");
        }
        await deleteGenome(accessToken, row.genome_id);
        setIsDeleted(true);
      } catch (err) {
        console.error(err);
        setError(
          "An error occurred deleting your genome. Please reload to confirm and/or retry its deletion."
        );
      } finally {
        setOpenDeleteCheck(false);
      }
    };

    // Confirmation modal to change seed genome in BV
    const ConfirmBVSeedChange = () => {
      return (
        <CustomDialog
          header="Replace your current genome?"
          text="This will replace your experiment's genome with a genome that has
      the selected behavior."
          isOpen={confirmationOpen}
          handleClose={handleConfirmationClose}
          confirmAction={handleSeedChange}
          cancelAction={handleConfirmationClose}
        />
      );
    };

    // Confirmation modal to delete genome
    const ConfirmDeleteGenome = () => {
      const closeAction = () => setOpenDeleteCheck(false);
      return (
        <CustomDialog
          header={`Are you sure you want to delete ${row.genome_title}?`}
          isOpen={openDeleteCheck}
          handleClose={closeAction}
          confirmAction={handleDelete}
          cancelAction={closeAction}
        />
      );
    };

    // const handleChange = (event) => {
    //   const clickedFeature = event.target.value;
    //   if (selectedFeatures.includes(clickedFeature)) {
    //     const filtered = features.filter(
    //       (feature) => feature !== clickedFeature
    //     );
    //     setSelectedFeatures(filtered);
    //   } else {
    //     selectedFeatures.push(clickedFeature);
    //   }
    // };

    function getSharingTitle(row) {
      if (row.shared_with?.length) {
        const count = row.shared_with_count || row.shared_with.length;
        return `Shared with ${count} ${count > 1 ? "people" : "person"}`;
      } else {
        return "Share genome";
      }
    }

    const handleDownloadClick = () => {
      if (auth.user) {
        setDownloadOpen(true);
      } else {
        setSignupModalOpen(true);
      }
    };

    const handleConfirmDownload = async () => {
      try {
        await downloadGenome(row.genome_id, row.genome_title, accessToken);
      } catch (err) {
        console.error(err);
        setError(
          "An error occurred downloading the genome. Please reload if issues persist."
        );
      }
    };

    return isDeleted ? (
      <></>
    ) : (
      <>
        <Card
          ref={ref ? ref : undefined}
          sx={{
            position: "relative",
            overflow: "visible",
            width: "100%",
            "&:hover .hoverButton": {
              opacity: 1,
            },
            border: "1px solid",
            backgroundColor: "background.dark",
            borderColor: "accents.superDark",
          }}
        >
          {/* Badge at the top of the card */}
          {row.genome_tier && row.genome_tier !== "starter-user" && (
            // || row.genome_id === collectedData?.embodimentGenomeId)
            <Box
              sx={{
                position: "absolute",
                top: "-10px", // Position it half outside the top of the card
                left: "50%", // Position element's starting point at parent's horizontal center
                transform: "translateX(-50%)", // Shift element left by half of its own width
                px: 1,
                py: 0.5,
                border: "1px solid #222629",
                borderRadius: "20px",
                bgcolor: "background.default",
              }}
            >
              {/* <Tooltip
                title={
                  row.genome_id === collectedData?.embodimentGenomeId
                    ? "This is a bare bones genome that will work for your selected embodiment"
                    : ""
                }
              > */}
              <Typography
                sx={{
                  fontSize: "0.7rem",
                  fontWeight: "600",
                  textTransform: "uppercase",
                  color: "#737373",
                  // row.genome_tier === "ultimate-user" ||
                  // row.genome_tier === "pro-user"
                  //   ? "#737373"
                  //   : "rgb(100 133 168)",
                }}
              >
                {
                  row.genome_tier === "ultimate-user" ? "Ultimate" : "Pro"
                  // row.genome_tier === "pro-user"
                  // ? "Pro"
                  // : "Embodiment Starter"
                }
              </Typography>
              {/* </Tooltip> */}
            </Box>
          )}
          <CardContent
            sx={{
              width: "100%",
              pt: "16px",
              pb: "16px !important",
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              flexDirection: "column",
            }}
          >
            {row.genome_id === collectedData?.embodimentGenomeId && (
              <Box
                sx={{
                  p: "2px 7px",
                  fontWeight: "bold",
                  backgroundColor: "#90cbf90d",
                  color: "primary.main",
                  textAlign: "center",
                  // border: 1,
                  // borderRadius: "5px",
                  // borderColor: "primary.main",
                }}
              >
                Recommended starter genome
              </Box>
            )}
            {/* Stats + main buttons */}
            <Box
              sx={{
                width: "100%",
                mt: { xs: 1, md: 0 },
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: { xs: 1, md: 3 },
              }}
            >
              {/* Left Column */}
              <Box
                display="flex"
                flexDirection="column"
                sx={{ width: { xs: "100%", md: "65%" } }}
              >
                {/* Top Left */}
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Box
                    display="flex"
                    flexDirection="column"
                    flexGrow={1}
                    overflow="hidden"
                    width="80%"
                  >
                    {isEditing ? (
                      <TextField
                        id="title"
                        type="text"
                        label="Title"
                        name="title"
                        placeholder="Title"
                        required
                        variant="outlined"
                        inputProps={{ maxLength: 50 }}
                        value={title !== null ? title : row.genome_title}
                        onChange={(e) => setTitle(e.target.value)}
                        sx={{ width: "100%", margin: "5px 5px 5px 0" }}
                      />
                    ) : (
                      <Typography
                        sx={{
                          marginBottom: "10px",
                          ...(!showMore
                            ? {
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                              }
                            : {}),
                          fontSize: "20px",
                        }}
                      >
                        <strong>{row.genome_title}</strong>{" "}
                        {row.creator_display_name && (
                          <Typography
                            variant="span"
                            sx={{ color: "accents.light", fontSize: ".9rem" }}
                          >
                            by{" "}
                            <Link
                              href={`/user/${row.creator_display_name}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {row.creator_display_name}
                            </Link>
                          </Typography>
                        )}
                      </Typography>
                    )}
                    {/* <Typography
                    sx={{
                      marginBottom: isEditing ? "15px" : "10px",
                      marginTop: isEditing ? "15px" : "0",
                      width: "100%",
                    }}
                  >
                    <span
                      sx={{
                        ...(!showMore
                          ? {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "100%",
                            }
                          : {}),
                      }}
                    >
                      <strong>Species:</strong>
                    </span>{" "}
                    {row.species || "None"}
                  </Typography> */}
                    <Box
                      sx={{
                        marginBottom: "10px",
                        width: "100%",
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "4px",
                          maxHeight: !showMore && !isEditing ? "35px" : "none",
                          overflow: !showMore ? "hidden" : "visible",
                        }}
                      >
                        {isEditing ? (
                          <FeaturesDropdown
                            features={features}
                            selectedFeatures={selectedFeatures}
                            setSelectedFeatures={setSelectedFeatures}
                          />
                        ) : (
                          <>
                            {row.tags?.length > 0 && (
                              <>
                                <Box
                                  sx={{
                                    minHeight: "35px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography fontWeight="700">
                                    Features:
                                  </Typography>
                                </Box>
                                {row.tags.map((feature, index) => {
                                  if (!feature.trim().length) return null;
                                  return (
                                    <Chip
                                      key={index}
                                      label={feature}
                                      sx={{
                                        "&.MuiChip-root": {
                                          backgroundColor: "accents.dark",
                                          borderRadius: "5px",
                                          height: "auto",
                                          minHeight: "1rem",
                                          padding: "3px 0",
                                          "& .MuiChip-label": {
                                            color: "white",
                                            fontWeight: "bold",
                                          },
                                        },
                                      }}
                                    />
                                  );
                                })}
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        mb: "10px",
                        display: "flex",
                        gap: 4,
                      }}
                    >
                      {/* Neurons */}
                      {row.neuron_count && (
                        <Typography sx={{ display: "flex", gap: 1 }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Neurons:
                          </Typography>{" "}
                          {row.neuron_count
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                            "Unavailable"}
                        </Typography>
                      )}
                      {/* Synapses */}
                      {row.synapse_count && (
                        <Typography sx={{ display: "flex", gap: 1 }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Synapses:
                          </Typography>{" "}
                          {row.synapse_count
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ||
                            "Unavailable"}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>

                {/* Bottom Left (description) */}
                {isEditing ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <TextField
                      variant="outlined"
                      type="text"
                      label="Description"
                      name="description"
                      placeholder="description"
                      fullWidth={true}
                      multiline
                      maxRows="30"
                      minRows="1"
                      sx={{
                        m: "5px 5px 5px 0",
                        width: "100%",
                        maxWidth: "100%",
                        overflowX: "hidden",
                      }}
                      value={
                        description !== null
                          ? description
                          : row.genome_description
                      }
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography
                      sx={
                        showMore
                          ? {}
                          : {
                              display: "block",
                              maxWidth: "80vw",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }
                      }
                    >
                      <Typography variant="span" fontWeight="700">
                        Description:
                      </Typography>{" "}
                      {row.genome_description || "None"}
                    </Typography>
                  </Box>
                )}
              </Box>

              {/* Right Column */}
              <Box
                sx={{
                  width: { xs: "100%", md: "40%" },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  order: { xs: "-1", md: "0" },
                }}
              >
                {/* Bottom Left */}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: { xs: "row-reverse", md: "column" },
                    alignItems: { xs: "flex-start", md: "flex-end" },
                    gap: "10px",
                  }}
                >
                  {/* Choose button */}
                  <Box display="flex">
                    {!isHub &&
                      (onComplete ||
                        handleSeedChangeClose ||
                        handleCurrentAugmentClose) && (
                        <Tooltip
                          title={
                            isEditing
                              ? "Confirm or cancel edits to choose"
                              : !canChooseGenome
                              ? `This genome requires ${
                                  row.genome_tier === "pro-user"
                                    ? "a pro"
                                    : "an ultimate"
                                } subscription`
                              : undefined
                          }
                        >
                          <span>
                            <Button
                              variant="contained"
                              onClick={() => handleSelect(row)}
                              className="hoverButton"
                              disabled={isEditing || !canChooseGenome}
                              sx={{
                                opacity: isEditing ? 1 : { xs: 1, md: 0 },
                                transition: { md: "opacity 0.1s ease" },
                                fontWeight: "600",
                              }}
                            >
                              Choose
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    {/* Download button */}
                    {isHub && (
                      <Button
                        variant="contained"
                        onClick={handleDownloadClick}
                        className="hoverButton"
                        sx={{
                          opacity: { xs: 1, md: 0 },
                          transition: { md: "opacity 0.1s ease" },
                          fontWeight: "600",
                        }}
                      >
                        Download
                      </Button>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    sx={{
                      width: "100%",
                      flexDirection: { xs: "row-reverse", md: "row" },
                      justifyContent: {
                        xs: "flex-end",
                        md: row.owned ? "space-between" : "flex-end",
                      },
                    }}
                  >
                    {/* Share */}
                    {row.owned && (
                      <Tooltip title={getSharingTitle(row)} sx={{ mr: 1 }}>
                        <IconButton onClick={() => setShareOpen(true)}>
                          <PersonAdd
                            sx={{
                              color: row.shared_with?.length
                                ? "primary.main"
                                : "white",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    {/* Public/Private */}
                    {row.owned && (
                      <Tooltip title="Change your genome's visibility">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography>Public</Typography>
                          <Switch
                            onClick={() => {
                              isPublic
                                ? setConfirmTogglePrivateOpen(true)
                                : setConfirmTogglePublicOpen(true);
                            }}
                            checked={!!isPublic}
                          />
                        </Box>
                      </Tooltip>
                    )}
                    {/* Edit */}
                    {row.owned && !isHub && (
                      <IconButton
                        onClick={handleStartEdit}
                        disabled={isEditing}
                        sx={{ marginRight: "8px" }}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    <Tooltip
                      title={
                        handleCurrentClose
                          ? "Only viewing one layer of ancestry is currently supported"
                          : "View genealogy"
                      }
                    >
                      <span>
                        <IconButton
                          onClick={() => setGenealogyOpen(true)}
                          disabled={handleCurrentClose}
                          sx={{ marginRight: row.owned ? "5px" : "20px" }}
                        >
                          <AccountTree />
                        </IconButton>
                      </span>
                    </Tooltip>
                    {/* Favorite */}
                    <Box
                      sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}
                    >
                      <Box
                        sx={(theme) => ({
                          display: "flex",
                          alignItems: "center",
                          gap: "3px",
                          border: `1px solid ${theme.palette.accents.dark}`,
                          borderRadius: "5px",
                          backgroundColor: "accents.dark",
                          padding: "4px 3px",
                        })}
                      >
                        <IconButton
                          sx={{
                            padding: 0,
                            "&& .MuiIconButton-root": {
                              padding: "4px",
                              fontSize: "1rem",
                            },
                            "&& .MuiButtonBase-root": {
                              padding: "4px",
                            },
                          }}
                          onClick={toggleFave}
                        >
                          {isFave ? (
                            <StarIcon style={{ color: "gold" }} />
                          ) : (
                            <StarOutlineIcon />
                          )}
                        </IconButton>
                        <Typography
                          sx={{
                            fontFamily: "'Courier New', Courier, monospace",
                            width: "4ch",
                          }}
                        >
                          {faveCount}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Bottom Right */}
            <Box
              display="flex"
              gap="10px"
              justifyContent={isEditing ? "space-between" : "flex-end"}
              sx={{
                width: "100%",
                alignSelf: "flex-end",
              }}
            >
              {isEditing && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    marginBottom: "5px",
                  }}
                >
                  {/* Save / cancel edits icons */}
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      borderRadius: "5px",
                      backgroundColor: "background.light",
                    }}
                  >
                    <Tooltip title={"Cancel editing"}>
                      <IconButton onClick={handleCancelEdit}>
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Save edits">
                      <span>
                        <IconButton onClick={handleEdit}>
                          <CheckBoxIcon color="success" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                  {/* Delete icon */}
                  {onComplete && (
                    <Box
                      sx={{
                        gap: "5px",
                        borderRadius: "5px",
                        backgroundColor: "background.light",
                      }}
                    >
                      <Tooltip
                        title={
                          isPublic
                            ? "Cannot delete while public"
                            : "Delete genome"
                        }
                      >
                        <span>
                          <IconButton
                            onClick={() => {
                              setOpenDeleteCheck(true);
                            }}
                            disabled={isPublic}
                          >
                            <DeleteIcon
                              sx={{
                                color: isPublic ? "accents.main" : "error.main",
                              }}
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              )}
              {/* Show more icon */}
              {!showMore ? (
                <Box display="flex" justifyContent="flex-end">
                  <Tooltip title="Show more details">
                    <Button
                      variant="text"
                      sx={{
                        minWidth: 0,
                        padding: 0,
                        fontSize: "1rem",
                        textTransform: "none",
                        color: "accents.main",
                      }}
                      onClick={() => setShowMore((prev) => !prev)}
                    >
                      <ExpandMoreIcon />
                    </Button>
                  </Tooltip>
                </Box>
              ) : (
                // Show less icon
                <Box display="flex" justifyContent="end">
                  <Tooltip title="See less details">
                    <Button
                      sx={{
                        minWidth: 0,
                        padding: 0,
                        fontSize: "1rem",
                        textTransform: "none",
                        color: "accents.main",
                      }}
                      onClick={() => setShowMore((prev) => !prev)}
                    >
                      <ExpandLessIcon />
                    </Button>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>

        {/* Parentage modal */}
        {genealogyOpen && (
          <Parentage
            open={genealogyOpen}
            handleClose={() => setGenealogyOpen(false)}
            genome={row}
          />
        )}

        {/* Confirm seed change (in BV) modal */}
        <ConfirmBVSeedChange />

        {/* Confirm delete genome modal */}
        {onComplete && <ConfirmDeleteGenome />}

        {/* Confirm make genome public modal */}
        {confirmTogglePublicOpen && (
          <TogglePublic
            confirmTogglePublicOpen={confirmTogglePublicOpen}
            setConfirmTogglePublicOpen={setConfirmTogglePublicOpen}
            togglePublic={togglePublic}
          />
        )}

        {/* Confirm make genome private modal */}
        <CustomDialog
          header="Make your genome private?"
          text="This will hide your genome from other users. Any experiments/genomes already created by other users using this genome will remain."
          isOpen={confirmTogglePrivateOpen}
          handleClose={handleConfirmTogglePrivateClose}
          cancelAction={handleConfirmTogglePrivateClose}
          confirmAction={handleConfirmTogglePrivate}
        />

        {/* Share genome (with select users) modal */}
        {shareOpen && (
          <ShareGenome
            shareOpen={shareOpen}
            setShareOpen={setShareOpen}
            accessToken={accessToken}
            genome={row}
            updateGenome={updateItem}
          />
        )}

        {/* Download modal */}
        {downloadOpen && (
          <CustomDialog
            isOpen={downloadOpen}
            handleClose={() => setDownloadOpen(false)}
            cancelAction={() => setDownloadOpen(false)}
            confirmAction={handleConfirmDownload}
            header="Download Genome"
            richText={
              <>
                Download <strong>{row.genome_title}</strong> as a JSON that you
                can use with a local instance of FEAGI. Have fun exploring, or
                improve it and share it with the community!
              </>
            }
          />
        )}

        {/* Signup modal */}
        {signupModalOpen && (
          <SignupModal open={signupModalOpen} setOpen={setSignupModalOpen} />
        )}
      </>
    );
  }
);

export default NewExperimentGenomeCard;
