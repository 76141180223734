import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { getVersions } from "../../api/feagiGetVersions";
import FormatObject from "../../util/FormatObject";
import NavbarVersionsChange from "./NavbarVersionsChange";

const NavbarVersions = ({
  versionsOpen,
  setVersionsOpen,
  sessionId,
  clusterId,
  experimentId,
  setError,
  setMessage,
}) => {
  const [versionData, setVersionData] = useState(null);
  const [changeOpen, setChangeOpen] = useState(false);

  const handleClose = () => {
    setVersionsOpen(false);
  };

  useEffect(() => {
    const handleVersionsOpen = async () => {
      try {
        const res = await getVersions(sessionId, clusterId);
        const data = res.data;

        if (typeof data !== "object") {
          throw new Error("Problem retrieving data:", data);
        }

        setVersionData(<FormatObject data={data} />);
      } catch (err) {
        console.error(err);
        setError("An error occurred while retrieving versions. Please retry.");
        setVersionsOpen(false);
      }
    };

    if (sessionId && clusterId) {
      handleVersionsOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setVersionData, sessionId, clusterId]);

  // Set change dialog open
  const handleChangeClick = () => {
    if (!changeOpen) setChangeOpen(true);
  };

  return (
    <>
      <Dialog
        open={versionsOpen && versionData !== null}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        {/* Title & close button */}
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography variant="h6">Version Data</Typography>
            <Button variant="outlined" onClick={handleChangeClick}>
              Change
            </Button>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>

        {/* Description text */}
        <DialogContent>{versionData}</DialogContent>
      </Dialog>

      {/* Change dialog */}
      {changeOpen && (
        <NavbarVersionsChange
          isOpen={changeOpen}
          setIsOpen={setChangeOpen}
          setError={setError}
          setMessage={setMessage}
          sessionId={sessionId}
          experimentId={experimentId}
        />
      )}
    </>
  );
};

export default NavbarVersions;
