import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import NewExperimentGenomeCard from "../../new-experiment/NewExperimentGenomeCard";

const GenomeCurrent = ({
  accessToken,
  error,
  setError,
  currentGenome,
  currentOpen,
  setCurrentOpen,
  handleCurrentClose,
  setRefetchGenome,
}) => {
  return (
    <Dialog
      open={currentOpen}
      onClose={handleCurrentClose}
      aria-labelledby="current-genome-title"
      aria-describedby="current-genome-description"
      maxWidth="lg"
    >
      <DialogTitle id="current-genome-title">
        {/* <FontAwesomeIcon
        icon={faDna}
        style={{ color: "rgb(30 44 70)", marginRight: "10px" }}
      /> */}
        {"Genome Info"}
        <IconButton
          style={{ position: "absolute", right: 7, top: 7, padding: 3 }}
          onClick={handleCurrentClose}
          size="small"
        >
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          minWidth: { xs: "90vw", md: "960px" },
          overflowY: "visible",
        }}
      >
        <NewExperimentGenomeCard
          accessToken={accessToken}
          row={currentGenome}
          error={error}
          setError={setError}
          setSeedOpen={setCurrentOpen}
          setRefetchGenome={setRefetchGenome}
        />
      </DialogContent>
    </Dialog>
  );
};

export default GenomeCurrent;
