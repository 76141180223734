import { Box, Typography } from "@mui/material";

// Helper function to handle rendering of values
function renderValue(key, value, prefix = "") {
  // Handle objects
  if (typeof value === "object" && value !== null && !Array.isArray(value)) {
    return (
      <>
        <Typography
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            fontWeight: "bold",
            marginLeft: prefix ? "10px" : "0px",
          }}
        >
          {key}:
        </Typography>
        {/* Recursive call for nested objects */}
        {Object.entries(value).map(([subKey, subValue]) =>
          renderValue(subKey, subValue, "10px")
        )}
      </>
    );
  } else if (Array.isArray(value)) {
    // Handle arrays
    return (
      <Typography
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          marginLeft: prefix,
        }}
      >
        {`${key}: [${value.join(", ")}]`}
      </Typography>
    );
  } else {
    // Handle primitives and other cases
    return (
      <Typography
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          marginLeft: prefix,
        }}
      >
        {`${key}: ${value}`}
      </Typography>
    );
  }
}

function FormatObject({ data }) {
  if (typeof data !== "object" || data === null) {
    return null;
  }

  return (
    <Box sx={{ minWidth: "300px" }}>
      {Object.entries(data).map(([key, value]) => (
        <Box key={key} marginBottom={2}>
          {renderValue(key, value)}
        </Box>
      ))}
    </Box>
  );
}

export default FormatObject;
