const minutesToMilliseconds = (minutes) => minutes * 60 * 1000;

const config = {
  // Inactivity timeouts
  idleTimeout: minutesToMilliseconds(30), // when to log user out
  idleTimeoutAlert: minutesToMilliseconds(25), // when to show logout warning
  idleTimeoutHeartbeatFrequency: minutesToMilliseconds(1), // how often to inform backend if there is user activity

  // API timeout
  timeoutSeconds: 60,

  // API error count
  maxFailCount: 5,
  resetTime: minutesToMilliseconds(10),

  // Webcam
  webcamWidth: 64,
  webcamHeight: 64,

  // Screen Capture
  canvasWidth: 128,
  canvasHeight: 128,
};

export default config;
