import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Container,
  Box,
  Button,
  Link as LinkMui,
  Typography,
} from "@mui/material";
// import { useTheme } from "@mui/material/styles";
import Section from "../Section";
// import SectionHeader from "../SectionHeader";
import NewExperimentStepper from "./NewExperimentStepper";
import NewExperimentEnvironment from "./NewExperimentEnvironment";
import NewExperimentEmbodiment from "./NewExperimentEmbodiment";
import NewExperimentGenome from "./NewExperimentGenome";
import NewExperimentSummary from "./NewExperimentSummary";
import PageLoader from "../PageLoader";
import { getLaunchCriteria } from "../../api/experimentManagement";
import { useAuth } from "../../util/auth";
import CustomSnackbar from "../../util/CustomSnackbar";
import SignupModal from "../../util/SignupModal";
// import embodimentsBackground from "../img/embodiments-backgound.png";

const NewExperiment = () => {
  const auth = useAuth();
  const accessToken = auth?.user?.accessToken;
  const [tokenChecked, setTokenChecked] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [signupModalOpen, setSignupModalOpen] = useState(false);
  const [signupModalType, setSignupModalType] = useState("signin");
  const [experimentsMax, setExperimentsMax] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [collectedData, setCollectedData] = useState({});
  // const [selectionMade, setSelectionMade] = useState(false);
  // const [message, setMessage] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setTokenChecked(true);
    }, "1000");
  }, []);

  // Check for saved values in local storage
  useEffect(() => {
    const getSavedValues = () => {
      const savedActiveStep = localStorage.getItem("activeStep");
      const savedCollectedData = localStorage.getItem("collectedData");

      if (savedActiveStep !== null) {
        setActiveStep(Number(savedActiveStep));
      }

      if (savedCollectedData !== null) {
        setCollectedData(JSON.parse(savedCollectedData));
      }
    };

    getSavedValues();
  }, []);

  // Add launch criteria to localStorage
  useEffect(() => {
    async function callGetLaunchCriteria() {
      const res = await getLaunchCriteria(accessToken);
      const {
        system_overload,
        max_experiment_count_reached,
        max_concurrent_experiments_reached,
      } = res.data;
      localStorage.setItem(
        "launchCriteria",
        JSON.stringify({
          system_overload,
          max_concurrent_experiments_reached,
          max_experiment_count_reached,
        })
      );
      if (max_experiment_count_reached) setExperimentsMax(true);
    }

    accessToken && callGetLaunchCriteria();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  // Whenever activeStep or collectedData changes, save to local storage
  useEffect(() => {
    localStorage.setItem("activeStep", JSON.stringify(activeStep));
  }, [activeStep]);

  useEffect(() => {
    localStorage.setItem("collectedData", JSON.stringify(collectedData));
  }, [collectedData]);

  // Set activeStep when a selection is made
  const handleCompleteStep = () => {
    setError("");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const subtitles = [
    <>
      Select the <strong>environment</strong> for your embodiment, such as a
      physical robot or virtual interaction.
    </>,
    <>
      Select the <strong>embodiment</strong> for your genome to inhabit or
      interact with.
    </>,
    <>
      Select the <strong>genome</strong>: the starting capabilities and
      structure of the virtual brain.
    </>,
    <>
      Here are your selections. If you would like to <strong>change</strong>{" "}
      anything, click the relevant step icon.
    </>,
  ];

  const restartCreation = () => {
    setActiveStep(0);
    setCollectedData({});
  };

  return (
    <Section
      bgColor="default"
      size="auto" // vertical padding
      bgImage=""
      bgImageOpacity={1}
      title="New Experiment"
      customStyle={{
        width: "100%",
        maxWidth: "95vw",
        height: "100%",
        mt: 0,
      }}
    >
      {/* Loading spinner */}
      {loading ? (
        <PageLoader />
      ) : (
        <Container maxWidth="md">
          {/* Header */}
          <Box
            sx={{
              marginTop: "20px",
              minHeight: "87.5px",
              padding: { xs: 2, md: "16px" },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                order: { xs: 2, md: 0 },
                minWidth: "64px",
                display: "flex",
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              <Link to="/lab" style={{ textDecoration: "none" }}>
                <Button variant="outlined">Back to Lab</Button>
              </Link>
            </Box>
            <Typography
              variant="h4"
              sx={{
                width: { xs: "100%", md: "auto" },
                mb: { xs: 2, md: 0 },
                textAlign: "center",
              }}
            >
              Create Experiment
            </Typography>
            <Box sx={{ order: 3, minWidth: "64px" }}>
              {activeStep > 0 && (
                <Button variant="outlined" onClick={restartCreation}>
                  Start Over
                </Button>
              )}
            </Box>
          </Box>

          {/* Subtitle */}
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              padding: "12px",
              margin: "10px 0",
            }}
          >
            <Typography>{subtitles[activeStep]}</Typography>
          </Box>

          {/* Stepper */}
          <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
            <Box display="flex" justifyContent="center">
              <NewExperimentStepper
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                collectedData={collectedData}
              />
            </Box>

            {/* Message message */}
            {/* {message && (
              <Box margin="10px 0">
                <Alert severity="message" onClose={() => setMessage("")}>
                  {message}
                </Alert>
              </Box>
            )} */}

            {experimentsMax && (
              <Alert
                severity="warning"
                sx={{ mt: 2, display: "flex", alignItems: "center" }}
              >
                You have reached the max number of experiments. To create a new
                experiment, please delete an older experiment in the lab.
              </Alert>
            )}
            {tokenChecked && !auth?.user && (
              <Alert
                severity="info"
                sx={{ mt: 2, display: "flex", alignItems: "center" }}
              >
                You'll need to{" "}
                <LinkMui
                  onClick={() => {
                    setSignupModalType("signin");
                    setSignupModalOpen(true);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  create an account
                </LinkMui>{" "}
                or{" "}
                <LinkMui
                  onClick={() => setSignupModalOpen(true)}
                  sx={{ cursor: "pointer" }}
                >
                  log in
                </LinkMui>{" "}
                to finish making an experiment.
              </Alert>
            )}

            {/* Error message */}
            {error && (
              <CustomSnackbar
                message={error}
                setMessage={setError}
                severity="error"
                top="20px"
              />
            )}

            {/* Current step component */}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  width: "100%",
                  // paddingBottom: "60px",
                }}
              >
                {activeStep === 0 && (
                  <NewExperimentEnvironment
                    onComplete={handleCompleteStep}
                    collectedData={collectedData}
                    setCollectedData={setCollectedData}
                    setError={setError}
                  />
                )}
                {activeStep === 1 && (
                  <NewExperimentEmbodiment
                    onComplete={handleCompleteStep}
                    collectedData={collectedData}
                    setCollectedData={setCollectedData}
                    setError={setError}
                  />
                )}
                {activeStep === 2 && (
                  <NewExperimentGenome
                    onComplete={handleCompleteStep}
                    collectedData={collectedData}
                    setCollectedData={setCollectedData}
                    setError={setError}
                  />
                )}
                {activeStep === 3 && (
                  <NewExperimentSummary
                    collectedData={collectedData}
                    setCollectedData={setCollectedData}
                    setError={setError}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Container>
      )}

      {/* Signup modal */}
      {signupModalOpen && (
        <SignupModal
          open={signupModalOpen}
          setOpen={setSignupModalOpen}
          authType={signupModalType}
        />
      )}
    </Section>
  );
};

export default NewExperiment;
