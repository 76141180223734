import { Link as LinkMui } from "@mui/material";
import {
  GitHub as GitHubIcon,
  LinkedIn as LinkedInIcon,
  X as XIcon,
  YouTube as YouTubeIcon,
} from "@mui/icons-material";
import DiscordIcon from "../img/discord-white.png";

const SocialLinks = () => {
  // const iconsColor = darkMode.value ? "white" : "black";
  // const iconsColor = "white";
  const links = [
    {
      Icon: YouTubeIcon,
      url: "https://www.youtube.com/@Neuraville",
      type: "mui",
    },
    {
      Icon: LinkedInIcon,
      url: "https://www.linkedin.com/company/neuraville/",
      type: "mui",
    },
    { Icon: XIcon, url: "https://x.com/neuraville", type: "mui" },
    {
      Icon: GitHubIcon,
      url: "https://github.com/feagi/feagi",
      type: "mui",
    },
    {
      Icon: DiscordIcon,
      url: "https://discord.gg/PTVC8fyGN8",
      type: "image",
      alt: "Discord",
    },
  ];

  return (
    <>
      {links.map(({ Icon, url, type, alt }, index) => (
        <LinkMui
          key={index}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: "inherit",
            lineHeight: 1,
          }}
        >
          {type === "mui" && <Icon fontSize="small" color="white" />}
          {type === "image" && (
            <img src={Icon} alt={alt} style={{ height: 20 }} />
          )}
        </LinkMui>
      ))}
    </>
  );
};

export default SocialLinks;
