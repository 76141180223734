import { Edit as EditIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const EditButton = ({ clickHandler }) => {
  return (
    <IconButton size="small" variant="outlined" onClick={clickHandler}>
      <EditIcon />
    </IconButton>
  );
};

export default EditButton;
