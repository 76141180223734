// A utility function to apply a timeout to a promise
// Example use: const res = await withTimeout(getHealthStatus(process.env.REACT_APP_PROJECT_ID), 60000);

// Add a status to the timeout error message to mimic server responses for easier error handling
class TimeoutError extends Error {
  constructor(message) {
    super(message);
    this.name = "TimeoutError";
    this.status = 408; // HTTP status code for Request Timeout
  }
}

// Timeout helper function
export default function withTimeout(promise, milliseconds, errorMessage) {
  // Create a promise that rejects after the specified timeout
  const timeout = new Promise((_, reject) =>
    setTimeout(() => reject(new TimeoutError(errorMessage)), milliseconds)
  );
  // Race the given promise against the timeout
  return Promise.race([promise, timeout]);
}
