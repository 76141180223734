import Meta from "../components/Meta";
import Play from "../components/play/Play";
// import { requireAuth } from "../util/auth";

function PlayPage() {
  return (
    <>
      <Meta title="NRS - Play" />
      <Play />
    </>
  );
}

// export default requireAuth(PlayPage);
export default PlayPage;
