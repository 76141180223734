import { useState } from "react";
import {
  AcUnit as AcUnitIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ReviveBrain from "../ReviveBrain";
import { freezeBrain } from "../../api/feagiSessionManagement";

const NavbarFreezeBrain = ({
  accessToken,
  experiment,
  sessionId,
  setError,
  setMessage,
  awaitAugment,
}) => {
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const dropdownOpen = Boolean(anchorEl);
  const id = dropdownOpen ? "complex-popover" : undefined;
  const [reviveOpen, setReviveOpen] = useState(false);
  const [freezeOpen, setFreezeOpen] = useState(false);
  const [freezeNote, setFreezeNote] = useState("");

  const clearAlerts = () => {
    setError("");
    setMessage("");
  };

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleReviveClick = () => {
    setReviveOpen(true);
  };

  const handleFreezeClick = () => {
    setFreezeOpen(true);
  };

  const handleFreezeClose = () => {
    setFreezeNote("");
    setFreezeOpen(false);
  };

  const handleFreezeBrain = async () => {
    try {
      setLoading(true);
      if (!accessToken || !sessionId || !experiment) {
        throw new Error("Unable to get required data");
      }
      if (!freezeNote) throw new Error("Note is required");
      await freezeBrain({
        accessToken,
        sessionId,
        experimentId: experiment.experiment_id,
        experimentGenomeId: experiment.experiment_genome_id,
        freezeNote,
      });
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
    handleFreezeClose();
  };

  return (
    <>
      {/* Button to open dropdown */}
      <Tooltip title="Freeze (save) brain state [PRO]">
        <IconButton
          color="inherit"
          style={{ borderRadius: "5px", padding: "5px" }}
          onClick={handleDropdownOpen}
          aria-describedby={id}
          disabled={awaitAugment}
          // disabled={true} // change to role !== pro etc.
        >
          <AcUnitIcon
            title="freeze (save) brain state [PRO]"
            sx={{
              margin: "0 5px",
            }}
          />
        </IconButton>
      </Tooltip>

      {/* Dropdown */}
      <Popover
        id={id}
        open={dropdownOpen}
        anchorEl={anchorEl}
        onClose={handleDropdownClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box display="flex" flexDirection="column">
          <List onClick={handleDropdownClose}>
            <ListItemButton onClick={handleFreezeClick}>
              <ListItemText>Freeze Brain</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={handleReviveClick}>
              <ListItemText>Revive Brain</ListItemText>
            </ListItemButton>
          </List>
        </Box>
      </Popover>

      {/* Freeze brain modal */}
      <Dialog
        open={freezeOpen}
        onClose={handleFreezeClose}
        aria-labelledby="freeze-title"
        aria-describedby="freeze-brain"
      >
        <DialogTitle id="freeze-title" sx={{ paddingBottom: "5px" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            Freeze Current Brain
            <IconButton size="small" onClick={handleFreezeClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Box component="hr" sx={{ borderColor: "accents.main" }} />
        </DialogTitle>
        <DialogContent
          sx={{
            minWidth: "450px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "15px",
          }}
        >
          {loading ? (
            <Box width="100%" display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box display="flex" flexDirection="column" gap="7px">
                <Typography>
                  This will save the current state of the brain for later
                  access. You can continue to make changes and freeze new brain
                  states.
                </Typography>
              </Box>
              <TextField
                label="Note"
                variant="outlined"
                required
                fullWidth
                value={freezeNote}
                onChange={(e) => setFreezeNote(e.target.value)}
              />
              <Tooltip title={!freezeNote.trim() ? "Note is required" : ""}>
                <span>
                  <Button
                    variant="outlined"
                    onClick={handleFreezeBrain}
                    disabled={!freezeNote.trim()} // remains disabled if user enters nothing or only spaces
                  >
                    Freeze
                  </Button>
                </span>
              </Tooltip>
            </>
          )}
        </DialogContent>
      </Dialog>

      {/* Revive brain modal */}
      {reviveOpen && (
        <ReviveBrain
          accessToken={accessToken}
          experiment={experiment}
          reviveOpen={reviveOpen}
          setReviveOpen={setReviveOpen}
          setError={setError}
          setMessage={setMessage}
          clearAlerts={clearAlerts}
        />
      )}
    </>
  );
};

export default NavbarFreezeBrain;
