// import { useEffect, useState } from "react";
// import Meta from "./../components/Meta";
// import PageLoader from "../components/PageLoader";
import NewExperiment from "../components/new-experiment/NewExperiment";
// import { requireAuth } from "../util/auth";
// import { useNavigate } from "react-router-dom";
// import { getUserExperiments } from "../api/userManagement";

function NewExperimentPage(props) {
  // const auth = useAuth();
  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);

  // Redirect to lab if an experiment is already running or initialized
  // useEffect(() => {
  //   const checkForRunningExperiment = async () => {
  //     try {
  //       const accessToken = auth.user.accessToken;
  //       const res = await getUserExperiments(accessToken);
  //       const runningFound = res.data.results.some(
  //         (experiment) =>
  //           experiment.state === "running" ||
  //           experiment.state === "initializing"
  //       );
  //       if (runningFound) {
  //         navigate("/lab");
  //       }
  //     } catch (err) {
  //       console.error(err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   checkForRunningExperiment();
  // }, [navigate, auth.user.accessToken]);

  return <NewExperiment />;
  // return <>{loading ? <PageLoader /> : <NewExperiment />}</>;
}

// export default requireAuth(NewExperimentPage);
export default NewExperimentPage;
