// Dependencies
import React, { useEffect, useRef, useState } from "react";
import init, { MotionDetector } from "../../rust_wasm_lib/pkg/rust_wasm_lib";
import {
  Box,
  Card,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import lz4 from "lz4js";
// Utils
import config from "../../config";
import { logger } from "../../util/logger";
import processImageData from "./utils/processImageData";
import cameraCapabilities from "./utils/cameraCapabilities";
import getGridPoints from "./utils/getGridPoints";
import DrawActivations from "./utils/DrawActivations";
import DrawGrid from "./utils/DrawGrid";
import downsizeCanvasData from "./utils/downsizeCanvasData";
import regionsToCommands from "./utils/regionsToCommands";
// Components
import Dragger from "../Dragger";
import { getStimulationPeriod } from "../../api/feagiApiBurstEngine";
import WebsocketManager from "./WebsocketManager";
// import { getFeagiSessionInfo } from "../../api/feagiSessionManagement";
import { useAuth } from "../../util/auth";

const Webcam = ({
  webcamOpen,
  setWebcamOpen,
  // websocketOpen,
  // setWebsocketOpen,
  setError,
  handleErrorOpen,
  configFromDb,
  isEmbedded,
  sendMessage,
  sessionId,
  clusterId,
}) => {
  const auth = useAuth();
  const theme = useTheme();
  const isAboveXXL = useMediaQuery(theme.breakpoints.up("xxl"));
  // Video
  const capabilitiesRef = useRef(configFromDb || cameraCapabilities);
  const [gridCoords, setGridCoords] = useState(
    getGridPoints(capabilitiesRef.current)
  );
  const [activationRegions, setActivationRegions] = useState([]);
  const [videoReady, setVideoReady] = useState(false);
  const DEFAULT_REFRESH_RATE = 1000 / 50;
  const [refreshRate, setRefreshRate] = useState(DEFAULT_REFRESH_RATE);
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const intervalIdRef = useRef(null);
  const feagiWidthRef = useRef(config.webcamWidth);
  const feagiHeightRef = useRef(config.webcamHeight);
  // Websocket
  const wsMgr = useRef(null);
  const [isWebsocketOpen, setIsWebSocketOpen] = useState(false);
  // const [sessionId, setSessionId] = useState("");
  // const [clusterId, setClusterId] = useState("");
  // const [isSessionValid, setIsSessionValid] = useState(null);
  // Rust
  const detectorRef = useRef(null);
  const sensitivities = capabilitiesRef.current?.motion_sensitivity;
  const greyscaleThreshold = sensitivities?.pixel_intensity_difference || 20;
  const blockSize = sensitivities?.block_size_percentage * 100 || 5;
  const configBlockThresh = sensitivities?.block_change_threshold_percentage;
  const blockThreshold = configBlockThresh?.map((val) => val * 100) || [
    30, 30, 30, 30, 110, 30, 30, 30, 30,
  ];
  const motionIntensity = sensitivities?.motion_intensity || 0.7;
  const cooldown = sensitivities?.cooldown || 300;
  let lastCommandTime = 0;

  // Initialize Rust
  useEffect(() => {
    const loadWasm = async () => {
      await init();
    };

    isEmbedded && loadWasm();
  }, [isEmbedded]);

  // Set session & cluster IDs
  // useEffect(() => {
  //   console.log("Webcam component rendered");
  //   const params = new URLSearchParams(window.location.search);
  //   const paramSessionId = params.get("session_id") || params.get("id");
  //   const paramClusterId = params.get("cluster_id") || params.get("cluster");
  //   paramSessionId && setSessionId(paramSessionId);
  //   paramClusterId && setClusterId(paramClusterId);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    // Set session validity
    // async function checkSession() {
    //   try {
    //     if (!auth?.user?.accessToken) {
    //       throw new Error("Missing access token.");
    //     }
    //     const res = await getFeagiSessionInfo(
    //       auth?.user?.accessToken,
    //       sessionId
    //     );
    //     if (res.data?.state === "running") {
    //       setIsSessionValid(true);
    //       return true;
    //     } else {
    //       throw new Error("Session is not running:", res.data?.state);
    //     }
    //   } catch (err) {
    //     console.error("Error checking session:", err);
    //     setIsSessionValid(false);
    //     return false;
    //   }
    // }

    // Set refresh rate
    async function getRefreshRate() {
      try {
        const res = await getStimulationPeriod(sessionId, clusterId);
        const feagiRefreshRate = res.data;
        const newRefreshRate = 1 / feagiRefreshRate;
        console.log("Setting refresh rate to:", newRefreshRate);
        setRefreshRate(newRefreshRate);
      } catch (err) {
        console.error("Error getting stimulation period:", err);
      }
    }

    if (sessionId && clusterId) {
      // const isValid = checkSession();
      // isValid && getRefreshRate();
      getRefreshRate();
    }
  }, [auth.user?.accessToken, sessionId, clusterId]);

  // Initialize websocket
  useEffect(() => {
    if (sessionId && clusterId && !wsMgr.current) {
      wsMgr.current = new WebsocketManager({
        setIsWebSocketOpen,
        handleClose,
        setError,
        handleErrorOpen,
        sessionId,
        clusterId,
        setActivationRegions,
        setGridCoords,
        feagiWidthRef,
        feagiHeightRef,
        refreshRate,
        setRefreshRate,
        initialCapabilities: cameraCapabilities,
      });
      wsMgr.current.init();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, clusterId]);

  // Function to set & apply activation regions from Rust
  const sendToRust = (canvasFrame) => {
    if (!detectorRef.current) {
      if (videoRef.current) {
        detectorRef.current = new MotionDetector(
          videoRef.current.videoWidth,
          videoRef.current.videoHeight,
          new Uint8ClampedArray(
            videoRef.current.videoWidth * videoRef.current.videoHeight * 4
          ).fill(0)
        );
      } else {
        console.error("Video ref does not exist. Skipping Rust detection.");
        return;
      }
    }

    const changedRegions = detectorRef.current.detect_changes(
      canvasFrame,
      gridCoords.x[0] || 33, // x1: Left of first vertical line
      gridCoords.y[0] || 33, // y1: Bottom of first horizontal line
      gridCoords.x[1] || 66, // x2: Right of second vertical line
      gridCoords.y[1] || 66, // y2: Top of second horizontal line
      greyscaleThreshold,
      blockSize,
      blockThreshold,
      true // mirror
    );

    if (Array.isArray(changedRegions)) {
      setActivationRegions(changedRegions);
      if (changedRegions.length > 0) {
        const commands = regionsToCommands(changedRegions, motionIntensity);
        if (commands?.motion_control || commands?.misc) {
          const now = Date.now();
          if (now - lastCommandTime >= cooldown) {
            sendMessage(commands);
            lastCommandTime = now;
          }
        }
      }
    }
  };

  // Function to send canvas data via ws
  const sendViaWebsocket = (downsizedData, width, height) => {
    // Remove alpha values, add width & height
    const processedData = processImageData(downsizedData, width, height);

    // console.log(processedData.length === newWidth * newHeight * 3 + 2); // confirm right length

    // Convert to JSON & UInt8Array & compress (lz4 requires uint8array)
    const uInt8Array = new Uint8ClampedArray(processedData);
    const compressed = lz4.compress(uInt8Array);

    // Send via ws
    if (isWebsocketOpen && wsMgr.current) {
      wsMgr.current.sendData(compressed);
    } else {
      console.error(
        "Websocket not open. Cannot send data. isWebsocketOpen:",
        isWebsocketOpen,
        "wsMgr.current:",
        wsMgr.current
      );
    }
  };

  // Turn on webcam, get video data, and trigger canvas sending interval
  useEffect(() => {
    if (webcamOpen) {
      let stream = null;
      const mediaDevices = navigator.mediaDevices;

      mediaDevices
        .getUserMedia({
          video: true,
          // video: {
          //   width: config.webcamWidth,
          //   height: config.webcamHeight,
          // },
        })
        .then((mediaStream) => {
          stream = mediaStream;
          streamRef.current = mediaStream;
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          } else {
            console.error(
              "Video ref does not exist. Skipping setting video stream."
            );
          }
          videoRef.current?.addEventListener("loadedmetadata", () => {
            logger('adding event listener for video "loadedmetadata"');
            videoRef.current?.play();

            // Initialize a Rust MotionDetector class
            if ((!sessionId || !clusterId) && isEmbedded) {
              logger("initializing MotionDetector");
              if (!detectorRef.current) {
                detectorRef.current = new MotionDetector(
                  videoRef.current.videoWidth,
                  videoRef.current.videoHeight,
                  new Uint8ClampedArray(
                    videoRef.current.videoWidth *
                      videoRef.current.videoHeight *
                      4
                  ).fill(0)
                );
              }
            }

            // Start canvas data sending interval
            setVideoReady(true);
          });
        })
        .catch((error) => {
          console.error(error);
          handleClose();
        });

      // When component rerenders, shut down the webcam
      return () => {
        logger(`webcamOpen changed to ${webcamOpen}. Closing webcam.`);
        handleClose();
      };
    } else {
      console.log("Webcam not open. Skipping webcam setup.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamOpen]);

  // Interval to send video canvas
  useEffect(() => {
    if (videoReady && videoRef.current) {
      intervalIdRef.current = setInterval(() => {
        // Create new canvas
        const canvas = document.createElement("canvas");

        // Set canvas size to video dimensions & draw video onto it
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

        // Use Rust/postMessage
        if (isEmbedded) {
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const canvasFrame = new Uint8Array(imageData.data.buffer);
          sendToRust(canvasFrame);
        }

        // Use WS/controller
        if (wsMgr.current && sessionId && clusterId) {
          const { downsizedData, newWidth, newHeight } = downsizeCanvasData(
            canvas,
            feagiWidthRef.current,
            feagiHeightRef.current
          );
          sendViaWebsocket(downsizedData, newWidth, newHeight);
        }
      }, refreshRate || DEFAULT_REFRESH_RATE);
    } else {
      console.log("Video not ready. Skipping interval setup.");
    }

    // Cleanup function to clear the interval
    return () => {
      if (intervalIdRef.current) {
        logger("clearing interval");
        clearInterval(intervalIdRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoReady, refreshRate]);

  // Shut down webcam & related
  const handleClose = () => {
    console.log("Shutting down webcam & websocket elements");
    // Close websocket connection
    if (wsMgr.current) {
      logger("Closing websocket");
      wsMgr.current.ws?.close();
    }
    // Stop and clear the video stream
    if (videoRef.current) {
      logger("Stopping video stream");
      videoRef.current.pause();
      videoRef.current.srcObject = null;
    }
    // Stop all tracks of camera stream
    if (streamRef.current) {
      logger("Stopping stream tracks");
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
    // Set videoReady to false
    if (videoReady) setVideoReady(false);
    // Clear MotionDetector
    if (detectorRef.current) detectorRef.current = null;
    // Clear interval
    if (intervalIdRef.current) {
      logger("Clearing sending interval");
      clearInterval(intervalIdRef.current);
    }
    // Setting webcamOpen to false
    logger("Setting webcamOpen to false");
    setWebcamOpen(false);
  };

  // Track picture-in-picture close event from pip window itself
  // useEffect(() => {
  //   const videoElement = videoRef.current;

  //   const onLeavePiP = () => {
  //     // Timeout to avoid split second show of "picture-in-picture" backward text
  //     setTimeout(() => setPictureInPicture(false), 200);
  //   };

  //   if (videoElement) {
  //     videoElement.addEventListener("leavepictureinpicture", onLeavePiP);
  //   }

  //   return () => {
  //     if (videoElement) {
  //       videoElement.removeEventListener("leavepictureinpicture", onLeavePiP);
  //     }
  //   };
  // }, []);

  // Handle picture-in-picture click to open/close
  // const handlePiP = async () => {
  //   if (videoRef.current) {
  //     try {
  //       if (document.pictureInPictureElement) {
  //         await document.exitPictureInPicture();
  //       } else if (
  //         document.pictureInPictureEnabled &&
  //         !videoRef.current.disablePictureInPicture
  //       ) {
  //         await videoRef.current.requestPictureInPicture();
  //         setPictureInPicture(true);
  //       }
  //     } catch (error) {
  //       console.error("Picture-in-Picture Error:", error);
  //       setPictureInPicture(false);
  //     }
  //   }
  // };

  const video = (
    <video
      muted
      playsInline
      ref={videoRef}
      style={{
        height: "100%",
        width: "100%",
        objectFit: "cover",
        transform: "scaleX(-1)", // horizontal flip 🐟💦
        border: isEmbedded ? "1px solid lightgrey" : null,
      }}
    >
      Webcam display pending or failed.
    </video>
  );

  return (
    <>
      {webcamOpen &&
        (isEmbedded ? (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                position: "relative",
                // aspectRatio: "1/1",
                height: "100%",
                // width: "100%",
                // maxHeight: { xs: "35vh", md: "50vh" },
                maxHeight: isAboveXXL ? "100%" : "300px",
                maxWidth: "90vw",
              }}
            >
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  zIndex: 200,
                  top: 8,
                  right: 8,
                  color: "white",
                  backgroundColor: "background.default",
                  "&:hover": { backgroundColor: "background.dark" },
                }}
              >
                <CloseIcon />
              </IconButton>
              {/* <Box
                sx={{
                  position: "relative",
                }}
              > */}
              {video}
              {gridCoords && <DrawGrid gridCoords={gridCoords} />}
              {activationRegions?.length > 0 && gridCoords && (
                <DrawActivations
                  gridCoords={gridCoords}
                  regions={activationRegions}
                />
              )}
              {/* </Box> */}
            </Box>
          </Box>
        ) : (
          <Dragger handle=".dragHandle">
            <Card
              sx={{
                position: "absolute",
                border: "1px solid black",
                minWidth: "150px",
                minHeight: "150px",
                // ...(pictureInPicture && {
                //   minHeight: "35px",
                //   height: "35px",
                // }),
              }}
            >
              <div
                className="dragHandle"
                style={{
                  padding: "2px",
                  paddingLeft: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#313131",
                  cursor: "move",
                }}
              >
                <Typography fontWeight="500">Webcam</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {/* <Tooltip
                    title={
                      pictureInPicture
                        ? "Close Picture-in-Picture"
                        : "Open Picture-in-Picture"
                    }
                  >
                    <span>
                      <IconButton
                        onClick={handlePiP}
                        onMouseDown={(e) => e.stopPropagation()}
                        sx={{ padding: "4px" }}
                      >
                        <PictureInPicture
                          color={pictureInPicture ? "primary.main" : "white"}
                        />
                      </IconButton>
                    </span>
                  </Tooltip> */}
                  <IconButton
                    onClick={handleClose}
                    onMouseDown={(e) => e.stopPropagation()}
                    sx={{ padding: "4px" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </div>
              <Box
                sx={{
                  position: "relative",
                  // aspectRatio: "1/1",
                  maxHeight: "150px",
                  // height: "100%",
                  // width: "100%",
                }}
              >
                {video}
                {gridCoords && <DrawGrid gridCoords={gridCoords} />}
                {activationRegions?.length > 0 && (
                  <DrawActivations
                    gridCoords={gridCoords}
                    regions={activationRegions}
                  />
                )}
              </Box>
            </Card>
          </Dragger>
        ))}
    </>
  );
};

export default Webcam;
