import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Cancel as CancelIcon,
  CheckBox as CheckBoxIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import StandardCard from "./StandardCard";
import {
  deleteFrozenBrain,
  editFrozenBrain,
} from "../api/experimentManagement";
import CustomDialog from "./CustomDialog";

const ReviveBrainCard = ({
  accessToken,
  brain,
  experiment,
  handleSelect,
  clearAllAlerts,
  setDialogError,
  setDialogMessage,
}) => {
  const theme = useTheme();
  const [showMore, setShowMore] = useState(false);
  const [note, setNote] = useState(brain?.user_note);
  const [isEditing, setIsEditing] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteCheckOpen, setDeleteCheckOpen] = useState(false);

  useEffect(() => {
    if (brain && note === undefined) {
      setNote(brain.user_note);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brain]);

  const submitEdit = async () => {
    try {
      clearAllAlerts();
      await editFrozenBrain(accessToken, brain.id, note);
      setDialogMessage("Your brain was successfully edited.");
      setIsEditing(false);
    } catch (err) {
      console.error(err);
      setDialogError(
        "An error occurred. If your edit doesn't show, please try again or refresh."
      );
    }
  };

  const cancelEdit = () => {
    setIsEditing(false);
  };

  const handleDeleteClick = () => {
    clearAllAlerts();
    setDeleteCheckOpen(true);
  };

  const handleDelete = async () => {
    try {
      clearAllAlerts();
      await deleteFrozenBrain(accessToken, brain.id);
      setDialogMessage("The brain was successfully deleted.");
    } catch (err) {
      console.error(err);
      setDialogError(
        "An error occurred. Please refresh to confirm deletion and/or retry."
      );
    }
    setDeleteCheckOpen(false);
  };

  const handleClick = async () => {
    clearAllAlerts();
    try {
      if (experiment.state === "running") {
        setConfirmOpen(true);
      } else {
        await handleSelect(brain.id, false);
      }
    } catch (err) {
      console.error(err);
      setDialogError(err.message);
    }
  };

  const TopLeft = () => {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          overflow="hidden"
          width="80%"
        >
          <Typography
            fontSize="20px"
            sx={{
              marginBottom: "10px",
              ...(!showMore
                ? {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                  }
                : {}),
            }}
          >
            <strong>{brain.timestamp}</strong>
          </Typography>
          <Typography sx={{ marginBottom: "10px", color: "text.secondary" }}>
            <span
              sx={{
                marginRight: "15px",
                ...(!showMore
                  ? {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                    }
                  : {}),
              }}
            >
              <strong>Neurons</strong>
            </span>{" "}
            {brain.neuron_count}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          overflow="hidden"
          width="80%"
        >
          <Typography sx={{ marginBottom: "10px", color: "text.secondary" }}>
            <span
              sx={{
                marginRight: "15px",
                ...(!showMore
                  ? {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                    }
                  : {}),
              }}
            >
              <strong>Synapses</strong>
            </span>{" "}
            {brain.synapse_count}
          </Typography>
        </Box>
      </Box>
    );
  };

  const TopRight = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        gap="5px"
      >
        <Button variant="contained" onClick={() => handleClick(brain.id)}>
          Choose
        </Button>
        <Box display="flex" gap="10px" alignItems="flex-start" width="100%">
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              width: "100%",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              justifyContent="flex-end"
            >
              {isEditing ? (
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      borderRadius: "5px",
                      backgroundColor: "background.light",
                    }}
                  >
                    <IconButton onClick={submitEdit}>
                      <CheckBoxIcon color="success" />
                    </IconButton>
                    <IconButton
                      onClick={cancelEdit}
                      // sx={{
                      //   padding: 0,
                      //   "&& .MuiIconButton-root": {
                      //     padding: "4px",
                      //     fontSize: "1rem",
                      //   },
                      //   "&& .MuiButtonBase-root": {
                      //     padding: "4px",
                      //   },
                      // }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      gap: "5px",
                      borderRadius: "5px",
                      backgroundColor: "background.light",
                    }}
                  >
                    <IconButton onClick={handleDeleteClick}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Box>
                </Box>
              ) : (
                <IconButton onClick={() => setIsEditing(true)}>
                  <EditIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const BottomLeft = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex" justifyContent="space-between">
          <Box flex width="100%">
            {isEditing ? (
              <Box display="flex">
                <TextField
                  variant="outlined"
                  type="text"
                  label="Note"
                  name="note"
                  placeholder="note"
                  required
                  fullWidth={true}
                  multiline
                  maxRows="30"
                  minRows="1"
                  style={{ margin: "5px 5px 5px 0", width: "100%" }}
                  value={note !== null ? note : brain.user_note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </Box>
            ) : (
              <Box
                display="flex"
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                  },
                  [theme.breakpoints.up("md")]: {
                    flexDirection: "row",
                  },
                }}
              >
                <Typography
                  sx={{
                    marginRight: "15px",
                    fontWeight: "bold",
                    color: "text.secondary",
                  }}
                >
                  Note
                </Typography>
                <Typography
                  sx={{
                    ...(!showMore
                      ? {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }
                      : {}),
                  }}
                >
                  {brain.user_note}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {/* Frozen brain card */}
      <StandardCard
        key={brain.id}
        TopLeft={TopLeft}
        TopRight={TopRight}
        BottomLeft={BottomLeft}
        showMore={showMore}
        setShowMore={setShowMore}
        leftColumnWidth="85%"
        rightColumnWidth="15%"
      />
      {/* Confirm select dialog */}
      <CustomDialog
        header="Freeze Current Brain State?"
        text="This action will overwrite your experiment's current brain state.
            Would you like to freeze it before proceeding?"
        isOpen={confirmOpen}
        handleClose={() => setConfirmOpen(false)}
        actionsChildren={
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setConfirmOpen(false)}
              >
                Cancel
              </Button>
            </Box>
            <Box display="flex" gap="15px">
              <Button
                variant="contained"
                onClick={() => handleSelect(brain.id, true)}
              >
                Freeze
              </Button>
              <Button
                variant="contained"
                onClick={() => handleSelect(brain.id, false)}
              >
                Overwrite
              </Button>
            </Box>
          </Box>
        }
      />
      {/* Confirm delete dialog */}
      <Dialog open={deleteCheckOpen} onClose={() => setDeleteCheckOpen(false)}>
        <DialogTitle>
          Are you sure you want to delete this brain state?
        </DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "5px 10px 10px 10px",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              clearAllAlerts();
              setDeleteCheckOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default ReviveBrainCard;
