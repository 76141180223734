import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import getBrowserInfoForDb from "../../../util/getBrowserInfoForDb";
import SlideUpBox from "../../../util/SlideUpBox";
import robotArmIcon from "../../../img/landing/robotic-arm.svg";
import neuronIcon from "../../../img/landing/neuron.svg";
import stemIcon from "../../../img/landing/computer-science.svg";
import gameIcon from "../../../img/landing/board-game.svg";

const UseCases = ({ isSmOrSmaller }) => {
  const { isMobile } = getBrowserInfoForDb;
  const [selectedUse, setSelectedUse] = useState(null);

  const uses = [
    {
      header: "Robotics",
      subheader: "Build a brain for personal or industrial robots and watch them learn",
      description:
        "Neurorobotics Studio helps you design sophisticated control systems for robots, whether personal or industrial, physical or virtual. You can monitor robot brain functions in real-time and watch them learn and adapt to their environment.",
      image: robotArmIcon,
      imageAlt: "blue robot arm",
    },
    {
      header: "Neuroscience",
      subheader:
        "Learn principals of neuroscience or as a scientist test hypotheses",
      description:
        "Neurorobotics Studio enables the simulation of neurons, where physiological properties can be tuned. Build simple interactions between neurons or use advanced neuroplasticity to develop dynamic neural circuits.",
      image: neuronIcon,
      imageAlt: "blue neuron",
    },
    {
      header: "STEM Education",
      subheader: "Expand knowledge of AI, robotics, and  biological science",
      description:
        "Learn principles that fuel the technological future. While coding is becoming less relevant, foundational skills such as reasoning, logic, and critical thinking will prevail.",
      image: stemIcon,
      imageAlt: "blue science book, atom, and mouse",
    },
    {
      header: "A Fun Challenge",
      subheader: "Tinker with cutting-edge tech and develop artificial brains",
      description:
        "Not into technology and science? Not a problem! Just have fun by loading artificial brains created by our community and let them give rise to fun and unique behaviors.",
      image: gameIcon,
      imageAlt: "blue games",
    },
  ];

  useEffect(() => {
    if (!isSmOrSmaller) {
      setSelectedUse(uses[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmOrSmaller]);

  const handleSelect = (useCase) => {
    setSelectedUse(useCase);
  };

  return (
    <Box
      sx={{
        zIndex: "10",
        mt: { xs: 0, lg: 2 },
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {isSmOrSmaller && (
        <Typography
          sx={{
            fontSize: { xs: "1rem", md: "1.3rem" },
            // color: "accents.light",
            color: "primary.main",
            fontWeight: "600",
          }}
        >
          Tell me more about...
        </Typography>
      )}
      <Box
        sx={{
          // mt: 4,
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          gap: { xs: 4, lg: 1, xl: 4 },
        }}
      >
        <Box
          sx={{
            mt: { xs: 2, lg: 0 },
            mr: { xs: 0, lg: 4 },
            display: "flex",
            flexDirection: { xs: "row", lg: "column" },
            flexWrap: "wrap",
            justifyContent: { xs: "space-around", sm: "space-between" },
            gap: { xs: 2, sm: 6, md: 1, lg: 2 },
          }}
        >
          {/* Use boxes */}
          {uses.map((use) => {
            const isSelected = selectedUse?.header === use.header;

            return (
              <Box
                key={use.header}
                onClick={() => !isSelected && handleSelect(use)}
                sx={(theme) => ({
                  p: selectedUse ? "5px" : "12px",
                  width: selectedUse
                    ? { xs: "60px", md: "90px", lg: "150px" }
                    : "0", // Adjust width when selected
                  height: selectedUse
                    ? { xs: "60px", md: "90px", lg: "150px" }
                    : "calc(50% - 16px)", // Ensure height is equal to width
                  minHeight: "60px",
                  minWidth: "60px",
                  flex: {
                    xs: selectedUse ? "0 1 auto" : "1 1 calc(50% - 16px)",
                    sm: selectedUse ? "0 1 auto" : "1 1 calc(50% - 50px)",
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  border: `1px solid ${
                    isSelected
                      ? theme.palette.primary.main
                      : theme.palette.accents.dark
                  }`,
                  borderRadius: "10px",
                  cursor: isSelected ? "auto" : "pointer",
                  transition: "all 0.3s ease", // Transition for smooth animation on select
                  color: isSelected ? "white" : "accents.light",
                  ...(isMobile
                    ? {
                        "&:active": !isSelected && {
                          border: `1px solid ${theme.palette.primary.main}`,
                        },
                      }
                    : {
                        "&:hover": !isSelected && {
                          border: `1px solid ${theme.palette.primary.main}`,
                        },
                        "&:active": !isSelected && {
                          border: `1px solid ${theme.palette.accents.light}`,
                        },
                      }),
                })}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: selectedUse ? 0 : ".7rem",
                      md: 0,
                      lg: ".7rem",
                    },
                    textTransform: "uppercase",
                    fontWeight: "700",
                    transition: "font-size 0.3s ease",
                  }}
                >
                  {use.header}
                </Typography>
                <Box
                  sx={{
                    mt: 1,
                    width: selectedUse
                      ? { xs: "100%", md: "80px", lg: "120px" }
                      : { xs: "100px", md: "80px", lg: "120px" },
                  }}
                >
                  <img
                    src={use.image}
                    alt={use.imageAlt}
                    style={{
                      width: "100%",
                      maxHeight:
                        selectedUse && isSmOrSmaller
                          ? "50px"
                          : isSmOrSmaller
                          ? "80px"
                          : "90px",
                      transition: "width 0.3s ease",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>

        {/* Selected use details */}
        {selectedUse !== null && (
          <>
            <SlideUpBox
              style={{
                mt: { xs: 2, lg: 0 },
                py: { xs: 6, xl: 8 },
                px: { xs: 4, xl: 8 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: { xs: 4, lg: 1 },
                border: "2px solid #4f7797",
                borderRadius: "35px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {/* Header in border */}
                <Box
                  sx={{
                    position: "absolute",
                    top: { xs: "-60px", lg: "-65px", xl: "-80px" },
                    backgroundColor: "black",
                    zIndex: 1000,
                  }}
                >
                  <Typography
                    sx={{
                      padding: "5px 10px",
                      display: "inline",
                      border: "2px solid #4f7797",
                      borderRadius: "35px",
                      fontWeight: "600",
                      fontSize: { xs: "1rem", lg: "1.3rem" },
                      textTransform: "uppercase",
                      background: "linear-gradient(45deg, #bae1ff, #008aff)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    {selectedUse.header}
                  </Typography>
                </Box>
                {/* Subheader */}
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: { xs: "1.3rem", md: "1.7rem", xl: "2rem" },
                  }}
                >
                  {selectedUse.subheader}
                </Typography>
                {/* Image */}
                <Box
                  sx={{
                    my: 2,
                    width: "100%",
                    maxWidth: {
                      xs: "200px",
                      md: "300px",
                      lg: "350px",
                      xl: "400px",
                    },
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={selectedUse.image}
                    alt={selectedUse.imageAlt}
                    style={{
                      width: "100%",
                      maxHeight: "350px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                {/* Description */}
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: { xs: "1rem", md: "1.3rem" },
                    color: "lightgray",
                  }}
                >
                  {selectedUse.description}
                </Typography>
              </Box>
            </SlideUpBox>
          </>
        )}
      </Box>
      <Box sx={{ width: "100%", mt: { xs: 4, lg: 6 } }}>
        <video
          crossorigin="anonymous"
          muted={true}
          playsInline={true}
          autoPlay={true}
          loop={true}
          controls={false}
          preload="auto"
          style={{
            maxWidth: "100%",
            objectFit: "contain",
            borderRadius: "5px",
          }}
        >
          <source
            src={`https://storage.googleapis.com/nrs_demos/nrs-trailer.mp4#t=0.001`}
            type="video/mp4"
          />
        </video>
      </Box>
    </Box>
  );
};

export default UseCases;
