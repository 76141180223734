import { useEffect, useState } from "react";
import DisplayMarkdown from "../../util/DisplayMarkdown";
import LongContent from "../LongContent";
import PageLoader from "../PageLoader";
import { getTermsOfUse } from "../../api/misc";

function LegalTerms() {
  const [loading, setLoading] = useState(true);
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    async function getTerms() {
      try {
        const response = await getTermsOfUse();
        setMarkdown(response.data);
      } catch (error) {
        console.error("Error getting terms:", error);
      } finally {
        setLoading(false);
      }
    }
    getTerms();
  }, []);

  return (
    <LongContent>
      {loading && <PageLoader />}
      {!loading && markdown && <DisplayMarkdown markdownFromDb={markdown} />}
    </LongContent>
  );
}

export default LegalTerms;
