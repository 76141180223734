export default async function isIntelMac() {
  let isMac = false;
  let isIntel = true; // default to assuming Intel so that warning shows if it can't be determined

  // Determine if Mac
  if (window.navigator?.userAgentData?.platform) {
    isMac = window.navigator.userAgentData.platform
      .toLowerCase()
      .includes("mac");
  } else {
    isMac = window.navigator?.platform?.toLowerCase().includes("mac");
  }

  if (!isMac) return false;

  // Determine if Intel (only works on Chrome as of 4/1/24)
  const highEntropyVals =
    await window.navigator?.userAgentData?.getHighEntropyValues([
      "architecture",
    ]);
  const architecture = highEntropyVals?.architecture;
  if (architecture !== undefined) {
    isIntel = architecture === "x86" || architecture === "x86_64";
  }

  return isIntel;
}
