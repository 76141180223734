import { getUserExperiments } from "../../../api/userManagement";

export default async function refreshExperiments(
  accessToken,
  setExperiments,
  setError,
  setLoading
) {
  try {
    const res = await getUserExperiments(accessToken);
    setExperiments(res.data);
    setLoading(false);
  } catch (error) {
    setError(error);
    setLoading(false);
  }
}
