export default function formatDate(dateStr) {
  const date = new Date(dateStr);

  // Check for invalid date
  if (isNaN(date.getTime())) {
    console.error("Invalid date string provided:", dateStr);
    return null;
  }

  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return formattedDate;
}
