import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../util/auth";
import { Box, Button, CircularProgress, Grid, TextField } from "@mui/material";
import AuthEmailValidation from "./AuthEmailValidation";
import { MailOutline } from "@mui/icons-material";

function AuthForm(props) {
  const auth = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState({});
  const [showForm, setShowForm] = useState(false);

  // Define actions based on form type (sign in, forgot password, etc.)
  const submitHandlersByType = {
    signin: () =>
      auth.signin(email, pass).then((user) => {
        props.onAuth(user);
        // setPass("");
      }),
    signup: () =>
      auth.signup(email, pass).then((user) => {
        props.setSignupSuccess(true);
        props.setPending(false);
        props.onAuth(user);
        // Call google analytics conversion tracking
        if (
          process.env.REACT_APP_NODE_ENV === "production" &&
          window &&
          typeof window.gtag_report_conversion === "function" &&
          !email.includes("neuraville.com") &&
          !email.includes("katpeace")
        ) {
          window.gtag_report_conversion();
        }
        // setPass("");
      }),
    forgotpass: () =>
      auth.sendPasswordResetEmail(email).then(() => {
        props.setPending(false);
        props.onFormAlert({
          type: "success",
          message:
            "If an account exists with this email, a password reset link has been sent.",
        });
      }),
    changepass: () =>
      auth.confirmPasswordReset(pass).then(() => {
        props.setPending(false);
        props.onFormAlert({
          type: "success",
          message: "Your password has been changed",
        });
        // setPass("");
      }),
  };

  // Basic input validation
  const validateForm = () => {
    let errors = {};
    if (!email) errors.email = "Please enter your email";
    if (["signin"].includes(props.type) && pass && pass.length < 8) {
      errors.pass = "Password must be at least 8 characters.";
    }
    if (["signup", "signin", "changepass"].includes(props.type) && !pass) {
      errors.pass = "Please enter a password";
    }
    if (["signup", "changepass"].includes(props.type) && pass !== confirmPass) {
      errors.confirmPass = "Passwords do not match";
    }
    if (["signup", "changepass"].includes(props.type) && pass.length < 8) {
      errors.confirmPass = "Password must be at least 8 characters.";
    }

    setError(errors);
    props.setPending(false);
    return Object.keys(errors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Validate inputs
    if (validateForm()) {
      props.setPending(true);
      // Call correct submit handler (signup/signin: handleAuth from Auth.js)
      submitHandlersByType[props.type]().catch((error) => {
        props.setPending(false);
        // account already exists
        if (props.type === "signup" && error.status === 409) {
          let errMessage =
            "An account already exists with this email. Please sign in.";
          const method = error.body?.registered_method?.[0];
          if (method && method !== "email") {
            errMessage = `An account already exists with this email via ${method}. Please click the provider button to sign in.`;
          }
          props.onFormAlert({
            type: "error",
            message: errMessage,
          });
          // invalid/nonexistent email or password
        } else if (props.type === "signin" && error.status === 401) {
          console.error(error, error.body);
          props.onFormAlert({
            type: "error",
            message:
              error.body.detail === "INVALID_PASSWORD"
                ? "Incorrect password."
                : error.body.detail === "EMAIL_NOT_FOUND"
                ? "No account exists for this email."
                : "Incorrect email or password.",
          });
          // unverified email
        } else if (props.type === "signin" && error.status === 428) {
          console.error(error, error.body);
          props.setShouldVerify(true);
          props.onFormAlert({
            type: "warning",
            message: "This email hasn't been verified.",
          });
        }
        // check if server code or timeout implies server is down
        else if (error.status === 500 || error.status === 408) {
          navigate("/server-down");
          // otherwise just show an error message
        } else if (error.status === 503) {
          props.onFormAlert({
            type: "error",
            message:
              "Sorry, our robots have their hands full. Please try again soon!",
          });
        } else if (error.status === 422) {
          props.onFormAlert({
            type: "error",
            message: "Your email or password is in an invalid format.",
          });
        } else {
          props.onFormAlert({
            type: "error",
            message: "Sorry, an unexpected error occurred. Please retry.",
          });
        }
      });
      props.setPending(false);
    }
  };

  return (
    <>
      {/* After signup success or attempt at unverified email signin, show email verification form */}
      {!props.isSocial && (
        <>
          {["signup"].includes(props.type) &&
            props.signupSuccess &&
            (props.pending || !email ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={28} />
              </Box>
            ) : (
              <AuthEmailValidation
                email={props.signupUser?.email || email}
                onFormAlert={props.onFormAlert}
                pass={pass}
                setPass={setPass}
                shouldVerify={props.shouldVerify}
                setShouldVerify={props.setShouldVerify}
                setSignupSuccess={props.setSignupSuccess}
                handleCloseModal={props.handleCloseModal}
                setType={props.setType}
              />
            ))}

          {props.shouldVerify && (
            <AuthEmailValidation
              email={props.signupUser?.email || email}
              onFormAlert={props.onFormAlert}
              pass={pass}
              setPass={setPass}
              shouldVerify={props.shouldVerify}
              setShouldVerify={props.setShouldVerify}
              setSignupSuccess={props.setSignupSuccess}
              handleCloseModal={props.handleCloseModal}
              setType={props.setType}
            />
          )}
        </>
      )}

      {/* Sign up/in with email small screen button */}
      {props.isSmOrSmaller && !showForm && (
        <Button
          onClick={() => setShowForm(true)}
          variant="outlined"
          size="large"
          color="primary"
          fullWidth={true}
          sx={{ mt: 2 }}
        >
          <div
            style={{
              position: "absolute",
              alignItems: "center",
              display: "inline-flex",
              justifyContent: "center",
              width: "1.5em",
              height: "1.5em",
              left: "0.5em",
              "& > img": {
                display: "block",
                width: "100%",
              },
            }}
          >
            <MailOutline color="primary" />
          </div>
          {["signin"].includes(props.type) ? "Sign in" : "Sign up"} with email
        </Button>
      )}

      {!props.signupSuccess &&
        !props.shouldVerify &&
        (!props.isSmOrSmaller || showForm) && (
          <form onSubmit={handleSubmit}>
            <Grid container={true} spacing={2} mt={2}>
              <>
                {["signup", "signin", "forgotpass"].includes(props.type) && (
                  <Grid item={true} xs={12}>
                    <TextField
                      variant="outlined"
                      type="email"
                      label="Email"
                      name="email"
                      autocomplete="email"
                      placeholder="user@example.com"
                      error={!!error.email}
                      helperText={error.email}
                      fullWidth={true}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                )}

                {["signup", "signin", "changepass"].includes(props.type) && (
                  <Grid item={true} xs={12}>
                    <TextField
                      variant="outlined"
                      type="password"
                      label="Password"
                      name="pass"
                      autocomplete={
                        "signin" ? "current-password" : "new-password"
                      }
                      error={!!error.pass}
                      helperText={error.pass}
                      fullWidth
                      value={pass}
                      onChange={(e) => setPass(e.target.value)}
                    />
                  </Grid>
                )}

                {["signup", "changepass"].includes(props.type) && (
                  <Grid item={true} xs={12}>
                    <TextField
                      variant="outlined"
                      type="password"
                      label="Confirm Password"
                      name="confirmPass"
                      autocomplete="new-password"
                      error={!!error.confirmPass}
                      helperText={error.confirmPass}
                      fullWidth
                      value={confirmPass}
                      onChange={(e) => setConfirmPass(e.target.value)}
                    />
                  </Grid>
                )}

                <Grid item={true} xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    disabled={props.pending}
                    fullWidth={true}
                    onClick={(event) => {
                      props.setPending(true);
                      handleSubmit(event);
                    }}
                  >
                    {!props.pending && <span>{props.buttonAction}</span>}

                    {props.pending && <CircularProgress size={28} />}
                  </Button>
                </Grid>
              </>
            </Grid>

            {/* {["signup"].includes(props.type) && props.signupSuccess && (
        <Grid item={true} xs={12}>
          {!props.pending && (
            <>
              <span style={{ marginBottom: "10px" }}>
                Your account was successfully created! Please check your email
                for a security code and enter it below:
              </span>
              <Box
                onPaste={handlePaste}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                {token.map((digit, index) => (
                  <input
                    key={index}
                    id={`token-field-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={handleChange(index)}
                    onKeyDown={handleKeyDown(index)}
                    style={{ width: "20px", margin: "0 5px" }}
                  />
                ))}
              </Box>
              <Button onClick={handleSubmitCode}>Submit</Button>
            </>
          )}
          {props.pending && <CircularProgress size={28} />}
        </Grid>
      )} */}
          </form>
        )}
    </>
  );
}

export default AuthForm;
