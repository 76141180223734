import { CircularProgress, Backdrop, Typography } from "@mui/material";

const FullscreenLoader = ({ message = "Loading..." }) => {
  return (
    <Backdrop
      open={true}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bgcolor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <CircularProgress size={60} thickness={4} color="primary" />
      {message && (
        <Typography variant="h6" sx={{ color: "accents.light" }}>
          {message}
        </Typography>
      )}
    </Backdrop>
  );
};

export default FullscreenLoader;
