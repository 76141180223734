import { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useAuth } from "../../util/auth";
import { getUserByToken, updateUser } from "../../api/userManagement";

function SettingsRobots(props) {
  const auth = useAuth();
  const accessToken = auth.user.accessToken;
  const [userObject, setUserObject] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [pending, setPending] = useState(false);
  // const [formErrors, setFormErrors] = useState({});
  const [robot, setRobot] = useState("");

  const robots = ["Arm", "Freenove"];

  const handleChange = (event) => {
    setRobot(event.target.value);
  };

  // const userObject = {
  //   account_number: "123",
  //   email: auth.user.email,
  //   username: "Testy",
  //   region: "US EAST",
  // };
  useEffect(() => {
    getUserByToken(accessToken).then(({ data }) => {
      setUserObject(data);
      setDisplayName(data.display_name);
    });
  }, [accessToken]);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setPending(true);
    // setFormErrors({});

    try {
      // Update user
      await updateUser(accessToken, null, displayName);

      // Set success status
      props.onStatus({
        type: "success",
        message: "Your robot registrations have been updated",
      });
    } catch (error) {
      // Set error status
      props.onStatus({
        type: "error",
        message: error.message,
      });
    } finally {
      setPending(false);
    }
  };

  if (!userObject) {
    return <CircularProgress />;
  }

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="robot-options-label">Robot</InputLabel>
            <Select
              labelId="robot-options-label"
              id="robot-options"
              value={robot}
              label="Robot"
              onChange={handleChange}
            >
              {robots.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item={true} xs={12}>
          {/* content */}
        </Grid>
        <Grid item={true} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={pending}
            fullWidth={true}
          >
            {!pending && <span>Save</span>}
            {pending && <CircularProgress size={28} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default SettingsRobots;
