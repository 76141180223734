export default function formatDate(utcString) {
  try {
    const date = new Date(utcString + "Z");
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const formattedDate = new Intl.DateTimeFormat(undefined, options);
    return formattedDate.format(date);
  } catch (err) {
    console.error(err);
  }
}
