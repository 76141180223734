import { useState } from "react";
import { useAuth } from "../../util/auth";
import {
  Alert,
  Box,
  DialogContent,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import NavbarFeedbackThoughts from "./NavbarFeedbackThoughts";
import NavbarFeedbackIssue from "./NavbarFeedbackIssue";
import { Close as CloseIcon } from "@mui/icons-material";

function NavbarFeedbackSection({ setFeedbackOpen }) {
  const auth = useAuth();
  const accessToken = auth.user.accessToken;
  const [activeKey, setActiveKey] = useState("thoughts");
  const [showThanks, setShowThanks] = useState(false);
  const [error, setError] = useState("");

  const handleTabChange = (event, newValue) => {
    setActiveKey(newValue);
  };

  const handleCancel = () => {
    setFeedbackOpen(false);
  };

  const handlePostSubmit = () => {
    setShowThanks(true);
  };

  const handleThanksClose = () => {
    setFeedbackOpen(false);
    setShowThanks(false);
  };

  return showThanks ? (
    <DialogContent
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6">Received</Typography>
        <IconButton onClick={handleThanksClose} sx={{ alignSelf: "flex-end" }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ height: "100px", display: "flex", alignItems: "center" }}>
        <Typography>Thanks for your message!</Typography>
      </Box>
    </DialogContent>
  ) : (
    <>
      <Tabs
        value={activeKey}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered={true}
        sx={{ backgroundColor: "background.default" }}
      >
        <Tab
          label="Share your thoughts"
          value="thoughts"
          sx={{ fontSize: { xs: "0.75rem", md: "0.875rem" } }}
        />
        <Tab
          label="Report a bug"
          value="issues"
          sx={{ fontSize: { xs: "0.75rem", md: "0.875rem" } }}
        />
      </Tabs>
      {/* Error message */}
      {error && (
        <DialogContent sx={{ paddingBottom: 0 }}>
          <Alert severity="error">{error}</Alert>
        </DialogContent>
      )}
      {/* Conditional rendering based on active tab */}
      {activeKey === "thoughts" && (
        <NavbarFeedbackThoughts
          accessToken={accessToken}
          handlePostSubmit={handlePostSubmit}
          handleCancel={handleCancel}
          setError={setError}
        />
      )}
      {activeKey === "issues" && (
        <NavbarFeedbackIssue
          accessToken={accessToken}
          handlePostSubmit={handlePostSubmit}
          handleCancel={handleCancel}
          setError={setError}
        />
      )}
    </>
  );
}

export default NavbarFeedbackSection;
