function processImageData(imageData, width, height) {
  if (!imageData) return [];

  const rgbArray = width && height ? [width, height] : [];

  for (let i = 0; i < imageData.length; i += 4) {
    rgbArray.push(imageData[i], imageData[i + 1], imageData[i + 2]);
  }

  return rgbArray;
}

// function processImageData(imageData, width, height) {
//   if (!imageData) return [];

//   const rgbArray =
//     width && height
//       ? [width, height, ...new Array((imageData.length / 4) * 3)]
//       : new Array((imageData.length / 4) * 3);

//   let offset = width && height ? 2 : 0;

//   for (let i = 0; i < imageData.length; i += 4) {
//     rgbArray[offset++] = imageData[i]; // Red
//     rgbArray[offset++] = imageData[i + 1]; // Green
//     rgbArray[offset++] = imageData[i + 2]; // Blue
//   }

//   return rgbArray;
// }

export default processImageData;
