import { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { StyledButton, StyledPopover } from "../../styles/NavbarDropdownStyles";
import {
  Info as InfoIcon,
  Star as StarIcon,
  StarOutline as StarOutlineIcon,
} from "@mui/icons-material";
import {
  toggleGenomePublic,
  toggleGenomeFavorite,
  getGenomeById,
} from "../../api/genomeManagement";
import TogglePublic from "../TogglePublic";
import CustomDialog from "../CustomDialog";
import GenomeAugment from "./genome/GenomeAugment";
import GenomeChange from "./genome/GenomeChange";
import GenomeCurrent from "./genome/GenomeCurrent";
import GenomeExport from "./genome/GenomeExport";

const NavbarGenome = ({
  experiment,
  accessToken,
  error,
  setError,
  setMessage,
  setRefetchExperiment,
  awaitAugment,
  setAwaitAugment,
  isSmOrSmaller,
}) => {
  // Entire genome dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "complex-popover" : undefined;
  // Current genome (IF YOU WANT SEPARATE SEED GENOME CODE, SEE LOCAL TRASHES/)
  const [currentGenome, setCurrentGenome] = useState(null);
  const [currentOpen, setCurrentOpen] = useState(false);
  // Augment/change genome
  const [currentAugmentOpen, setCurrentAugmentOpen] = useState(false);
  const [genomeChangeOpen, setGenomeChangeOpen] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  // Export genome
  const [exportOpen, setExportOpen] = useState(false);
  // Refetch genome
  const [refetchGenome, setRefetchGenome] = useState(false);
  // Toggle public/private
  const [isCurrentPublic, setIsCurrentPublic] = useState(false);
  const [confirmTogglePublicOpen, setConfirmTogglePublicOpen] = useState(false);
  const [confirmTogglePrivateOpen, setConfirmTogglePrivateOpen] =
    useState(false);
  // Toggle fave
  const [isCurrentFave, setIsCurrentFave] = useState(null);
  const [currentFaveCount, setCurrentFaveCount] = useState(null);

  // Set genome for info popup on initial load (need public/fave info anyway)
  useEffect(() => {
    async function fetchGenomes() {
      try {
        const currentRes = await getGenomeById({
          accessToken,
          genomeId: experiment.experiment_genome_id,
        });
        const resCurrentGenome = currentRes.data;
        setCurrentGenome(resCurrentGenome);
        setIsCurrentPublic(resCurrentGenome.public);
        setIsCurrentFave(resCurrentGenome.is_favorite);
        setCurrentFaveCount(resCurrentGenome.stars);
      } catch (err) {
        console.error(err);
      }
    }

    accessToken && experiment && fetchGenomes();
  }, [accessToken, experiment, refetchGenome]);

  useEffect(() => {
    setRefetchGenome((prev) => !prev);
  }, [isCurrentPublic, isCurrentFave, currentFaveCount, setRefetchGenome]);

  // Open/close entire genome dropdown
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Genome change popup
  const handleGenomeChangeOpen = () => {
    handleClose(); // close genome dropdown first to prevent focustrap issues
    setGenomeChangeOpen(true);
  };

  const handleGenomeChangeClose = () => {
    setGenomeChangeOpen(false);
  };

  // Genome card popup from info button
  const handleCurrentOpen = async () => {
    try {
      setError("");
      handleClose();
      if (!experiment || !currentGenome) {
        throw new Error("Genome data unavailable.");
      }
      setCurrentOpen(true);
    } catch (err) {
      console.error(err);
      handleClose();
      setError(
        "Sorry, we were unable to retrieve the genome data. Please try again soon."
      );
    }
  };

  const handleCurrentClose = () => {
    setCurrentOpen(false);
  };

  // Close augment popup
  const handleCurrentAugmentClose = () => {
    setCurrentAugmentOpen(false);
  };

  useEffect(() => {
    awaitAugment && handleClose();
  }, [awaitAugment]);

  // Toggle genome public/private
  async function togglePublic() {
    try {
      if (!accessToken) throw new Error("Unable to get user data");
      if (!experiment) throw new Error("Experiment data unavailable.");
      await toggleGenomePublic(accessToken, experiment.experiment_genome_id);
      setIsCurrentPublic((prev) => !prev);
    } catch (error) {
      console.error(error);
      handleClose();
      setError(
        "Sorry, we were unable to change your experiment visibility. Please try again soon."
      );
    }
  }

  // Genome to private handling
  const handleConfirmTogglePrivate = () => {
    setConfirmTogglePrivateOpen(false);
    togglePublic();
  };

  const handleConfirmTogglePrivateClose = () => {
    setConfirmTogglePrivateOpen(false);
  };

  // Toggle genome fave
  async function toggleFave() {
    try {
      if (currentFaveCount === null) {
        throw new Error("Unable to get current fave count.");
      }
      await toggleGenomeFavorite(accessToken, experiment.experiment_genome_id);
      isCurrentFave
        ? setCurrentFaveCount((prev) => prev - 1)
        : setCurrentFaveCount((prev) => prev + 1);
      setIsCurrentFave((prev) => !prev);
    } catch (error) {
      console.error(error);
      handleClose();
      setError(
        "Sorry, we were unable to edit your favorite. Please try again soon."
      );
    }
  }

  return (
    <>
      <StyledButton
        aria-describedby={id}
        onClick={handleClick}
        sx={{ minWidth: { xs: "24px", md: "64px" } }}
        disabled={awaitAugment || !experiment || !accessToken}
      >
        {isSmOrSmaller ? "Gen" : "Genome"}
      </StyledButton>

      {/* Genome dropdown */}
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box display="flex" flexDirection="column" width="100%">
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            style={{ gap: "10px" }}
          >
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              style={{ gap: "15px" }}
            >
              <Box
                display="flex"
                width="100%"
                flexDirection="column"
                gap="15px"
              >
                {/* Title & info button */}
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography
                    sx={{
                      maxWidth: "100%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontWeight: experiment?.experiment_genome_title
                        ? "bold"
                        : "normal",
                      fontStyle: experiment?.experiment_genome_title
                        ? "normal"
                        : "italic",
                    }}
                  >
                    {experiment?.experiment_genome_title
                      ? experiment.experiment_genome_title
                      : "Loading title . . ."}
                  </Typography>
                  <Tooltip title="Genome info">
                    <IconButton onClick={handleCurrentOpen}>
                      <InfoIcon sx={{ fontSize: "1rem" }} />
                    </IconButton>
                  </Tooltip>
                </Box>

                {/* Replace button */}
                <Box display="flex">
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={handleGenomeChangeOpen}
                  >
                    Replace
                  </Button>
                </Box>
              </Box>

              {/* Toggle public & fave */}
              <Box width="100%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  maxWidth="100%"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gap="10px"
                  >
                    <Typography>Public</Typography>
                    <Switch
                      onClick={() => {
                        isCurrentPublic
                          ? setConfirmTogglePrivateOpen(true)
                          : setConfirmTogglePublicOpen(true);
                      }}
                      checked={!!isCurrentPublic}
                    />
                  </Box>
                  <Box
                    sx={{
                      padding: "4px 3px",
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                      border: `1px solid #444444`,
                      borderRadius: "5px",
                      backgroundColor: "accents.dark",
                    }}
                  >
                    <IconButton
                      sx={{
                        padding: 0,
                        "&& .MuiIconButton-root": {
                          padding: "4px",
                          fontSize: "1rem",
                        },
                        "&& .MuiButtonBase-root": {
                          padding: "4px",
                        },
                      }}
                      onClick={toggleFave}
                    >
                      {isCurrentFave ? (
                        <StarIcon style={{ color: "gold" }} />
                      ) : (
                        <StarOutlineIcon />
                      )}
                    </IconButton>
                    <Typography
                      sx={{
                        fontFamily: "'Courier New', Courier, monospace",
                        width: "4ch",
                      }}
                    >
                      {currentFaveCount !== null ? currentFaveCount : "?"}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Divider */}
              <Box
                component="hr"
                sx={{
                  width: "100%",
                  borderColor: "accents.main",
                }}
              />

              {/* Brain regions */}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <Typography
                  sx={{
                    maxWidth: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    fontWeight: "bold",
                  }}
                >
                  Brain Regions
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Tooltip title="Augment your current genome with another genome">
                    <span>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => setCurrentAugmentOpen(true)}
                      >
                        Import
                      </Button>{" "}
                    </span>
                  </Tooltip>
                  <Tooltip title="Add your current genome to the genome pool">
                    <span>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => setExportOpen(true)}
                      >
                        Export
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </StyledPopover>

      {/* Current genome popup */}
      <GenomeCurrent
        accessToken={accessToken}
        error={error}
        setError={setError}
        currentGenome={currentGenome}
        currentOpen={currentOpen}
        setCurrentOpen={setCurrentOpen}
        handleCurrentClose={handleCurrentClose}
        setRefetchGenome={setRefetchGenome}
      />

      {/* Toggle public confirm popup */}
      {confirmTogglePublicOpen && (
        <TogglePublic
          confirmTogglePublicOpen={confirmTogglePublicOpen}
          setConfirmTogglePublicOpen={setConfirmTogglePublicOpen}
          togglePublic={togglePublic}
        />
      )}

      {/* Toggle private confirm popup */}
      <CustomDialog
        header="Make your genome private?"
        text="This will hide your genome from other users. Any experiments/genomes already created by other users using this genome will remain."
        isOpen={confirmTogglePrivateOpen}
        handleClose={handleConfirmTogglePrivateClose}
        cancelAction={handleConfirmTogglePrivateClose}
        confirmAction={handleConfirmTogglePrivate}
      />

      {/* Genome change popup */}
      <GenomeChange
        genomeChangeOpen={genomeChangeOpen}
        handleGenomeChangeClose={handleGenomeChangeClose}
        error={error}
        setError={setError}
      />

      {/* Genome augment popup */}
      <GenomeAugment
        currentAugmentOpen={currentAugmentOpen}
        handleCurrentAugmentClose={handleCurrentAugmentClose}
        error={error}
        setError={setError}
        setAwaitAugment={setAwaitAugment}
        isCalling={isCalling}
        setIsCalling={setIsCalling}
      />

      {/* Genome export popup */}
      <GenomeExport
        accessToken={accessToken}
        genomeId={experiment?.experiment_genome_id}
        setError={setError}
        exportOpen={exportOpen}
        setExportOpen={setExportOpen}
        setMessage={setMessage}
      />
    </>
  );
};

export default NavbarGenome;
