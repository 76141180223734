import { Box, CircularProgress, Container } from "@mui/material";
import Section from "./Section";

function PageLoader(props) {
  const { height = 350 } = props;

  return (
    <Section bgColor="default" style={{ width: "100%" }}>
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={height}
        >
          {!props.children && <CircularProgress size={32} />}

          {props.children && <>{props.children}</>}
        </Box>
      </Container>
    </Section>
  );
}

export default PageLoader;
