import { useEffect, useState } from "react";
import CustomDialog from "./CustomDialog";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";
import { fetchPublicConsentTerms } from "../api/genomeManagement";
import DisplayMarkdown from "../util/DisplayMarkdown";
import { useAuth } from "../util/auth";

const TogglePublic = ({
  confirmTogglePublicOpen,
  setConfirmTogglePublicOpen,
  togglePublic,
}) => {
  const auth = useAuth();
  const accessToken = auth.user.accessToken;
  const [termsMarkdown, setTermsMarkdown] = useState("");
  const [publicTermsAgree, setPublicTermsAgree] = useState(false);
  const [pending, setPending] = useState(true);
  const [error, setError] = useState("");
  const [togglePublicError, setTogglePublicError] = useState("");

  useEffect(() => {
    async function fetchTerms() {
      try {
        const res = await fetchPublicConsentTerms(accessToken);
        if (!res.data) {
          console.error("Missing terms data:", res);
          throw new Error("Missing terms data");
        }
        setTermsMarkdown(res.data);
      } catch (err) {
        console.error("Error fetching public consent terms:", err);
        setError(
          "Error fetching terms on making genomes public. Please try reloading the page to confirm them."
        );
      } finally {
        setPending(false);
      }
    }

    fetchTerms();
  }, [accessToken]);

  const handleConfirmTogglePublic = () => {
    if (!publicTermsAgree) {
      setTogglePublicError(
        "Please agree to the terms to make your genome public."
      );
      return;
    }
    togglePublic();
    setConfirmTogglePublicOpen(false);
  };

  const handleConfirmTogglePublicClose = () => {
    setConfirmTogglePublicOpen(false);
  };

  return (
    <CustomDialog
      header="Make your genome public?"
      text="This will make your genome visible to all users. If you change it back to private later, any new genomes created from this genome will remain."
      isOpen={confirmTogglePublicOpen}
      handleClose={handleConfirmTogglePublicClose}
      richText={
        <>
          {/* Terms content */}
          <Box
            sx={{
              maxHeight: { xs: "30vh", sm: "40vh" },
              mt: 2,
              px: 2,
              overflowY: "scroll",
              fontSize: ".75rem",
              backgroundColor: "#1d1d1d",
            }}
          >
            {termsMarkdown && (
              <DisplayMarkdown markdownFromDb={termsMarkdown} />
            )}
          </Box>

          <Box>
            {/* Errors */}
            {togglePublicError && (
              <Alert severity="warning">{togglePublicError}</Alert>
            )}
            {error && <Alert severity="error">{error}</Alert>}

            {/* Loading spinner */}
            {pending && (
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  mb: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </>
      }
      // Agree & buttons
      actionsChildren={
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Agree checkbox */}
          <Box sx={{ mb: 1, display: "flex", alignItems: "center", gap: 1 }}>
            <Checkbox
              inputProps={{ "aria-label": "public-terms-agree" }}
              onChange={() => setPublicTermsAgree((prev) => !prev)}
            />
            <Typography variant="caption">
              I agree to the terms and conditions
            </Typography>
          </Box>
          {/* Cancel/Confirm */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={handleConfirmTogglePublicClose} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleConfirmTogglePublic}
              variant="contained"
              disabled={!termsMarkdown}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      }
    />
  );
};

export default TogglePublic;
