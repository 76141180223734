import React from "react";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PlayHtmlAppMaker from "./PlayHtmlAppMaker";
import PlayFeagi from "./PlayFeagi";

const PlayVirtualGames = ({
  config,
  embodiment,
  clusterId,
  setClusterId,
  sessionId,
  setSessionId,
  isSmOrSmaller,
  isAbove2XL,
}) => {
  const [embodimentId, setEmbodimentId] = useState(
    embodiment?.embodiment_id || ""
  );
  const [dimensions, setDimensions] = useState(embodiment?.dimensions || {});

  useEffect(() => {
    if (embodiment) {
      setEmbodimentId(embodiment?.embodiment_id || "");
      setDimensions(embodiment?.dimensions || { aspect_ratio: 1 });
    }
  }, [embodiment]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: {
          xs: "column",
          lg:
            !dimensions?.aspect_ratio || dimensions.aspect_ratio < 1.2
              ? "row"
              : "column",
        },
        gap: {
          xs: 4,
          lg:
            !dimensions?.aspect_ratio || dimensions.aspect_ratio < 1.2 ? 4 : 2,
        },
      }}
    >
      {config?.player_1 != null && (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: isSmOrSmaller ? "1rem" : "1.3rem",
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            {config.player_1.label}
          </Typography>
          <PlayHtmlAppMaker
            player={1}
            feagiIndex={
              config.player_1.capabilities?.input?.camera?.[0]?.feagi_index
            }
            outputFeagiIndex={
              config.player_1.capabilities?.output?.motion_control?.[0]
                ?.feagi_index
            }
            outputMotorFeagiIndex={
              config.player_1.capabilities?.output?.motor?.[0]?.feagi_index
            }
            port="9055"
            embodimentId={embodimentId}
            sessionId={sessionId}
            clusterId={clusterId}
            isSmOrSmaller={isSmOrSmaller}
            isAbove2XL={isAbove2XL}
            dimensions={dimensions}
          />
        </Box>
      )}

      {config?.player_2 != null && (
        <PlayFeagi
          embodimentId={embodimentId}
          sessionId={sessionId}
          setSessionId={setSessionId}
          clusterId={clusterId}
          setClusterId={setClusterId}
          config={config.player_2}
          isSmOrSmaller={isSmOrSmaller}
          isAbove2XL={isAbove2XL}
          dimensions={dimensions}
        />
      )}
    </Box>
  );
};

export default PlayVirtualGames;
