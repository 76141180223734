import { getExperimentSessions } from "../../../api/experimentManagement";

// View a experiment
export default async function viewExperiment(
  experimentId,
  setLoading,
  setError,
  setMessage,
  accessToken
) {
  setMessage("");
  setError("");
  setLoading(true);

  // Error messages
  const errorMessages = {
    dbError:
      "Could not retrieve experiment data from server. If issues persist, try stopping and restarting your experiment.",
    missingExperimentId:
      "Unable to identify currently selected experiment. Please reload.",
    missingAccessToken: "Unable to validate user credentials. Please reload.",
  };

  try {
    // Handle missing data
    if (!experimentId) {
      throw new Error(errorMessages.missingExperimentId);
    }
    if (!accessToken) {
      throw new Error(errorMessages.missingAccessToken);
    }

    // Get experiment session data
    const { data } = await getExperimentSessions(experimentId, accessToken);

    if (!data || data.length < 1) {
      throw new Error(errorMessages.dbError);
    }

    const sessionId = data[0].session_id;
    const clusterId = data[0].feagi_cluster;

    if (!sessionId || !clusterId) {
      throw new Error(errorMessages.dbError);
    }

    // Redirect to brain visualizer
    window.location.href = `/brain-visualizer?id=${sessionId}&cluster=${clusterId}`;
  } catch (error) {
    // Handle errors
    console.error(error);
    setLoading(false);
    setError("Error launching experiment. " + error.message);
  }
}
