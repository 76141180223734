import { deleteExperiment } from "../../../api/experimentManagement";
import { logger } from "../../../util/logger";
import refreshExperiments from "./refreshExperiments";

export default async function callDeleteExperiment(
  accessToken,
  experimentId,
  setLoading,
  setError,
  setMessage,
  setExperiments
) {
  setError("");
  setMessage("");
  setLoading(true);
  try {
    if (!accessToken) throw new Error("Unable to retrieve user token.");
    if (!experimentId) throw new Error("Unable to retrieve experiment ID.");
    const res = await deleteExperiment(accessToken, experimentId);
    logger("response in deleteExperiment:", res);
    // add check to confirm deletion like in stop experiment
    await refreshExperiments(accessToken, setExperiments, setError, setLoading);
    setLoading(false);
    setMessage("Experiment successfully deleted.");
  } catch (error) {
    console.error(error);
    setLoading(false);
    setError(
      "Sorry, there was an error deleting the experiment. Please reload and retry."
    );
  }
}
