import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Box, Container, Typography } from "@mui/material";
import disappointedRobot from "../img/robot-bewildered-transparent.png";

function ServerDown(props) {
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container
        maxWidth="md"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SectionHeader
          title="Server Unavailable"
          subtitle=""
          size={4}
          textAlign="center"
        />
        <Box
          marginTop="10px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>Sorry, the server is currently down.</Typography>
          <Typography>Please try back soon.</Typography>
          <img
            src={disappointedRobot}
            alt="bewildered robot"
            style={{ maxWidth: "400px" }}
          ></img>
        </Box>
      </Container>
    </Section>
  );
}

export default ServerDown;
