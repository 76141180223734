import { useParams } from "react-router-dom";
import Meta from "./../components/Meta";
import LegalSection from "./../components/legal/LegalSection";

function LegalPage(props) {
  const { section } = useParams();

  return (
    <>
      <Meta title="Legal" />
      <LegalSection
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        section={section}
        key={section}
      />
    </>
  );
}

export default LegalPage;
