import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Button, TextField, Tooltip } from "@mui/material";
import physicalEnvironment from "../../img/environment-physical.png";
import virtualEnvironment from "../../img/environment-virtual.png";
import genomeTransparent from "../../img/genome-transparent.png";
import aauoxsin from "../../img/em-aauoxsin.png"; // robot arm
import kxrhxvtv from "../../img/em-bsfuiref.png"; // cutebot microbit
import kbxamajf from "../../img/em-kbxamajf.png"; // freenove
import nbnhzdqc from "../../img/em-nbnhzdqc.png"; // drone
import dgocpqka from "../../img/em-zkmemyyd.png"; // pong
import defaultImage from "../../img/robot-neutral.png";
import PageLoader from "../PageLoader";
// import getBrowserInfo from "../../util/getBrowserInfo";
// import { getUserExperiments } from "../../api/userManagement";
import { useAuth } from "../../util/auth";
import { createExperiment } from "../../api/experimentManagement";
import getBrowserInfo from "../../util/getBrowserInfo";
import { logger } from "../../util/logger";
import SignupModal from "../../util/SignupModal";
import SummaryCard from "./NewExperimentSummaryCard";

const imageMap = {
  aauoxsin: aauoxsin,
  kxrhxvtv: kxrhxvtv,
  kbxamajf: kbxamajf,
  nbnhzdqc: nbnhzdqc,
  dgocpqka: dgocpqka,
};

const NewExperimentSummary = ({
  collectedData,
  setCollectedData,
  setError,
}) => {
  const auth = useAuth();
  const accessToken = auth?.user?.accessToken;
  const [tokenChecked, setTokenChecked] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [signupModalOpen, setSignupModalOpen] = useState(false);
  const [createOnlyMessage, setCreateOnlyMessage] = useState("");
  const [experimentsMax, setExperimentsMax] = useState(false);
  const [imageId, setImageId] = useState(
    collectedData?.embodimentId?.replace("em-", "")
  );
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");

  const cardData = [
    {
      title: collectedData.environmentTitle,
      imageSrc:
        collectedData.environmentTitle === "physical"
          ? physicalEnvironment
          : virtualEnvironment,
      altText: "environment",
    },
    {
      title: collectedData.embodimentTitle,
      imageSrc:
        collectedData.embodimentUrl || imageMap[imageId] || defaultImage,
      altText: collectedData.embodimentTitle,
    },
    {
      title: collectedData.genomeTitle,
      imageSrc: genomeTransparent,
      altText: "blue genome",
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setTokenChecked(true);
    }, "1000");
  }, []);

  // Check if user can launch experiment
  // useEffect(() => {
  //   const initiateCheck = async () => {
  //     console.log("checking");
  //     try {
  //       if (!accessToken) {
  //         throw new Error("Missing user validation token");
  //       }
  //       const message = await checkCanLaunch(accessToken);
  //       setCreateOnlyMessage(message);
  //     } catch (err) {
  //       console.error(err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   console.log("in initiateCheck useEffect");
  //   initiateCheck();
  // }, [accessToken]);

  // Check if mobile
  useEffect(() => {
    async function fetchBrowserInfo() {
      const info = await getBrowserInfo();
      if (info?.isMobile) {
        setCreateOnlyMessage(
          "Mobile device detected. You can create an experiment on mobile, but desktop will be required to run the brain visualizer."
        );
        return false;
      }
      return true;
    }

    fetchBrowserInfo();
  }, [accessToken]);

  // Show embodiment image based on embodiment ID
  useEffect(() => {
    if (collectedData) {
      if (collectedData.embodimentId)
        setImageId(collectedData.embodimentId.replace("em-", ""));
    }
  }, [collectedData]);

  // Show message if an experiment running or system overloaded
  useEffect(() => {
    if (accessToken) {
      const launchCriteria = localStorage.getItem("launchCriteria");
      if (launchCriteria) {
        const {
          system_overload,
          max_concurrent_experiments_reached,
          max_experiment_count_reached,
        } = JSON.parse(launchCriteria);
        if (max_concurrent_experiments_reached) {
          setCreateOnlyMessage(
            "You already have an experiment running. You can still create an experiment, but you will need to stop your running experiment from the lab to launch it."
          );
        } else if (system_overload) {
          setCreateOnlyMessage(
            "The server is currently at capacity. You can create an experiment, but you will need to wait for the server to have available resources to launch it."
          );
        }
        if (max_experiment_count_reached) {
          setExperimentsMax(true);
        }
        localStorage.removeItem("launchCriteria");
      }
      setLoading(false);
    } else if (!accessToken && tokenChecked) {
      setLoading(false);
    }
  }, [auth, auth?.user, accessToken, tokenChecked]);

  // Validate title
  const validateForm = () => {
    if (!title.trim()) {
      setTitleError("Please enter a title for your experiment");
      return false;
    }
    return true;
  };

  const callCreateExperiment = async () => {
    try {
      const res = await createExperiment(
        accessToken,
        title,
        collectedData.environmentId,
        collectedData.embodimentId,
        collectedData.genomeId
      );
      const experimentId = res.data?.experiment_id;
      if (!experimentId) {
        throw new Error(
          "Did not receive experiment ID from server:",
          experimentId
        );
      }
      localStorage.removeItem("collectedData");
      localStorage.removeItem("activeStep");
      localStorage.removeItem("title");
      return experimentId;
    } catch (err) {
      console.error(err);
      setError(
        "Error creating experiment. Please try again, or reload if issues persist."
      );
    }
  };

  // Handle submit
  async function handleSubmit(event) {
    event.preventDefault();
    try {
      setTitleError("");
      if (!validateForm()) return; // checks title exists

      setLoading(true);
      logger("submitted", title, collectedData);

      if (
        !collectedData.environmentId ||
        !collectedData.embodimentId ||
        !collectedData.genomeId
      ) {
        throw new Error(
          "Missing required selections. Please try again or start from the first step (environment)."
        );
      }

      setCollectedData({
        ...collectedData,
        title: title,
      });

      localStorage.setItem(
        "collectedData",
        JSON.stringify({
          ...collectedData,
          title: title,
        })
      );

      localStorage.removeItem("experimentId"); // For BrainLoading use
      const experimentId = await callCreateExperiment();
      if (createOnlyMessage) {
        setSignupModalOpen(true);
      } else {
        // navigate(`/brain-loading?id=create`);
        navigate(`/brain-loading?id=${experimentId}`);
      }
    } catch (error) {
      // add error handling
      console.error(error);
      setError("Error launching experiment. Please try again.");
      setLoading(false);
    }
  }

  if (loading) return <PageLoader height={50} />;

  return (
    <>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        gap="20px"
        sx={{ mt: { xs: 3, md: 1, lg: 0 } }}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginBottom="15px"
        >
          <Box width="100%" display="flex" justifyContent="center">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
                width: { xs: "100%", sm: "70%", md: "100%" },
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              {cardData.map((data, index) => (
                <SummaryCard
                  key={index}
                  title={data.title}
                  imageSrc={data.imageSrc}
                  altText={data.altText}
                />
              ))}
            </Box>
          </Box>
        </Box>

        {/* Name & Launch */}
        <Box width="100%" display="flex" flexDirection="column">
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <TextField
              variant="outlined"
              type="text"
              label="Name your experiment!*"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              error={!!titleError}
              helperText={titleError}
              style={{
                marginBottom: "16px",
                width: "100%",
                minWidth: "200px",
                textAlign: "center",
              }}
            />
            {/* <FormControlLabel
            label="Brain Analyzer"
            control={
              <Checkbox defaultChecked name="analyzer" inputRef={register} />
            }
          /> */}
            {createOnlyMessage && !experimentsMax && (
              <Alert severity="warning" sx={{ alignItems: "center" }}>
                {createOnlyMessage}
              </Alert>
            )}
            <Tooltip
              title={
                experimentsMax
                  ? "Max experiments reached. Delete one in lab to make new"
                  : createOnlyMessage
                  ? "Create"
                  : !auth?.user
                  ? "Create an account to make an experiment"
                  : "Launch"
              }
            >
              <span>
                {!tokenChecked || auth.user ? (
                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    disabled={experimentsMax || !accessToken}
                  >
                    {createOnlyMessage ? "Create" : "Launch"} Experiment
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => setSignupModalOpen(true)}
                  >
                    Sign Up to Create
                  </Button>
                )}
              </span>
            </Tooltip>
          </form>
        </Box>
      </Box>
      {signupModalOpen && (
        <SignupModal
          open={signupModalOpen}
          setOpen={setSignupModalOpen}
          afterAuthPath="/new-experiment"
        />
      )}
    </>
  );
};

export default NewExperimentSummary;
