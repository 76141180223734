import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import DisplayMarkdown from "../../../util/DisplayMarkdown";
import { getEmbodimentConnectivityInstructions } from "../../../api/embodimentManagement";
import MagicLink from "../../MagicLink";

const EmbodimentInstructions = ({
  accessToken,
  embodimentName,
  embodimentId,
  instructionsOpen,
  setInstructionsOpen,
  handleClose,
  setError,
}) => {
  const [markdown, setMarkdown] = useState("");
  const [link, setLink] = useState("");

  useEffect(() => {
    async function fetchInstructions() {
      try {
        const res = await getEmbodimentConnectivityInstructions(
          accessToken,
          embodimentId
        );
        const markdownFromDb = res.data;
        setMarkdown(markdownFromDb);
        setInstructionsOpen(true);
      } catch (err) {
        console.error(err);
        setError(
          "Sorry, there was an error fetching the instructions. Please reload if the issue persists."
        );
      }
    }

    accessToken && embodimentId && fetchInstructions();
  }, [accessToken, embodimentId, setInstructionsOpen, setError]);

  return (
    <Dialog
      open={markdown && instructionsOpen}
      onClose={handleClose}
      aria-labelledby="embodiment-title"
      maxWidth="lg"
    >
      <DialogTitle
        id="embodiment-title"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Robot icon & title text */}
        <Box display="flex" gap="5px">
          <FontAwesomeIcon
            icon={faRobot}
            style={{ color: "#616161", marginRight: "10px" }}
          />
          <Typography>{embodimentName} Connection Instructions</Typography>
        </Box>
        {/* Magic link text/button */}
        <Box display="flex" alignItems="center" gap="20px" minHeight="56px">
          <MagicLink
            accessToken={accessToken}
            embodimentId={embodimentId}
            setError={setError}
            link={link}
            setLink={setLink}
          />
          {/* Close button */}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      {/* Instructions from db */}
      <DialogContent style={{ minWidth: "40vw" }}>
        <DisplayMarkdown markdownFromDb={markdown} />
      </DialogContent>
    </Dialog>
  );
};

export default EmbodimentInstructions;
