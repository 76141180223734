import { logger } from "../util/logger";
import { apiRequestExternal } from "./apiRequestExternal";
import { apiValidateArguments } from "./apiValidateArguments";

// Get session game stats
export async function getFitnessStats(sessionId, clusterId) {
  try {
    // Validate args
    apiValidateArguments({ sessionId, clusterId });

    // Return custom response data
    const response = await apiRequestExternal(
      `https://${sessionId}-feagi.${clusterId}.neurorobotics.studio/v1/training/fitness_stats`,
      "GET",
      null,
      null
    );

    logger("Response in getFitnessStats function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getFitnessStats function:", error);
    throw error;
  }
}
