export const sendToIframe = (iframe, message) => {
  if (iframe?.contentWindow) {
    iframe.contentWindow.postMessage(message, "*");
  } else {
    console.error("Iframe not found or does not have a contentWindow property");
  }
};

export const listenToIframe = (callback) => {
  const handler = (event) => {
    callback(event.data);
  };

  window.addEventListener("message", handler);
  return () => window.removeEventListener("message", handler);
};
