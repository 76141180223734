import { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth } from "../../util/auth";
import { getUserByToken, updateUser } from "../../api/userManagement";
import UserBadges from "../UserBadges";
import SettingsAvatarPicker from "./SettingsAvatarPicker";

function SettingsProfile(props) {
  const auth = useAuth();
  const accessToken = auth.user.accessToken;
  const [userObject, setUserObject] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [description, setDescription] = useState("");
  const [avatar, setAvatar] = useState({});
  const [avatarPickerOpen, setAvatarPickerOpen] = useState(false);
  const [pending, setPending] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    getUserByToken(accessToken).then(({ data }) => {
      setUserObject(data);
      setDisplayName(data.display_name || "");
      setDescription(data.description || "");
      setAvatar(data.avatar || "");
    });
  }, [accessToken]);

  // Validate display name as user types
  const handleDisplayNameChange = (event) => {
    const value = event.target.value;
    if (value.length > 20) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        displayName: "Name cannot exceed 20 characters",
      }));
    } else if (!/^[A-Za-z0-9_-]*$/.test(value)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        displayName:
          "Names can only contain letters, numbers, underscores, & dashes",
      }));
    } else if (value.toLowerCase().includes("neuraville")) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        displayName: "Names cannot include 'Neuraville'",
      }));
    } else {
      setFormErrors((prevErrors) => ({ ...prevErrors, displayName: null }));
      setDisplayName(value);
    }
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setPending(true);
    setFormErrors({});

    try {
      // Update user & show success message
      const newDisplayName =
        userObject.display_name === displayName ? null : displayName;
      const newDescription =
        userObject.description === description ? null : description;
      const newAvatar =
        userObject.avatar.img_url === avatar.img_url ? null : avatar.img_url;
      if (
        newDisplayName === null &&
        newDescription === null &&
        newAvatar === null
      ) {
        props.onStatus({
          type: "error",
          message: "No changes detected!",
        });
        return;
      }
      const res = await updateUser(
        accessToken,
        null,
        newDisplayName,
        newDescription,
        newAvatar
      );

      const newUserObj = res.data;
      setUserObject(newUserObj);

      props.onStatus({
        type: "success",
        message: "Your profile has been updated",
      });
    } catch (err) {
      // Handle errors
      if (err.status && err.status === 418) {
        props.onStatus({
          type: "error",
          message:
            "Inappropriate language detected. Please edit and resubmit. (If you feel this is incorrect, please submit an issue report through the message icon in the navbar.)",
        });
      } else if (err.status && err.status === 409) {
        props.onStatus({
          type: "error",
          message: "Display name already in use. Please choose another.",
        });
      } else {
        props.onStatus({
          type: "error",
          message: err.message,
        });
      }
    } finally {
      // Stop loading spinner
      setPending(false);
    }
  };

  if (!userObject) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
      <form onSubmit={handleSubmit} style={{ mb: 4 }}>
        <Grid container={true} spacing={2}>
          {/* Avatar*/}
          <Grid item xs={12}>
            <Grid container alignItems="flex-start" spacing={2}>
              {/* Avatar and Button Wrapper */}
              <Grid item xs={12} container spacing={2} wrap="nowrap">
                <Grid item xs={12} md={4}>
                  <Typography
                    sx={{ mb: 2, fontSize: ".85rem", color: "accents.light" }}
                  >
                    Avatar
                  </Typography>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent="+"
                    sx={{
                      cursor: "pointer",
                      "& .MuiBadge-badge": {
                        bottom: "25%",
                        border: "1px solid #525252",
                        backgroundColor: "#ffffff14",
                        "&:hover": { backgroundColor: "#ffffff24" },
                      },
                    }}
                    onClick={() => setAvatarPickerOpen(true)}
                  >
                    <Avatar
                      sx={{
                        mb: 2,
                        fontSize: "2rem",
                        color: "white",
                        width: 80,
                        height: 80,
                        padding: 1.5,
                        backgroundColor: "background.paper",
                      }}
                      title="Change avatar"
                      alt={avatar.img_alt_text || ""}
                      src={avatar.img_url || ""}
                    >
                      ?
                    </Avatar>
                  </Badge>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-start",
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      href={`/user/${userObject.display_name}`}
                    >
                      Public Profile
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Display name */}
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="text"
              label="Display Name"
              placeholder="Display Name"
              value={displayName}
              onChange={handleDisplayNameChange}
              error={!!formErrors.displayName}
              helperText={formErrors.displayName}
              fullWidth
            />
          </Grid>
          {/* Description */}
          <Grid item={true} xs={12}>
            <TextField
              variant="outlined"
              type="text"
              label="Description"
              placeholder="Description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              error={!!formErrors.description}
              helperText={formErrors.description}
              fullWidth
              multiline
              rows={2}
              // sx={{ textarea: { resize: 'vertical' } }}
            />
          </Grid>
          {/* Save button */}
          <Grid item={true} xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={pending}
              fullWidth={false}
            >
              {!pending && <span>Save</span>}
              {pending && <CircularProgress size={28} />}
            </Button>
          </Grid>
        </Grid>
      </form>
      {/* Badges */}
      {userObject?.earned_badges && (
        <UserBadges badges={userObject.earned_badges} />
      )}
      {/* Avatar picker modal */}
      {avatarPickerOpen && (
        <SettingsAvatarPicker
          accessToken={accessToken}
          isOpen={avatarPickerOpen}
          handleClose={() => setAvatarPickerOpen(false)}
          setAvatar={setAvatar}
        />
      )}
    </Box>
  );
}

export default SettingsProfile;
