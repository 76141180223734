import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ContactForm from "./ContactForm";
import { contact, feedback, reportIssue } from "../api/misc";
import { useAuth } from "../util/auth";
import { logger } from "../util/logger";

const ContactSection = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { type } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  const fullUrl = window.location.origin + currentPath;
  const browserInfo = navigator.userAgent;

  useEffect(() => {
    if (
      !type ||
      !["feedback", "bug-report", "support", "get-in-touch"].includes(type)
    ) {
      navigate("/contact");
    }
  }, [type, navigate]);

  const handleSubmit = async (values) => {
    logger("Form values:", values);
    let accessToken;
    if (auth.user) {
      accessToken = auth.user.accessToken;
    }

    switch (type) {
      case "feedback":
        logger("Submitting feedback");
        await feedback({
          accessToken,
          values,
          browserInfo,
          route: fullUrl || currentPath,
        });
        break;
      case "bug-report":
        logger("Submitting bug report");
        await reportIssue({
          accessToken,
          values,
          browserInfo,
          route: fullUrl || currentPath,
        });
        break;
      case "support":
        logger("Submitting support request");
        await contact(values, browserInfo, fullUrl || currentPath);
        break;
      case "get-in-touch":
        logger("Submitting get in touch request");
        await contact(values, browserInfo, fullUrl || currentPath);
        break;
      default:
        break;
    }
  };

  const renderContactForm = () => {
    switch (type) {
      // Feedback
      case "feedback":
        return (
          <ContactForm
            title="Feedback"
            description="We'd love to hear about your experience."
            fields={[
              {
                name: "rating",
                label: "Rating",
                type: "rating",
                required: false,
              },
              {
                name: "message",
                label: "Feedback",
                type: "textarea",
                required: true,
              },
            ]}
            onSubmit={handleSubmit}
          />
        );
      // Bug report
      case "bug-report":
        return (
          <ContactForm
            title="Bug Report"
            description="Please provide details about the bug you encountered."
            fields={[
              {
                name: "rating",
                label: "Severity",
                type: "rating",
                required: true,
              },
              {
                name: "message",
                label: "Description",
                type: "textarea",
                required: true,
              },
            ]}
            onSubmit={handleSubmit}
          />
        );
      // Support
      case "support":
        return (
          <ContactForm
            title="Support"
            description="Please provide details about your support request."
            fields={[
              { name: "name", label: "Name", type: "text", required: true },
              { name: "email", label: "Email", type: "email", required: true },
              {
                name: "issue",
                label: "Issue Type",
                type: "select",
                options: [
                  { label: "Technical", value: "technical" },
                  { label: "Billing", value: "billing" },
                  { label: "Account Management", value: "account-management" },
                  { label: "Login", value: "login" },
                  { label: "Other", value: "other" },
                ],
                required: true,
              },
              {
                name: "message",
                label: "Description",
                type: "textarea",
                required: true,
              },
            ]}
            onSubmit={handleSubmit}
          />
        );
      // Get in touch
      case "get-in-touch":
        return (
          <ContactForm
            title="Get in Touch"
            description="Whether you're an investor, innovator, open source contributor, or
            just plain curious, we'd love to hear from you!"
            fields={[
              { name: "name", label: "Name", type: "text", required: true },
              { name: "email", label: "Email", type: "email", required: true },
              {
                name: "topic",
                label: "Topic *",
                type: "select",
                options: [
                  { label: "Partnership", value: "partnership" },
                  { label: "Investment", value: "investment" },
                  { label: "STEM & Education", value: "education" },
                  { label: "Research", value: "research" },
                  { label: "Other", value: "other" },
                ],
                required: true,
              },
              {
                name: "message",
                label: "Message",
                type: "textarea",
                required: true,
              },
            ]}
            onSubmit={handleSubmit}
          />
        );
      default:
        return null;
    }
  };

  return renderContactForm();
};

export default ContactSection;
