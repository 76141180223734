// example regions: [[1, 0, 0], [0, 2, 0]] where each array is [x, y, z] and 0, 0 is bottom left

import { Box } from "@mui/material";
import getBorderlines from "./getBorderlines";

const DrawActivations = ({ gridCoords, regions }) => {
  if (!gridCoords?.x || !regions || regions.length < 1) return null;

  return regions.map((regionArr) => {
    const { top, left, right, bottom } = getBorderlines(
      gridCoords,
      regionArr[0],
      regionArr[1]
    );

    return (
      <Box
        sx={{
          position: "absolute",
          zIndex: 100,
          top: `${top}%`,
          left: `${left}%`,
          width: `${right - left}%`,
          height: `${bottom - top}%`,
          backgroundColor: "#ff110042",
        }}
      />
    );
  });
};

export default DrawActivations;
