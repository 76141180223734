import React from "react";
import ReactDOM from "react-dom/client"; // Updated import
import App from "./pages/_app";
import * as serviceWorker from "./serviceWorker";

// Create a root.
const root = ReactDOM.createRoot(document.getElementById("root"));
// Render the app on the root.
root.render(<App />);

// Service worker setup remains the same
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
