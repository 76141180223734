import { Link } from "react-router-dom";
// import { useAuth } from "../../util/auth";
import { Tab, Tabs } from "@mui/material";

function UserProfileNav({ displayName, isGenomes }) {
  // const auth = useAuth();
  // const authProviders = auth.user.providers;
  return (
    <Tabs
      value={isGenomes ? "genomes" : "achievements"}
      indicatorColor="primary"
      textColor="primary"
      centered={true}
    >
      <Tab
        component={Link}
        to={`/user/${displayName}`}
        label="Achievements"
        value="achievements"
      />
      <Tab
        component={Link}
        to={`/user/${displayName}/genomes`}
        label="Genomes"
        value="genomes"
      />
    </Tabs>
  );
}

export default UserProfileNav;
