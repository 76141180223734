import { Box, Button, Typography } from "@mui/material";
import disappointedRobot from "../../img/robot-bewildered-transparent.png";

function OAuthFailureHandler() {
  return (
    <Box
      sx={{
        height: "100%",
        px: 3,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "center",
        alignItems: "center",
        gap: { xs: 2, md: 0 },
      }}
    >
      <img
        src={disappointedRobot}
        alt="bewildered robot"
        style={{ maxWidth: "200px" }}
      />
      <Typography fontWeight="500">
        Sorry, an error occurred during the OAuth process. Please retry.
      </Typography>
      <Button variant="contained" href="/auth/signin" sx={{ mt: 2 }}>
        Sign In
      </Button>
    </Box>
  );
}

export default OAuthFailureHandler;
