import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const UnsupportedModal = ({
  browserInfo,
  confirmationOpen,
  handleConfirmationClose,
  action,
  dontShowUnsupportedModal,
  setDontShowUnsupportedModal,
}) => {
  const [dontShow, setDontShow] = useState(dontShowUnsupportedModal || false);
  const { browser, isMobile, isUsingIntelMac } = browserInfo;
  const isBreakingCombo = isUsingIntelMac && browser !== "Safari";

  const handleConfirm = () => {
    action && action();
    handleConfirmationClose();
  };

  useEffect(() => {
    if (dontShow) {
      localStorage.setItem("dontShowIntelMacWarning", "true");
      setDontShowUnsupportedModal(true);
    } else {
      localStorage.removeItem("dontShowIntelMacWarning");
      setDontShowUnsupportedModal(false);
    }
  }, [dontShow, setDontShowUnsupportedModal]);

  return (
    <Dialog
      open={confirmationOpen}
      onClose={handleConfirmationClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {isMobile
              ? "Unsupported Device"
              : "Unsupported System/Browser Combo"}
          </Typography>
          <IconButton onClick={handleConfirmationClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {isMobile &&
            "The brain visualizer is too mighty to run on mobile devices."}

          {!isMobile && isBreakingCombo && (
            <Box
              sx={{
                width: "100%",
                minHeight: "calc(100% - 64px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
                // backgroundColor: "#1e1e1e",
                color: "#c9c9c9",
              }}
            >
              <Typography>
                On older (Intel) Macs, non-Safari browsers don't support the
                brain visualizer out of the box. Please switch to Safari.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid grey",
                  p: 2,
                }}
              >
                <Typography mb={2}>
                  Or, <strong>if you are using Chrome</strong>, enabling the
                  experimental Metal graphics flag will fix the issue. (If you
                  already have it enabled, you can safely load the brain
                  visualizer.)
                </Typography>
                <Typography
                  variant="h2"
                  sx={{ fontSize: "1.3rem", fontWeight: "bold" }}
                >
                  How to Enable Metal in Chrome
                </Typography>
                <List>
                  <ListItem>
                    <Typography>
                      1. Paste <strong>chrome://flags/#use-angle</strong> into
                      your browser bar
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      2. Turn Choose ANGLE graphics backend to Metal
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>3. Restart Chrome</Typography>
                  </ListItem>
                </List>
              </Box>
              <Typography>
                We have not been able to confirm reliable behavior on Firefox or
                Edge on Intel Macs.{" "}
                <span style={{ color: "red" }}>
                  Using the brain visualizer with these browsers may cause the
                  browser to stop responding.
                </span>
              </Typography>
            </Box>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isMobile && (
          <Button onClick={handleConfirmationClose} variant="contained">
            OK
          </Button>
        )}
        {!isMobile && (
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mx: 2 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => setDontShow((prev) => !prev)}
                  sx={{
                    color: "#c9c9c9",
                  }}
                />
              }
              label="Got it, don't show again"
              sx={{
                color: "#c9c9c9",
              }}
            />
            {action ? (
              <Button variant="contained" onClick={handleConfirm}>
                Load Brain Visualizer
              </Button>
            ) : (
              <Button onClick={handleConfirmationClose} variant="contained">
                OK
              </Button>
            )}
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UnsupportedModal;
