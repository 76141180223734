import isIntelMac from "./getIntel";

export default async function getBrowserInfo() {
  const userAgent = navigator.userAgent;

  let browser = "Unknown Browser";
  let os = "Unknown OS";
  let isMobile = false;
  const isUsingIntelMac = await isIntelMac();

  // Detect browser
  switch (true) {
    case userAgent.indexOf("Chrome") > -1:
      browser = "Chrome";
      break;
    case userAgent.indexOf("Firefox") > -1:
      browser = "Firefox";
      break;
    case userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1:
      browser = "Internet Explorer";
      break;
    case userAgent.indexOf("Safari") > -1:
      browser = "Safari";
      break;
    case userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1:
      browser = "Opera";
      break;
    case userAgent.indexOf("Edge") > -1:
      browser = "Edge";
      break;
    default:
      break;
  }

  // Detect OS
  switch (true) {
    case userAgent.indexOf("Win") > -1:
      os = "Windows OS";
      break;
    case userAgent.indexOf("Mac") > -1:
      os = "MacOS";
      break;
    case userAgent.indexOf("X11") > -1:
      os = "UNIX";
      break;
    case userAgent.indexOf("Linux") > -1:
      os = "Linux";
      break;
    case /Android/.test(userAgent):
      os = "Android";
      break;
    case /iPhone|iPad|iPod/.test(userAgent):
      os = "iOS";
      break;
    default:
      break;
  }

  // Detect if mobile
  if (navigator.userAgentData?.mobile) {
    isMobile = true;
  } else {
    isMobile =
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(
        userAgent.toLowerCase()
      );
  }

  return {
    browser,
    os,
    isMobile,
    isUsingIntelMac,
  };
}
