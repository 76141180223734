// Utility function to filter out null or undefined properties (null and undefined are loosely equal (==) to each other in JavaScript)
export function apiFilterNonNull(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => {
      // Filters out null and undefined
      if (value == null) return false;

      // Filters out empty objects
      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        Object.keys(value).length === 0
      )
        return false;

      // Filters out empty arrays
      if (Array.isArray(value) && value.length === 0) return false;

      // Keeps the value if none of the conditions are met
      return true;
    })
  );
}
