import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Paper, Box, DialogContentText, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { deployGenome } from "../api/feagiSessionManagement";
import CustomDialog from "./CustomDialog";
import { localLogger } from "../util/logger";
import SignupModal from "../util/SignupModal";
// import { logger } from "../util/logger";
// import { updateExperiment } from "../api/experimentManagement";

export default function CarouselComplex({
  accessToken,
  setSnackbar,
  items,
  handleBehaviorsClose,
  handleChange,
  isSessionValid,
}) {
  // Get session ID
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const sessionId =
    queryParameters.get("id") || queryParameters.get("session_id");
  // Other variables
  const [signupModalOpen, setSignupModalOpen] = useState(false);
  const [carouselItems, setCarouselItems] = useState(items);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedGenome, setSelectedGenome] = useState({});
  const [selectedGenomeId, setSelectedGenomeId] = useState(null);
  const gapPercent = 3;
  const itemWidth = 100 / items.length;

  const goToNextSlide = () => {
    // Shift the array to the left
    setCarouselItems((prevItems) => {
      const nextItems = [...prevItems.slice(1), prevItems[0]];
      return nextItems;
    });
  };

  const goToPrevSlide = () => {
    // Shift the array to the right
    setCarouselItems((prevItems) => {
      const lastItem = prevItems[prevItems.length - 1];
      const nextItems = [lastItem, ...prevItems.slice(0, -1)];
      return nextItems;
    });
  };

  const handleSelect = (personality) => {
    if (!accessToken) {
      setSignupModalOpen(true);
      return;
    }
    if (!isSessionValid) {
      return;
    }
    if (!personality) {
      console.error("Missing personality in selection:", personality);
    }
    setSelectedGenome(personality);
    setSelectedGenomeId(personality.genome_id);
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    setSelectedGenomeId(null);
  };

  const handleConfirmationAgree = async () => {
    try {
      if (!sessionId || !accessToken || !selectedGenomeId) {
        localLogger(
          "error",
          `Missing a required argument (sessionId, accessToken, selectedGenomeId): ${sessionId}, ${accessToken}, ${selectedGenomeId}`
        );
        throw new Error(
          "Missing a required argument to deploy genome (validation, session ID, or genome ID)"
        );
      }
      // const res = await updateExperiment(
      //   accessToken,
      //   experiment.experiment_id,
      //   experiment.experiment_title,
      //   experiment.experiment_description,
      //   selectedGenomeId
      // );
      deployGenome(accessToken, sessionId, selectedGenomeId);
      if (handleChange) handleChange(selectedGenome);
      setSnackbar({
        message: "Personality change complete.",
        severity: "success",
      });
      handleConfirmationClose();
      handleBehaviorsClose();
    } catch (err) {
      console.error(err);
      setSnackbar({
        message:
          "Sorry, an error occurred changing the personality. If you continue to experience issues, please reload the page.",
        severity: "error",
      });
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <IconButton onClick={goToPrevSlide} disabled={items.length < 2}>
        <KeyboardArrowLeft sx={{ fontSize: { xs: "1.5rem", md: "3rem" } }} />
      </IconButton>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
      >
        <Box
          sx={{
            width: `${itemWidth * carouselItems.length}%`,
            display: "flex",
            justifyContent: `${carouselItems.length < 3 ? "center" : ""}`,
            gap: `${gapPercent}%`,
            // transform: "translate3d(-75%, 0px, 0px)",
            // transitionDuration: "500ms",
          }}
        >
          {carouselItems.map((item, index) => {
            return (
              <Tooltip
                title={
                  isSessionValid || !accessToken
                    ? ""
                    : "Connect & awaken the brain to switch personalities"
                }
              >
                <Paper
                  key={
                    (item.personality?.personality_id ||
                      item.genome?.genome_id) + index
                  }
                  elevation={4}
                  sx={{
                    minWidth: "125px",
                    width: `${100 / 3 - gapPercent}%`, // Each item should take up an equal percentage of the carousel width, minus any gap
                    height: "11rem",
                    flexShrink: 0,
                    transition: "all 1s",
                    cursor: isSessionValid || !accessToken ? "pointer" : "auto",
                    backgroundImage: "none",
                    opacity: isSessionValid ? 1 : 0.5,
                    "&:hover": {
                      backgroundColor:
                        isSessionValid || !accessToken
                          ? "background.light"
                          : "",
                    },
                  }}
                  onClick={() => handleSelect(item.personality)}
                >
                  {item.content}
                </Paper>
              </Tooltip>
            );
          })}
        </Box>
      </Box>

      <IconButton onClick={goToNextSlide} disabled={items.length < 2}>
        <KeyboardArrowRight sx={{ fontSize: { xs: "1.5rem", md: "3rem" } }} />
      </IconButton>

      {/* Confirmation dialog */}
      <CustomDialog
        header={`Replace your current ${
          handleChange ? "personality" : "genome"
        }?`}
        text=""
        richText={
          <DialogContentText id="alert-dialog-description">
            This will replace your robot's personality with your selection.{" "}
            {!handleChange && "Your experiment genome will change."}
          </DialogContentText>
        }
        isOpen={confirmationOpen}
        handleClose={handleConfirmationClose}
        confirmAction={handleConfirmationAgree}
        cancelAction={handleConfirmationClose}
      />

      {/* Signup modal */}
      {signupModalOpen && (
        <SignupModal open={signupModalOpen} setOpen={setSignupModalOpen} />
      )}
    </Box>
  );
}
