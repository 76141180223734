import { useEffect, useState } from "react";
import NewExperimentGenome from "../new-experiment/NewExperimentGenome";
import { getUserByToken } from "../../api/userManagement";
import { useAuth } from "../../util/auth";
import CustomSnackbar from "../../util/CustomSnackbar";

const SettingsGenomes = () => {
  const auth = useAuth();
  const accessToken = auth.user.accessToken;
  const [displayName, setDisplayName] = useState("");
  const [snackbar, setSnackbar] = useState({ message: "", severity: "" });

  useEffect(() => {
    getUserByToken(accessToken).then(({ data }) => {
      if (data?.display_name) setDisplayName(data.display_name);
    });
  }, [accessToken]);

  const customSetError = (error) => {
    setSnackbar({
      message: error,
      severity: "error",
    });
  };

  return (
    <>
      {displayName && (
        <NewExperimentGenome
          setError={customSetError}
          displayName={displayName}
        />
      )}

      {snackbar.message && (
        <CustomSnackbar
          message={snackbar.message}
          setMessage={setSnackbar}
          severity={snackbar.severity}
        />
      )}
    </>
  );
};

export default SettingsGenomes;
