export default function getExperimentAge(utcString, moment, now) {
  try {
    let end = moment.utc(utcString); // Time of last run
    let duration = moment.duration(now.diff(end));

    let minutes = Math.floor(duration.asMinutes() % 60);
    let hours = Math.floor(duration.asHours() % 24);
    let days = Math.floor(duration.asDays());

    let ageString = "";

    if (minutes < 1 && hours < 1 && days < 1) {
      return "0 mins";
    }
    if (days > 0) {
      ageString += `${days} days`;
      if (hours > 0 || minutes > 0) ageString += `, `;
    }
    if (hours > 0) {
      ageString += `${hours} hours`;
      if (minutes > 0) ageString += `, `;
    }
    if (minutes > 0) {
      ageString += `${minutes} mins`;
    }

    return ageString.trim();
  } catch (err) {
    console.error(err);
  }
}
