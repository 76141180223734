// Note: Resolution is also set by canvas width/height. For example, if a canvas is 500x500 px, and you draw an image onto it, that image's resolution will be 500x500 px. If you scale that image up, it would be pixelated because there are only 500x500 pixels of data available.
import { getStimulationPeriod } from "../../../api/feagiApiBurstEngine";
import lz4 from "lz4js";
import processImageData from "./processImageData";
// import config from "../../../config";
import cameraCapabilities from "./cameraCapabilities";
import dataToJsonAndUint8Array from "../../../util/dataToJsonAndUInt8Array";
import { logger } from "../../../util/logger";

export async function startScreenCapture(
  setScreenCaptureOpen,
  feagiCanvasRef,
  userCanvasRef,
  websocket,
  setWebsocket,
  videoStream,
  setVideoStream,
  videoElement,
  setVideoElement,
  sessionId,
  clusterId,
  setError,
  configFromDb
) {
  const connectionError =
    "There was an error connecting screen capture. Please reload, or report a bug if the issue persists.";

  try {
    if (!sessionId) {
      throw new Error("Missing session ID for screen capture.");
    }

    console.log("Screen cap show on the road");

    const canvas = feagiCanvasRef.current;
    const ctx = canvas.getContext("2d");
    const userCanvas = userCanvasRef.current;
    const userCtx = userCanvas.getContext("2d");

    const stream = await navigator.mediaDevices.getDisplayMedia({
      video: true,
    });
    setVideoStream(stream);
    const videoTrack = stream.getVideoTracks()[0];
    const capabilities = configFromDb || cameraCapabilities;

    // Handle the case user ends screen capture via browser button etc.
    videoTrack.onended = () => {
      console.log("Screen capture ended");
      stopScreenCapture(
        setScreenCaptureOpen,
        websocket,
        setWebsocket,
        videoStream,
        setVideoStream,
        videoElement,
        setVideoElement
      );
      setScreenCaptureOpen(false);
    };
    console.log(
      "opening ws",
      `wss://${sessionId}-feagi.${clusterId}.neurorobotics.studio/p9051`
    );
    const newWebsocket = new WebSocket(
      `wss://${sessionId}-feagi.${clusterId}.neurorobotics.studio/p9051`
    );
    console.log("websocket should be open now");
    setWebsocket(newWebsocket);

    const hertz = await getStimulationPeriod(sessionId, clusterId);

    const videoElement = document.createElement("video");
    setVideoElement(videoElement);
    videoElement.srcObject = new MediaStream([videoTrack]);

    let isWebSocketOpen = false;
    let isVideoMetadataLoaded = false;

    // Function to initiate capture frames
    const initiateCaptureFrames = () => {
      if (isWebSocketOpen && isVideoMetadataLoaded) {
        captureFrames(
          ctx,
          canvas,
          userCanvas,
          userCtx,
          newWebsocket,
          setWebsocket,
          stream,
          setVideoStream,
          videoElement,
          setVideoElement,
          setScreenCaptureOpen,
          hertz
        );
      }
    };

    // Event listeners for websocket
    newWebsocket.onopen = () => {
      console.log("Screen capture websocket connection opened");
      if (capabilities) {
        const uInt8Array = dataToJsonAndUint8Array(capabilities);
        const compressedCapabilities = lz4.compress(uInt8Array);
        logger("sending capabilities:", capabilities);
        newWebsocket.send(compressedCapabilities);
      }
      isWebSocketOpen = true;
      initiateCaptureFrames();
    };

    newWebsocket.onerror = (error) => {
      console.error(
        "Websocket error occurred:",
        error,
        error.name,
        error.message
      );
      // Show error, but not if user canceled connection dialog
      if (error.name !== "NotAllowedError") {
        setError(connectionError);
      }
      stopScreenCapture(
        setScreenCaptureOpen,
        websocket,
        setWebsocket,
        videoStream,
        setVideoStream,
        videoElement,
        setVideoElement
      );
    };

    videoElement.onloadedmetadata = () => {
      console.log("Video metadata loaded");
      videoElement.play();
      isVideoMetadataLoaded = true;
      initiateCaptureFrames();
    };
  } catch (error) {
    console.error("Error starting screen capture:", error);
    setError(connectionError);
    stopScreenCapture(
      setScreenCaptureOpen,
      websocket,
      setWebsocket,
      videoStream,
      setVideoStream,
      videoElement,
      setVideoElement
    );
  }
}

function captureFrames(
  ctx,
  canvas,
  userCanvas,
  userCtx,
  websocket,
  setWebsocket,
  videoStream,
  setVideoStream,
  videoElement,
  setVideoElement,
  setScreenCaptureOpen,
  hertz
) {
  const intervalId = setInterval(() => {
    try {
      // Make sure websocket open before attempting to send data
      if (websocket.readyState !== WebSocket.OPEN) {
        console.error("Websocket is not open, cannot send data");
        clearInterval(intervalId);
        stopScreenCapture(
          setScreenCaptureOpen,
          websocket,
          setWebsocket,
          videoStream,
          setVideoStream,
          videoElement,
          setVideoElement
        );
        return;
      }

      // Ensure canvas values exist
      if (!canvas.width || !canvas.height) {
        throw new Error(
          "Missing canvas width/height. Values:",
          canvas.width,
          canvas.height
        );
      }

      // Draw video frame onto user-visible canvas
      userCtx.drawImage(
        videoElement,
        0,
        0,
        userCanvas.width,
        userCanvas.height
      );

      // Clear old data to avoid incorrect array values (without this, even static windows show as changed)
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Flip FEAGI canvas image horizontally (this affects future drawings like immediately below, not current)
      ctx.translate(canvas.width, 0);
      ctx.scale(-1, 1);

      // Draw video frame onto FEAGI canvas
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      // Store FEAGI canvas data in array
      const clampedArray = ctx.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      ).data;

      // Create object, incl. new array with alpha values removed (size reduction)
      // const feagiData = {
      //   vision: processImageData(clampedArray),
      //   vision_size: [config.canvasWidth, config.canvasHeight],
      //   device: "screen-capture",
      // };

      // Compress JSON data
      // const uInt8Array = dataToJsonAndUint8Array(feagiData);
      // const compressed = lz4.compress(uInt8Array);

      // Add dimensions & remove alpha values
      const processedData = processImageData(
        clampedArray,
        canvas.width,
        canvas.height
      );

      // Compress
      const uInt8Array = new Uint8ClampedArray(processedData);
      const compressed = lz4.compress(uInt8Array);

      // Send via websocket
      websocket.send(compressed);
    } catch (error) {
      console.error("Error in captureFrames:", error);
      clearInterval(intervalId);
    }
  }, hertz || 60); // frame rate in milliseconds
}

export function stopScreenCapture(
  setScreenCaptureOpen,
  websocket,
  setWebsocket,
  videoStream,
  setVideoStream,
  videoElement,
  setVideoElement
) {
  try {
    // Stop all video tracks
    if (videoStream) {
      videoStream.getTracks().forEach((track) => {
        // if (track.readyState == "live") {
        track.stop();
        track.enabled = false;
        // }
      });
      videoStream = null;
      setVideoStream(null);
    }

    // Clear the video element
    if (videoElement) {
      let tracks = videoElement.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoElement.srcObject = null;
      videoElement = null;
      setVideoElement(null);
    }

    // Close the websocket connection
    if (websocket) {
      websocket.close();
      websocket = null;
      setWebsocket(null);
    }

    setScreenCaptureOpen(false);
  } catch (err) {
    console.error(err);
  }
}
