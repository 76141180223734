import { toggleExperimentFavorite } from "../../../api/experimentManagement";

export default async function toggleFave(
  accessToken,
  experimentId,
  setIsFave,
  setError
) {
  try {
    if (!accessToken) throw new Error("Unable to get user data");
    await toggleExperimentFavorite(accessToken, experimentId);
    setIsFave((prev) => !prev);
  } catch (err) {
    console.error(err);
    setError(
      "Sorry, there was an error saving your favorite value. Please retry."
    );
  }
}
