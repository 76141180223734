import { Box, Container, Tab, Tabs } from "@mui/material";
import Section from "../Section";
import LegalTerms from "./LegalTerms";
import LegalPrivacy from "./LegalPrivacy";
import CustomLink from "../../util/CustomLink";

function LegalSection(props) {
  const validSections = {
    "terms-of-service": true,
    "privacy-policy": true,
  };

  const section = validSections[props.section]
    ? props.section
    : "terms-of-service";

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Tabs
        value={section}
        indicatorColor="primary"
        textColor="primary"
        centered={true}
      >
        <Tab
          component={CustomLink}
          href="/legal/terms-of-service"
          label="Terms of Service"
          value="terms-of-service"
        />
        <Tab
          component={CustomLink}
          href="/legal/privacy-policy"
          label="Privacy Policy"
          value="privacy-policy"
        />
      </Tabs>
      <Box mt={5}>
        <Container>
          {section === "terms-of-service" && <LegalTerms />}
          {section === "privacy-policy" && <LegalPrivacy />}
        </Container>
      </Box>
    </Section>
  );
}

export default LegalSection;
