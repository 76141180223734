import { useEffect, useState } from "react";
import { Snackbar, Alert } from "@mui/material";

const SnackbarHandler = ({ content, setMessage }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (content?.message) {
      setOpen(true);
    }
  }, [content]);

  const handleClose = () => {
    setOpen(false);
    setMessage(null);
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={3000}
    >
      <Alert
        onClose={handleClose}
        severity={content?.severity || "info"}
        sx={{ textAlign: "center" }}
      >
        {content?.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarHandler;
