import PropTypes from "prop-types";
import {
  Stack,
  Step,
  Stepper,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  styled,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  PrecisionManufacturing,
  Signpost as SignpostIcon,
} from "@mui/icons-material";
import { FaDna } from "react-icons/fa";

// Lines between stepper
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        // "linear-gradient(95deg, rgb(102, 255, 102) 0%, rgb(55, 153, 153) 50%, rgb(53, 74, 177) 100%)",
        "linear-gradient(136deg, rgb(80 95 106) 0%, rgb(57 81 113) 50%, rgb(41 43 57) 100%)", //silvery
      // "linear-gradient(136deg, rgb(144, 202, 249) 0%, rgb(106, 0, 207) 50%, rgb(75 156 210) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(136deg, rgb(80 95 106) 0%, rgb(57 81 113) 50%, rgb(41 43 57) 100%)", //silvery
      // "linear-gradient(136deg, rgb(144, 202, 249) 0%, rgb(106, 0, 207) 50%, rgb(75 156 210) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "rgb(37 37 37)",
    borderRadius: 1,
  },
}));

// Stepper icons background
const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  zIndex: 1,
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgb(37 37 37)",
  color: "accents.light",
  ...(ownerState.active && {
    backgroundImage:
      // "linear-gradient(136deg, rgb(144, 202, 249) 0%, rgb(106, 0, 207) 50%, rgb(75 156 210) 100%)",
      "linear-gradient(136deg, rgb(144, 202, 249) 0%, rgb(44 46 63) 50%, rgb(24 24 24) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    color: "white",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      // "linear-gradient(136deg, rgb(144, 202, 249) 0%, rgb(106, 0, 207) 50%, rgb(75 156 210) 100%)",
      "linear-gradient(136deg, rgb(144, 202, 249) 0%, rgb(44 46 63) 50%, rgb(24 24 24) 100%)",
    color: "white",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.2)",
    },
  }),
}));

// Icons
function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SignpostIcon />,
    2: <PrecisionManufacturing />,
    3: <FaDna />,
    4: <CheckCircleIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  // Whether step is active
  active: PropTypes.bool,
  className: PropTypes.string,
  // Whether step is completed
  completed: PropTypes.bool,
  // Step icon
  icon: PropTypes.node,
  // Step number
  step: PropTypes.number,
};

export default function NewExperimentStepper({
  activeStep,
  setActiveStep,
  collectedData,
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const steps = ["Environment", "Embodiment", "Genome", "Name & Launch"];
  const shortSteps = ["Environ", "Embod", "Genome", "Launch"];
  console.log("collectedData in stepper", collectedData);

  function handleStepJump(label) {
    const stepNumber = steps.indexOf(label);
    stepNumber <= activeStep && setActiveStep(stepNumber);
  }

  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        sx={{ width: "100%" }}
      >
        {steps.map((label, index) => (
          <Step key={label} onClick={() => handleStepJump(label)}>
            <StepLabel
              StepIconComponent={(props) => (
                <ColorlibStepIcon {...props} step={index} />
              )}
              // Step title styling
              sx={{
                "&& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                  {
                    color: "primary.main",
                    cursor: "pointer",
                    "&:hover": { fontWeight: "700" },
                  },
              }}
              // Step subtitle ("Cutebot," etc.)
              optional={
                index < activeStep ? (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{
                      maxWidth: { xs: "5em", md: "10em" },
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                  >
                    {index === 0 && collectedData.environmentTitle}
                    {index === 1 && collectedData.embodimentTitle}
                    {index === 2 && collectedData.genomeTitle}
                  </Typography>
                ) : null
              }
            >
              {/* Step title ("Environment", etc.) */}
              {isSmallScreen ? shortSteps[index] : label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
