import Meta from "../components/Meta";
import PlaySelect from "../components/play/PlaySelect";
// import { requireAuth } from "../util/auth";

function PlaySelectPage() {
  return (
    <>
      <Meta title="NRS - Play Selection" />
      <PlaySelect />
    </>
  );
}

export default PlaySelectPage;
// export default requireAuth(PlaySelectPage);
