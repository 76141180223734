import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  DialogActions,
  DialogContent,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { Whatshot as WhatshotIcon } from "@mui/icons-material";
import { reportIssue } from "../../api/misc";

const NavbarFeedbackIssue = ({
  accessToken,
  handlePostSubmit,
  handleCancel,
  setError,
}) => {
  const [severity, setSeverity] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const location = useLocation();
  const currentPath = location.pathname;
  const fullUrl = window.location.origin + currentPath;
  const browserInfo = navigator.userAgent;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!feedbackMessage || !severity) {
      setError("Both a message and severity rating are required.");
      return;
    }

    setError("");

    // Send data
    try {
      // if (!accessToken) throw new Error("Unable to retrieve access token.");

      // Send to backend
      await reportIssue({
        accessToken,
        values: { rating: severity, message: feedbackMessage },
        browserInfo,
        route: fullUrl || currentPath,
      });
      // Reset form fields
      setFeedbackMessage("");
      setSeverity("");

      // Close feedback & open thanks
      handlePostSubmit();
    } catch (err) {
      console.error(err);
      setError("Sorry, an error occurred. Please try again later.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Typography component="legend">How severe is the issue? *</Typography>
        <Rating
          name="severity"
          value={severity}
          size="large"
          icon={
            <WhatshotIcon sx={{ color: "rgb(250 95 0)" }} fontSize="inherit" />
          }
          emptyIcon={
            <WhatshotIcon sx={{ color: "accents.main" }} fontSize="inherit" />
          }
          sx={{
            marginTop: "5px",
            marginBottom: "20px",
          }}
          onChange={(event, newValue) => {
            setSeverity(newValue);
          }}
        />
        <TextField
          id="message"
          label="Describe the issue"
          value={feedbackMessage}
          fullWidth
          variant="outlined"
          multiline
          minRows={4}
          inputProps={{ maxLength: 1000 }}
          onChange={(e) => setFeedbackMessage(e.target.value)}
          required
        />
      </DialogContent>
      <DialogActions
        sx={{ padding: "8px 24px 16px 8px", justifyContent: "space-between" }}
      >
        <Button variant="contained" color="error" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </DialogActions>
    </form>
  );
};

export default NavbarFeedbackIssue;
