export default function extractFilename(contentDisposition) {
  if (!contentDisposition) return null;

  // Handle both regular and extended filename formats
  const filenameRegex = /filename\*?=(?:utf-8'')?([^;\n]*)/i;
  const matches = filenameRegex.exec(contentDisposition);

  if (matches && matches[1]) {
    // Decode URI-encoded characters and remove surrounding quotes if present
    return decodeURIComponent(matches[1].replace(/['"]/g, ""));
  } else {
    return null;
  }
}
