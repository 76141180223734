import { Alert, Snackbar } from "@mui/material";

// MUI severity options: success, info, warning, error

const CustomSnackbar = ({
  message,
  setMessage,
  severity,
  top = "7px",
  autoHideTime = null,
}) => {
  const handleClose = () => {
    if (typeof message === "string") {
      setMessage("");
    } else if (typeof message === "object") {
      setMessage({
        message: "",
        severity: "",
      });
    }
  };

  return (
    <Snackbar
      open={message}
      autoHideDuration={autoHideTime}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{
        "&.MuiSnackbar-root": { top: top },
      }}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
