import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
// import { useDarkMode } from "./../util/theme";
import { useAuth } from "../util/auth";
import AddToAny from "../util/AddToAny";
import CustomLink from "../util/CustomLink";
import CustomDialog from "./CustomDialog";
import Section from "./Section";
import SocialLinks from "./SocialLinks";
import handleLeavePlay from "../util/handleLeavePlay";

function Footer(props) {
  const auth = useAuth();
  const location = useLocation();
  const path = location.pathname;
  const params = new URLSearchParams(location.search);
  const clusterParam = params.get("cluster") || params.get("cluster_id");
  const logo = props.logo;
  const [leavePlayPath, setLeavePlayPath] = useState("");
  // const darkMode = useDarkMode();
  // props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  const linkClick = (targetPath) => {
    if (path.startsWith("/play") && clusterParam) {
      setLeavePlayPath(targetPath);
    } else {
      window.location.href = targetPath;
    }
  };

  // Don't render anything for certain page(s)
  if (
    location?.pathname === "/instance" ||
    location?.pathname.startsWith("/brain-visualizer") ||
    location?.pathname === "/brain-loading" ||
    location?.pathname.startsWith("/book")
  ) {
    return null;
  }

  // Colors to indicate environment
  const backgroundColor =
    process.env.REACT_APP_NODE_ENV === "staging"
      ? "rgb(27 73 74)"
      : "background.default";

  return (
    <>
      <Section
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
        sx={{
          zIndex: 10,
          mt: props.sticky ? "auto" : undefined,
          py: "20px",
          px: { xs: 0, md: "10px" },
          backgroundColor: backgroundColor || "background.default",
        }}
      >
        <Container
          sx={{
            "@media (min-width: 1200px)": {
              maxWidth: "none", // This specifically overrides the max-width at 1200px and above
            },
            mt: { xs: 2, lg: 0 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            {/* Logo, Terms, etc. */}
            <Box
              sx={{
                flex: { xs: "none", md: 1 },
                order: { xs: 3, md: 1 },
                display: "flex",
                justifyContent: { xs: "center", md: "flex-start" },
                alignItems: "center",
                gap: 2,
              }}
            >
              <a
                href="https://www.neuraville.com"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "grey" }}
              >
                {logo ? (
                  <img
                    src={logo}
                    alt="Logo"
                    style={{ display: "block", height: 32 }}
                  />
                ) : (
                  <Typography
                    sx={{
                      minWidth: "25px",
                      borderRadius: "100%",
                      border: "1px solid grey",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  >
                    N
                  </Typography>
                )}
              </a>
              <Box
                display="flex"
                sx={{
                  opacity: 0.6,
                  fontSize: "0.875rem",
                  "& a": {
                    color: "inherit",
                    marginLeft: "0.8rem",
                  },
                }}
              >
                <Typography fontSize="0.875rem">{props.copyright}</Typography>
                {auth.user ? (
                  <>
                    <Typography
                      onClick={() => linkClick("/legal/terms-of-service")}
                      sx={{
                        ml: "0.8rem",
                        fontSize: "0.875rem",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Terms
                    </Typography>
                    <Typography
                      onClick={() => linkClick("/legal/privacy-policy")}
                      sx={{
                        ml: "0.8rem",
                        fontSize: "0.875rem",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Privacy
                    </Typography>
                  </>
                ) : (
                  <>
                    <CustomLink href="/legal/terms-of-service">
                      Terms
                    </CustomLink>
                    <CustomLink href="/legal/privacy-policy">
                      Privacy
                    </CustomLink>
                  </>
                )}
              </Box>
            </Box>

            {/* Contact & Hub */}
            <Box
              sx={{
                mb: { xs: 2, md: 0 },
                flex: { xs: "none", md: 1 },
                order: { xs: 1, md: 2 },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
              }}
            >
              {auth.user ? (
                <>
                  <Typography
                    onClick={() => linkClick("/hub")}
                    sx={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      lineHeight: 1,
                    }}
                  >
                    Hub
                  </Typography>
                  <Typography
                    onClick={() => linkClick("/contact")}
                    sx={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      lineHeight: 1,
                    }}
                  >
                    Contact
                  </Typography>
                </>
              ) : (
                <>
                  <CustomLink
                    href="/hub"
                    type="mui"
                    style={{
                      color: "inherit",
                      lineHeight: 1,
                    }}
                  >
                    Hub
                  </CustomLink>
                  <CustomLink
                    href="/contact"
                    type="mui"
                    style={{
                      color: "inherit",
                      lineHeight: 1,
                    }}
                  >
                    Contact
                  </CustomLink>
                </>
              )}
            </Box>

            {/* Social & AddToAny */}
            <Box
              sx={{
                mb: { xs: 2, md: 0 },
                flex: { xs: "none", md: 1 },
                order: { xs: 2, md: 3 },
                display: "flex",
                justifyContent: { xs: "center", md: "flex-end" },
                alignItems: "flex-end",
                gap: 2,
                flexWrap: "wrap",
              }}
            >
              <SocialLinks />
              <AddToAny color="transparent" />
            </Box>
          </Box>
        </Container>
      </Section>

      {leavePlayPath && (
        <CustomDialog
          header="Leave this page and end your play session?"
          // text="(No data will be lost.)"
          isOpen={leavePlayPath}
          handleClose={() => setLeavePlayPath("")}
          confirmAction={() =>
            handleLeavePlay(
              auth.user?.accessToken,
              leavePlayPath,
              setLeavePlayPath
            )
          }
          cancelAction={() => setLeavePlayPath("")}
        />
      )}
    </>
  );
}

export default Footer;
