import { forwardRef } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import { useMediaQuery, useTheme } from "@mui/material";

const NavbarWrapper = forwardRef((props, ref) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const isAuthPage =
    currentPath === "/" ||
    currentPath === "/business" ||
    currentPath.startsWith("/auth");

  const shouldHideNavbar =
    (!isSmOrSmaller && isAuthPage) || currentPath.startsWith("/book");

  return <>{!shouldHideNavbar && <Navbar ref={ref} {...props} />}</>;
});

export default NavbarWrapper;
