import { useEffect, useState } from "react";
import { Alert, Box, OutlinedInput, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "./auth";
import { changeUserEmail, sendUserEmailToken } from "../api/userManagement";

const EmailToken = ({ email, handleSuccess }) => {
  const auth = useAuth();
  const [token, setToken] = useState(["", "", "", "", "", ""]);
  const [pending, setPending] = useState(false);
  const [alertInfo, setAlertInfo] = useState({});

  // Triggered when something is pasted into the input fields
  const handlePaste = (e) => {
    setAlertInfo({});
    let data = e.clipboardData.getData("text");
    if (data.length === 6 && /^\d+$/.test(data)) {
      setToken(data.split(""));
    }
  };

  // Handle input changes
  const handleChange = (index) => (e) => {
    setAlertInfo({});
    const newToken = [...token];
    newToken[index] = e.target.value.slice(0, 1);
    setToken(newToken);
    // Move to next input if the current one is filled
    if (index < 5 && e.target.value) {
      document.getElementById(`token-field-${index + 1}`).focus();
    }
  };

  // Handle backspace
  const handleKeyDown = (index) => (e) => {
    setAlertInfo({});
    // If backspace is pressed and the field is empty, move to the previous field
    if (e.key === "Backspace" && index > 0 && !token[index]) {
      e.preventDefault(); // Prevents the default backspace behavior
      const newToken = [...token];
      newToken[index - 1] = ""; // Clear the previous field
      setToken(newToken);
      document.getElementById(`token-field-${index - 1}`).focus();
    }
  };

  // Handle resend email
  const handleResendEmail = async () => {
    try {
      setAlertInfo({});
      if (!email) {
        setAlertInfo({
          severity: "error",
          content:
            "An error occurred sending your verification code. Please close this dialog and try again.",
        });
        return;
      }
      const accessToken = auth.user.accessToken;
      await sendUserEmailToken(accessToken, email);
      // setAlertInfo({
      //   severity: "success",
      //   content: "Sent! Please check your email.",
      // });
    } catch (err) {
      console.error(err);
      setAlertInfo({
        severity: "error",
        content:
          "An error occurred sending your verification code. If you do not see it in your inbox, please retry.",
      });
      setPending(false);
    }
  };

  // Send email token on initial render
  useEffect(() => {
    handleResendEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle token submission
  const handleSubmitCode = async () => {
    try {
      setAlertInfo({});
      setPending(true);
      const accessToken = auth.user.accessToken;
      await changeUserEmail(accessToken, email, token.join(""));
      handleSuccess();
    } catch (err) {
      console.error(err);
      if (err.status === 400 && err.body?.detail?.code === "bad-token") {
        setToken(["", "", "", "", "", ""]);
        setAlertInfo({
          severity: "error",
          content:
            "Expired or invalid code. Please click the link below to resend. (May take a couple minutes to arrive.)",
        });
        setToken(["", "", "", "", "", ""]);
      } else if (err.status === 503) {
        setAlertInfo({
          severity: "error",
          content:
            "Sorry, our robots have their hands full. 🤖😓 Please try again soon!",
        });
      } else {
        setAlertInfo({
          severity: "error",
          content: "An unexpected error occurred. Please retry.",
        });
      }
    } finally {
      setPending(false);
    }
  };

  return (
    <>
      {alertInfo?.content && (
        <Alert
          severity={alertInfo.severity || "info"}
          sx={{ mt: 2 }}
          onClose={() => setAlertInfo({})}
        >
          {alertInfo.content}
        </Alert>
      )}
      <Box
        onPaste={handlePaste}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "5px",
          my: "20px",
        }}
      >
        {token.map((digit, index) => (
          <OutlinedInput
            key={index}
            id={`token-field-${index}`}
            type="text"
            maxLength="1"
            value={digit}
            onChange={handleChange(index)}
            onKeyDown={handleKeyDown(index)}
            sx={{
              width: "30px",
              height: "30px",
              borderRadius: "5px",
              textAlign: "center",
              "&:focus": {
                borderColor: "primary",
              },
              "& input": {
                textAlign: "center",
                padding: "3px",
              },
            }}
          />
        ))}
      </Box>
      <Box display="flex" justifyContent="center" my="10px">
        <LoadingButton
          variant="contained"
          onClick={handleSubmitCode}
          loading={pending}
        >
          Submit
        </LoadingButton>
      </Box>
      <Box display="flex" justifyContent="space-around">
        <Typography
          onClick={handleResendEmail}
          color="primary"
          sx={{
            my: "10px",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Resend code
        </Typography>
      </Box>
    </>
  );
};

export default EmailToken;
