import lz4 from "lz4js";
import dataToJsonAndUint8Array from "../../util/dataToJsonAndUInt8Array";
import updateCapabilitiesControl from "./utils/updateCapabilitiesControl";
import getGridPoints from "./utils/getGridPoints";
import { logger } from "../../util/logger";

class WebsocketManager {
  constructor({
    setIsWebSocketOpen,
    handleClose,
    setError,
    handleErrorOpen,
    sessionId,
    clusterId,
    setActivationRegions,
    setGridCoords,
    initialCapabilities,
    refreshRate,
    setRefreshRate,
    feagiWidthRef,
    feagiHeightRef,
  }) {
    // Passed args
    this.setIsWebSocketOpen = setIsWebSocketOpen;
    this.handleClose = handleClose;
    this.setError = setError;
    this.handleErrorOpen = handleErrorOpen;
    this.sessionId = sessionId;
    this.clusterId = clusterId;
    this.setActivationRegions = setActivationRegions;
    this.setGridCoords = setGridCoords;
    this.capabilities = initialCapabilities;
    this.refreshRate = refreshRate;
    this.setRefreshRate = setRefreshRate;
    this.feagiWidthRef = feagiWidthRef;
    this.feagiHeightRef = feagiHeightRef;
    // Internal variables
    this.ws = null;
    this.controls = ["modulation_control", "eccentricity_control"];
    this.connectionTimeout = 5000;
    this.retryAttempts = 3;
    this.currentRetry = 0;
    // Internal methods
    this.handleOpen = this.handleOpen.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleError = this.handleError.bind(this);
    // Initialize
    this.init();
  }

  // Initialize connection
  init() {
    if (!this.sessionId || !this.clusterId) {
      console.error("Missing session and/or cluster ID from params for ws url");
      this.handleError(
        new Error("Missing session and/or cluster ID from params for ws url")
      );
      return;
    }

    try {
      this.ws = new WebSocket(
        `wss://${this.sessionId}-feagi.${this.clusterId}.neurorobotics.studio/p9051`
      );
      this.setupEventHandlers();
      this.setupConnectionTimeout();
    } catch (error) {
      this.handleError(
        new Error("Error initializing websocket:", error.message)
      );
    }
  }

  setupConnectionTimeout() {
    this.connectionTimer = setTimeout(() => {
      if (this.ws?.readyState !== WebSocket.OPEN) {
        this.handleError(new Error("Connection timeout"));
      }
    }, this.connectionTimeout);
  }

  // Assign ws events to methods
  setupEventHandlers() {
    if (!this.ws) return;

    this.ws.onopen = (event) => {
      console.log("Websocket open event:", event);
      clearTimeout(this.connectionTimer);
      this.handleOpen(event);
    };
    this.ws.onmessage = this.handleMessage;
    this.ws.onclose = (event) => {
      console.log("Websocket close event:", event);
      clearTimeout(this.connectionTimer);
      this.handleClose(event);
    };
    this.ws.onerror = this.handleError;
  }

  // Open
  handleOpen() {
    this.setIsWebSocketOpen(true);
    if (this.capabilities) {
      this.sendCapabilities(this.capabilities);
    }
  }

  // Send data to websocket
  sendData(data) {
    if (this.ws?.readyState === WebSocket.OPEN) {
      // console.log("sending websocket data");
      this.ws.send(data);
    } else {
      console.error("WS not open. Cannot send data.");
    }
  }

  // Process received message
  handleMessage(event) {
    try {
      // console.log("Rec'd websocket message:", event.data);
      const parsedData = JSON.parse(event.data);

      if (!parsedData) {
        throw new Error("No data in the message. Ignoring:", event.data);
      }

      // Update activation regions
      if (parsedData.activation_regions) {
        this.setActivationRegions(parsedData.activation_regions);
      }

      // Update gridlines
      if (this.controls.some((control) => parsedData[control])) {
        this.updateGridLines(parsedData);
      }

      // Update refresh rate
      if (typeof parsedData.newRefreshRate === "number") {
        const newRate = parsedData.newRefreshRate;
        logger("new refresh rate via WS:", newRate);
        if (newRate !== this.refreshRate) this.setRefreshRate(newRate);
      }

      // Change max resolution
      const dimensions = parsedData.cortical_dimensions_per_device;
      if (dimensions) {
        const width = dimensions[0];
        const height = dimensions[1];
        if (typeof width === "number" && typeof height === "number") {
          this.feagiWidthRef.current = width;
          this.feagiHeightRef.current = height;
        } else {
          console.error(
            "Rec'd non-numeric width/height. Dimensions rec'd:",
            dimensions
          );
        }
      }
    } catch (error) {
      console.error("Error processing message:", error);
    }
  }

  // Error
  handleError(error) {
    console.error("WebSocket error:", error);

    // Attempt retry if under max attempts
    if (this.currentRetry < this.retryAttempts) {
      this.currentRetry++;
      console.log(
        `Retrying ws connection (${this.currentRetry}/${this.retryAttempts})...`
      );
      setTimeout(() => this.init(), 1000 * this.currentRetry);
      return;
    }

    this.handleClose();
    this.setError(
      "There was an error connecting the webcam. Please reload, or report a bug if the issue persists."
    );
    this.handleErrorOpen && this.handleErrorOpen();
  }

  // Send capabilities
  sendCapabilities(capabilities) {
    try {
      logger("sending capabilities", capabilities);
      const uInt8Array = dataToJsonAndUint8Array(capabilities);
      const compressedCapabilities = lz4.compress(uInt8Array);
      this.sendData(compressedCapabilities);
    } catch (error) {
      console.error("Error sending capabilities:", error);
    }
  }

  // Change grid lines
  updateGridLines(data) {
    this.controls.forEach((control) => {
      if (data[control]) {
        this.capabilities = updateCapabilitiesControl(
          this.capabilities,
          control,
          data[control]
        );
      }
    });
    this.setGridCoords(getGridPoints(this.capabilities));
  }

  cleanup() {
    clearTimeout(this.connectionTimer);

    if (this.ws) {
      // Remove all event listeners
      this.ws.onopen = null;
      this.ws.onmessage = null;
      this.ws.onclose = null;
      this.ws.onerror = null;

      // Close connection if still open
      if (this.ws.readyState === WebSocket.OPEN) {
        this.ws.close();
      }

      this.ws = null;
    }

    // Clear all references
    this.setIsWebSocketOpen = null;
    this.handleClose = null;
    this.setError = null;
    this.handleErrorOpen = null;
    this.sessionId = null;
    this.clusterId = null;
    this.setActivationRegions = null;
    this.setGridCoords = null;
    this.capabilities = null;
    this.feagiWidthRef = null;
    this.feagiHeightRef = null;
    this.refreshRate = null;
    this.setRefreshRate = null;
  }
}

export default WebsocketManager;
