import { useEffect, useState } from "react";
import { getAvatarLinks } from "../../api/userManagement";
import CustomDialog from "../CustomDialog";
import CarouselGeneric from "../CarouselGeneric";
import { Box } from "@mui/material";

const SettingsAvatarPicker = ({
  accessToken,
  isOpen,
  handleClose,
  setAvatar,
}) => {
  const [imageLinks, setImageLinks] = useState([]);

  useEffect(() => {
    async function fetchImages() {
      try {
        const { data } = await getAvatarLinks(accessToken);
        console.log(data);
        setImageLinks(data);
      } catch (err) {
        console.error("Error fetching images:", err);
      }
    }

    accessToken && fetchImages();
  }, [accessToken]);

  function handleSelect(obj) {
    setAvatar(obj);
    handleClose();
  }

  return (
    <CustomDialog
      isOpen={isOpen}
      handleClose={handleClose}
      header="Avatar Selection"
      text="Choose the avatar for your public profile. It can be changed anytime."
      richText={
        <Box sx={{ mt: 2 }}>
          <CarouselGeneric
            cards={imageLinks}
            handleSelect={(url) => handleSelect(url)}
          />
        </Box>
      }
    />
  );
};

export default SettingsAvatarPicker;
