import { Box, Button, Typography } from "@mui/material";
import { triggerBehavior } from "../../api/embodimentManagement";
import { useState } from "react";

const PlayBehaviors = ({
  accessToken,
  setSnackbar,
  sessionId,
  embodimentId,
  personalityId,
  behaviors,
  physicalConnected,
  sessionOpen,
}) => {
  const [flippedBehavior, setFlippedBehavior] = useState(null);

  const handleFlip = (behaviorId) => {
    setFlippedBehavior(flippedBehavior === behaviorId ? null : behaviorId);
  };

  const handleClick = async (behavior) => {
    try {
      const behaviorId = behavior.behavior_id;

      if (behavior.click_activated) {
        await triggerBehavior(
          accessToken,
          sessionId,
          embodimentId,
          personalityId,
          behaviorId
        );
      } else {
        handleFlip(behaviorId);
      }
    } catch (err) {
      console.error("Error in PlayBehaviors handleClick:", err);
      setSnackbar({
        message:
          "An error occurred triggering the behavior. Please send a bug report via the navbar message icon if this continues to occur.",
        severity: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        mt: 2,
        p: "10px 20px",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRadius: "5px",
        backgroundColor: "background.dark",
      }}
    >
      <Typography
        sx={{ textAlign: "center", fontSize: "1.2rem", fontWeight: "500" }}
      >
        Behaviors
      </Typography>
      {/* <Typography>Select a behavior to watch it in action!</Typography> */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: 2,
          title:
            !physicalConnected || !sessionOpen
              ? "Your robot must be connected and the brain activated to use behaviors."
              : "",
        }}
      >
        {behaviors.map((behavior) => {
          if (!behavior.active && behavior.state !== "active") return null;
          const isDisabled = !physicalConnected || !sessionOpen;

          return (
            <Button
              key={behavior.title}
              // onMouseEnter={() => handleClick(behavior)}
              // onMouseLeave={() => handleClick(behavior)}
              onClick={() => !isDisabled && handleClick(behavior)}
              sx={{
                position: "relative",
                width: { xs: "100px", md: "125px" },
                height: { xs: "140px", md: "165px" },
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
                border: 1,
                borderRadius: "5px",
                color: "white",
                borderColor: "background.light",
                backgroundColor: "background.default",
                cursor: "pointer",
                pointerEvents: isDisabled ? "none" : "auto",
                perspective: "1000px",
                transformStyle: "preserve-3d",
                transition: "transform 0.6s",
                transform:
                  flippedBehavior === behavior.behavior_id
                    ? "rotateY(180deg)"
                    : "rotateY(0deg)",
                "&:hover": {
                  backgroundColor: "background.dark",
                },
                "&:before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: isDisabled
                    ? "rgb(37 37 37 / 50%)"
                    : "transparent",
                  zIndex: isDisabled ? 1 : -1,
                },
                disabled: isDisabled,
              }}
            >
              {/* Front */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  p: 1,
                  width: "100%",
                  height: "100%",
                  backfaceVisibility: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: ".65rem", md: ".8rem" },
                    fontWeight: "600",
                    wordBreak: "break-word",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                  }}
                >
                  {behavior.title}
                </Typography>
                {behavior.image_url && (
                  <img
                    src={behavior.image_url}
                    alt={behavior.image_alt_text}
                    crossOrigin="anonymous"
                    style={{ maxWidth: "100%", objectFit: "contain" }}
                  />
                )}
              </Box>
              {/* Back */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  height: "100%",
                  backfaceVisibility: "hidden",
                  transform: "rotateY(180deg)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "background.dark",
                  p: 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: ".65rem", md: ".8rem" },
                    fontWeight: "600",
                    textTransform: "uppercase",
                    mb: 1,
                  }}
                >
                  Details
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: ".5rem", md: ".65rem" },
                    textAlign: "center",
                    color: "accents.light",
                  }}
                >
                  {behavior.description}
                </Typography>
              </Box>
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};

export default PlayBehaviors;
