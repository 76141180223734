import { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Box,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useAuth } from "../../util/auth";
import { getUserByToken } from "../../api/userManagement";
import { logger } from "../../util/logger";
import ReauthModal from "../../util/ReauthModal";
import EditButton from "../../util/EditButton";

function SettingsAccount({ onStatus }) {
  const auth = useAuth();
  const accessToken = auth.user?.accessToken;
  const isSmOrLarger = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [pending, setPending] = useState(true);
  // const authProviders = auth.user.providers;
  // const [displayName, setDisplayName] = useState("");
  // const [regions, setRegions] = useState(null);
  // const [regionSupported, setRegionSupported] = useState(true);
  // const [pending, setPending] = useState(false);
  const [userObject, setUserObject] = useState(null);
  const [email, setEmail] = useState("");
  const [originalEmail, setOriginalEmail] = useState("");
  const [reauthOpen, setReauthOpen] = useState(false);
  // const [region, setRegion] = useState("");

  useEffect(() => {
    async function callGetUserByToken() {
      try {
        if (accessToken) {
          const res = await getUserByToken(accessToken);
          if (!res?.data) {
            throw new Error("Did not receive user data from server.");
          }
          logger("User data:", res.data);
          setUserObject(res.data);
          setOriginalEmail(res.data.email);
          // setDisplayName(data.display_name);
          // setRegion(data.region);
        }
      } catch (err) {
        console.error("Error fetching user data:", err);
        onStatus({
          type: "error",
          message:
            "An error occurred fetching your account information. Please refresh, or try again later.",
        });
      } finally {
        setPending(false);
      }
    }

    callGetUserByToken();
    // const fetchRegions = async () => {
    //   try {
    //     const res = await getRegions(accessToken);
    //     setRegions(res.data);
    //   } catch (err) {
    //     console.error(err);
    //   }
    // };

    // const fetchLink = async () => {
    //   try {
    //     const res = await getEmbodimentRegistrationLink(accessToken);
    //     setLink(res.data);
    //   } catch (err) {
    //     console.error("Error fetching link:", err);
    //     setLink("Error fetching link. See console");
    //   }
    // };

    // if (accessToken) {
    //   // fetchRegions();
    //   fetchLink();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  // Send email to reset password
  const sendPasswordEmail = async () => {
    try {
      await auth.sendPasswordResetEmail(auth.user.email);
      onStatus({
        type: "success",
        message:
          "A reset password email has been sent. Please check your inbox.",
      });
    } catch (err) {
      console.error(err);
      onStatus({
        type: "error",
        message:
          "An error occurred sending the email. If you don't see it in your inbox, please try again.",
      });
    }
  };

  // After reauth completes, update user email
  const handleReauthSuccess = async () => {
    try {
      // await auth.updateEmail(email);
      // await updateUser(accessToken, email, null, region);
      onStatus({
        type: "success",
        message: "Your email has been changed.",
      });
      auth.user?.getIdToken(true);
      // await auth.sendEmailVerification(email);
      // await auth. get new token
    } catch (err) {
      console.error(err);
      let message = "An error occurred updating your email. Please retry.";
      if (err.code === "auth/email-already-in-use") {
        message = "An account is already using this email.";
      }
      onStatus({
        type: "error",
        message: message,
      });
    } finally {
      setReauthOpen(false);
    }
  };

  // Validate display name as user types
  // const handleDisplayNameChange = (event) => {
  //   const value = event.target.value;
  //   if (value.length > 25) {
  //     setFormErrors((prevErrors) => ({
  //       ...prevErrors,
  //       displayName: "Name cannot exceed 25 characters",
  //     }));
  //   } else if (!/^[A-Za-z0-9_-]*$/.test(value)) {
  //     setFormErrors((prevErrors) => ({
  //       ...prevErrors,
  //       displayName:
  //         "Names can only contain letters, numbers, underscores, & dashes",
  //     }));
  //   } else {
  //     setFormErrors((prevErrors) => ({ ...prevErrors, displayName: null }));
  //     setDisplayName(value);
  //   }
  // };

  // Validate region on select
  // const handleRegionChange = (event) => {
  //   const selectedRegion = event.target.value;
  //   setRegion(selectedRegion);
  //   const regionData = regions[selectedRegion];
  //   if (regionData && !regionData.supported) {
  //     setRegionSupported(false);
  //     onStatus({
  //       type: "error",
  //       message: `Region ${regionData.title} is not supported.`,
  //     });
  //   } else {
  //     setRegionSupported(true);
  //     onStatus(null);
  //   }
  // };

  // const validateForm = () => {
  //   let isValid = true;
  //   setPasswordError("");
  //   setConfirmPasswordError("");

  //   if (!password) {
  //     setPasswordError("Please enter a password");
  //     isValid = false;
  //   }

  //   if (confirmPassword !== password) {
  //     setConfirmPasswordError("Passwords do not match");
  //     isValid = false;
  //   }

  //   return isValid;
  // };

  // Handle form submission
  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   setPending(true);
  //   setFormErrors({});

  //   // Handle password change
  //   // if (password || confirmPassword) {
  //   //   if (!validateForm()) {
  //   //     console.error("Invalid or non-matching passwords.");
  //   //     return;
  //   //   }
  //   //   auth
  //   //     .updatePassword(password)
  //   //     .then(() => {
  //   //       setPassword("");
  //   //       setConfirmPassword("");
  //   //       onStatus({
  //   //         type: "success",
  //   //         message: "Your password has been updated",
  //   //       });
  //   //     })
  //   //     .catch((error) => {
  //   //       if (error.code === "auth/requires-recent-login") {
  //   //         onStatus({
  //   //           type: "requires-recent-login",
  //   //           callback: () => handleSubmit({ pass: password }),
  //   //         });
  //   //       } else {
  //   //         onStatus({
  //   //           type: "error",
  //   //           message: error.message,
  //   //         });
  //   //       }
  //   //     })
  //   //     .finally(() => {
  //   //       setPending(false);
  //   //     });
  //   // }

  //   // Validate region
  //   // if (!regionSupported) {
  //   //   onStatus({
  //   //     type: "error",
  //   //     message: `Sorry, the selected region is not currently supported.`,
  //   //   });
  //   //   setPending(false);
  //   //   return;
  //   // }
  //   // Check if the username is unique
  //   // isUsernameUnique(data.name).then((isUnique) => {
  //   //   if (!isUnique) {
  //   //     // Handle the case when username is not unique
  //   //     setError("name", {
  //   //       type: "manual",
  //   //       message: "Username already exists.",
  //   //     });
  //   //     return;
  //   //   }

  //   try {
  //     // Update user
  //     await updateUser(accessToken, email, null, region);

  //     // Set success status
  //     onStatus({
  //       type: "success",
  //       message: "Your account has been updated",
  //     });
  //   } catch (error) {
  //     // Set error status
  //     onStatus({
  //       type: "error",
  //       message: error.message,
  //     });
  //   } finally {
  //     setPending(false);
  //   }
  // };

  if (pending) {
    return <CircularProgress />;
  }

  if (!pending && !userObject) {
    return (
      <Box
        sx={{
          width: "100%",
          minHeight: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>Error fetching user data. Please refresh.</Typography>
      </Box>
    );
  }

  return (
    <>
      <form>
        <Grid
          container={true}
          spacing={2}
          sx={{ maxWidth: "100vw", px: { xs: 2, md: 0 } }}
        >
          <Grid item={true} xs={12} sx={{ width: "100%", mb: 2 }}>
            <Typography fontSize=".8rem">
              <span style={{ color: "rgba(255, 255, 255, 0.7)" }}>
                Account Number:
              </span>{" "}
              {userObject?.account_number}
            </Typography>
          </Grid>

          {/* Pricing */}
          {/* <Grid item={true} xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            marginBottom="5px"
            padding="10px 16px"
            sx={{ backgroundColor: "wildin.main", borderRadius: "5px" }}
          >
            <Typography
              sx={{
                height: "100%",
                padding: "6px 0",
                borderRadius: "5px",
                fontWeight: "500",
              }}
            >
              Plan Tier:{" "}
              {auth.user?.roles?.includes("ultimate-user")
                ? "Ultimate 💎"
                : auth.user?.roles?.includes("pro-user")
                ? "Pro ✨"
                : "Free"}
            </Typography>
            <Box>
              {!auth.user?.roles?.includes("starter") && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => router.push("/settings/billing")}
                >
                  Manage
                </Button>
              )}

              <Button
                variant={
                  auth.user?.roles?.includes("ultimate-user")
                    ? "outlined"
                    : "contained"
                }
                size="small"
                onClick={() => router.replace("/pricing")}
                sx={{ marginLeft: "5px" }}
              >
                {auth.user?.roles?.includes("ultimate-user")
                  ? "Pricing"
                  : "Upgrade"}
              </Button>
            </Box>
          </Box>
        </Grid> */}

          {/* Region */}
          {/* <Grid item={true} xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="region-label">Region</InputLabel>
            <Select
              labelId="region-label"
              label="Region"
              name="region"
              value={region}
              onChange={handleRegionChange}
            >
              <MenuItem value="">---</MenuItem>
              {regions &&
                Object.entries(regions).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid> */}

          {/* {authProviders.includes("password") && ( */}
          {userObject?.last_login_method === "email" && (
            <>
              {/* Change email */}
              <Box
                sx={{
                  width: "100%",
                  pl: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography fontSize=".8rem" color="rgba(255, 255, 255, 0.7)">
                  Email
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                      fontSize: { xs: "0.9rem", sm: "1rem" },
                    }}
                  >
                    {originalEmail}
                  </Typography>
                  <Tooltip title="Change email address">
                    <span>
                      {isSmOrLarger ? (
                        <Button
                          variant="outlined"
                          onClick={() => setReauthOpen(true)}
                        >
                          Change Email
                        </Button>
                      ) : (
                        <EditButton clickHandler={() => setReauthOpen(true)} />
                      )}
                    </span>
                  </Tooltip>
                </Box>
              </Box>
              {/* Change password */}
              <Box
                sx={{
                  width: "100%",
                  mt: 2,
                  pl: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography fontSize=".8rem" sx={{ color: "accents.light" }}>
                  Password
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
                    ********
                  </Typography>
                  <Tooltip title="Send an email to reset your password">
                    <span>
                      {isSmOrLarger ? (
                        <Button variant="outlined" onClick={sendPasswordEmail}>
                          Change Password
                        </Button>
                      ) : (
                        <EditButton clickHandler={sendPasswordEmail} />
                      )}
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            </>
          )}
          {/* <Grid item={true} xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={pending}
              // disabled={pending || !regionSupported}
              fullWidth={true}
            >
              {!pending && <span>Save</span>}
              {pending && <CircularProgress size={28} />}
            </Button>
          </Grid> */}
        </Grid>
      </form>

      {/* Reauth modal */}
      {reauthOpen && (
        <ReauthModal
          reauthOpen={reauthOpen}
          setReauthOpen={setReauthOpen}
          originalEmail={originalEmail}
          email={email}
          setEmail={setEmail}
          handleSuccess={handleReauthSuccess}
        />
      )}
    </>
  );
}

export default SettingsAccount;
