import React from "react";
import { Box } from "@mui/material";

const PlayHtmlAppMaker = ({
  player,
  feagiIndex,
  outputFeagiIndex,
  outputMotorFeagiIndex,
  port,
  embodimentId,
  sessionId,
  clusterId,
  isSmOrSmaller,
  isAbove2XL,
  dimensions,
}) => {
  // pm = use postmessage to receive commands (no output, no ws) [no AI]
  // pm_ws_px = receive postmessage commands and send ws pixel data [AI and player 1]
  // ws_px_controls = receive ws commands and send ws pixel data (no postmessage) [AI and player 2]
  const mode = !(sessionId && clusterId)
    ? "pm"
    : player === 1
    ? "pm_ws_px"
    : "ws_px_controls";

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1,
      }}
    >
      <iframe
        title={embodimentId}
        sandbox="allow-scripts allow-popups allow-same-origin allow-forms allow-top-navigation-by-user-activation allow-top-navigation allow-modals allow-popups-to-escape-sandbox"
        allow="cross-origin-isolation"
        src={`${
          // @ts-ignore
          process.env.PUBLIC_URL
        }/static/${embodimentId}/index.html?feagi_url=${sessionId}-feagi.${clusterId}.neurorobotics.studio&port_disabled=true&ws_url=wss://${sessionId}-feagi.${clusterId}.neurorobotics.studio/p${port}&http_type=HTTPS://&muted=${
          player === 2
        }&capabilities=input%2Ecamera%2E0%2Efeagi_index%3D${feagiIndex}%7Coutput%2Emotion_control%2E0%2Efeagi_index%3D${outputFeagiIndex}%7Coutput%2Emotor%2E0%2Efeagi_index%3D${outputMotorFeagiIndex}&player=${player}&mode=${mode}`}
        style={{
          width: "100%",
          maxWidth: isSmOrSmaller
            ? "300px"
            : `min(${isAbove2XL ? "900px" : "600px"}, calc(300px * ${
                dimensions?.aspect_ratio
              }))`, // in calc, maxWidth becomes what it should be with a maxHeight of 300px and aspect ratio maintained (applying maxHeight directly to iframe breaks aspect ratio)
          minWidth: dimensions.min_width || undefined,
          minHeight: dimensions.min_height || undefined,
          aspectRatio: dimensions?.aspect_ratio,
          backgroundColor: "white",
          pointerEvents: player === 2 ? "none" : "auto",
        }}
      />
    </Box>
  );
};

export default PlayHtmlAppMaker;
