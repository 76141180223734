import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Alert, Box, Button, Divider } from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  // VolumeOff as VolumeOffIcon,
  // VolumeUp as VolumeUpIcon,
} from "@mui/icons-material";
import { useAuth } from "../../util/auth";
import {
  getEmbodimentById,
  getEmbodimentDefaultPlayObject,
} from "../../api/embodimentManagement";
import { getFeagiSessionInfo } from "../../api/feagiSessionManagement";
import handleLeavePlay from "../../util/handleLeavePlay";
import CustomSnackbar from "../../util/CustomSnackbar";
import DisplayMarkdown from "../../util/DisplayMarkdown";
import CustomDialog from "../CustomDialog";
import PageLoader from "../PageLoader";
import PlayConnected from "./PlayConnected";
// import PlayPhysical from "./PlayPhysical";
import useParamsUpdater from "../../util/useParamsUpdater";
import Bluetooth from "../embodiments-code/Bluetooth";

const Play = () => {
  const auth = useAuth();
  const accessToken = auth?.user?.accessToken;
  const [tokenChecked, setTokenChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    message: "",
    severity: "", // "success", "info", "warning", or "error"
  });
  const [isSessionValid, setIsSessionValid] = useState(null);
  const [leavePlayPath, setLeavePlayPath] = useState("");
  // Data from URL
  const { id: embodimentId } = useParams();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const [sessionId, setSessionId] = useState(
    queryParameters.get("session_id") || queryParameters.get("id")
  );
  const [clusterId, setClusterId] = useState(
    queryParameters.get("cluster_id") || queryParameters.get("cluster")
  );
  const updateQueryParams = useParamsUpdater();
  // Embodiments
  const [config, setConfig] = useState({});
  const [embodiment, setEmbodiment] = useState({});
  const [embodimentType, setEmbodimentType] = useState(""); // "user-ws-device", "nrs-html-app", "nrs-bt-device"
  const [physicalConnected, setPhysicalConnected] = useState(
    embodimentType && embodimentType === "nrs-html-app" ? null : false
  );
  // const [hasConnectedOnce, setHasConnectedOnce] = useState(false);
  const [isBluetoothOpen, setIsBluetoothOpen] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [instructionsVideo, setInstructionsVideo] = useState("");
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  // (embodimentType === "user-ws-device" ||
  //   embodimentType === "nrs-bt-device") &&
  //   !hasConnectedOnce
  //   ? false
  //   : true

  useEffect(() => {
    setTimeout(() => {
      setTokenChecked(true);
    }, "1000");
  }, []);
  // const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {}, []);

  // Show connect page only on initial load, not on disconnect
  // useEffect(() => {
  //   if (physicalConnected === true) {
  //     setHasConnectedOnce(true);
  //   }
  // }, [physicalConnected]);

  // Fetch embodiment for current session
  useEffect(() => {
    async function checkSession() {
      try {
        if (sessionId && clusterId) {
          const sessionRes = await getFeagiSessionInfo(accessToken, sessionId);
          if (sessionRes.data?.state === "running") {
            setIsSessionValid(true);
          } else {
            setIsSessionValid(false);
          }
        }
      } catch (err) {
        console.error(err);
        setIsSessionValid(false);
      }
    }

    async function callFetchEmbodiment() {
      try {
        // Embodiment info
        const embodimentRes = await getEmbodimentById(
          accessToken,
          embodimentId
        );
        const data = embodimentRes.data;
        setEmbodiment(data);
        if (!data) {
          throw new Error("Embodiment data is missing.");
        }
        setEmbodimentType(data.agent_type);
        setInstructions(data.play_notes);
        setInstructionsVideo(data.play_obj?.play_video_url);
        const dataConfig = data.play_obj?.configuration;
        if (
          !dataConfig ||
          (data.agent_type === "nrs-html-app" && !dataConfig.player_1)
        ) {
          const defaultObjRes = await getEmbodimentDefaultPlayObject(
            accessToken
          );
          if (
            !defaultObjRes?.data?.configuration ||
            (defaultObjRes?.data?.agent_type === "nrs-html-app" &&
              !defaultObjRes.player_1)
          ) {
            throw new Error(
              "Either embodiment configuration is missing, or agent type is 'nrs-html-app' without player_1."
            );
          } else {
            setConfig(defaultObjRes.data.configuration);
            return;
          }
        }
        setConfig(dataConfig);
      } catch (err) {
        // Errors
        console.error(err);
        console.log("err message:", err.message);
        setSnackbar({
          message:
            "An error occurred fetching the required info. Play elements may be missing or non-functional. Please reload, or restart from Plays if issues persist.",
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    }

    if (tokenChecked) {
      callFetchEmbodiment();
      checkSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenChecked, embodimentId, sessionId]);

  useEffect(() => {
    const newParams = {};
    if (sessionId) newParams.session_id = sessionId;
    if (clusterId) newParams.cluster_id = clusterId;

    if (sessionId || clusterId) {
      updateQueryParams(newParams, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, clusterId]);

  // const toggleMute = () => {
  //   setIsMuted(!isMuted);
  // };

  if (loading) return <PageLoader />;

  // @ts-ignore
  if (!embodiment?.embodiment_title && snackbar?.severity === "error") {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          pt: 3,
          px: { xs: 3, md: 6, lg: 24, xl: 48 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Alert severity="error">{snackbar.message}</Alert>
        <Button variant="outlined" startIcon={<ArrowBackIcon />} href="/play">
          Play Select
        </Button>
      </Box>
    );
  }

  return (
    <>
      {/* {(embodimentType === "user-ws-device" ||
        embodimentType === "nrs-bt-device") &&
      !hasConnectedOnce ? (
        <PlayPhysical
          accessToken={accessToken}
          setSnackbar={setSnackbar}
          sessionId={sessionId}
          clusterId={clusterId}
          embodiment={embodiment}
          physicalConnected={physicalConnected}
          setPhysicalConnected={setPhysicalConnected}
          setIsBluetoothOpen={setIsBluetoothOpen}
        />
      ) : ( */}
      <PlayConnected
        accessToken={accessToken}
        setSnackbar={setSnackbar}
        queryParameters={queryParameters}
        sessionId={isSessionValid && auth.user ? sessionId : null}
        setSessionId={setSessionId}
        clusterId={isSessionValid && auth.user ? clusterId : null}
        setClusterId={setClusterId}
        embodiment={embodiment}
        embodimentId={embodimentId}
        embodimentType={embodimentType}
        config={config}
        hasInstructions={instructions || instructionsVideo}
        setInstructionsOpen={setInstructionsOpen}
        setLeavePlayPath={setLeavePlayPath}
        physicalConnected={physicalConnected}
        setPhysicalConnected={setPhysicalConnected}
        setIsBluetoothOpen={setIsBluetoothOpen}
      />
      {/* )} */}

      {/* Bluetooth device */}
      {/* {isBluetoothOpen && ( */}
      {embodimentType === "nrs-bt-device" && (
        <Bluetooth
          isMicrobitOpen={isBluetoothOpen}
          setIsMicrobitOpen={setIsBluetoothOpen}
          isMicrobitConnected={physicalConnected}
          setIsMicrobitConnected={setPhysicalConnected}
          embodiment={embodiment}
          sessionId={sessionId}
          clusterId={clusterId}
          // embodimentBluetoothProperties={embodiment?.bluetooth_properties}
          // embodimentId={embodimentId}
        />
      )}
      {/* )} */}

      {/* Play instructions dialog */}
      {instructionsOpen && (instructions || instructionsVideo) && (
        <CustomDialog
          header={`How to Play`}
          text=""
          buttonText="Got it!"
          isOpen={instructionsOpen}
          handleClose={() => setInstructionsOpen(false)}
          confirmAction={() => setInstructionsOpen(false)}
          richText={
            <Box>
              {instructionsVideo && (
                <video
                  muted
                  playsInline
                  autoPlay
                  loop
                  controls={false}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "70vh",
                    objectFit: "contain",
                    borderRadius: "5px",
                  }}
                >
                  <source src={instructionsVideo} type="video/mp4" />
                </video>
              )}

              {instructions && (
                <>
                  <Divider />
                  <DisplayMarkdown markdownFromDb={instructions} />
                </>
              )}
            </Box>
          }
          cancelAction={undefined}
          actionsChildren={undefined}
        />
      )}

      {/* Message */}
      <CustomSnackbar
        message={snackbar.message}
        setMessage={setSnackbar}
        severity={snackbar.severity}
        // @ts-ignore
        autoHideTime={snackbar.severity === "error" ? null : 4000}
      />

      {/* Confirm leave page */}
      {leavePlayPath && (
        <CustomDialog
          header="Leave this page and end your play session?"
          // text="(No data will be lost.)"
          isOpen={leavePlayPath}
          handleClose={() => setLeavePlayPath("")}
          confirmAction={() =>
            handleLeavePlay(
              auth.user?.accessToken,
              leavePlayPath,
              setLeavePlayPath
            )
          }
          cancelAction={() => setLeavePlayPath("")}
        />
      )}
    </>
  );
};

export default Play;
