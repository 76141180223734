import BrainVisualizer from "../components/BrainVisualizer";
import { requireAuth } from "./../util/auth";

const BrainVisualizerPage = ({ webcamOpen, setWebcamOpen, navbarHeight }) => {
  return (
    <BrainVisualizer
      webcamOpen={webcamOpen}
      setWebcamOpen={setWebcamOpen}
      navbarHeight={navbarHeight}
    />
  );
};

export default requireAuth(BrainVisualizerPage);
