import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import NewExperimentEmbodimentCard from "../../new-experiment/NewExperimentEmbodimentCard";
import { getEmbodimentById } from "../../../api/embodimentManagement";
import getBrowserInfo from "../../../util/getBrowserInfo";

const NavbarEmbodimentInfo = ({
  accessToken,
  experiment,
  setError,
  embodimentInfoOpen,
  setEmbodimentInfoOpen,
}) => {
  const [embodiment, setEmbodiment] = useState(false);
  const [browserInfo, setBrowserInfo] = useState({});

  // Close embodiment info card
  const handleClose = () => {
    setEmbodimentInfoOpen(false);
  };

  useEffect(() => {
    const handleEmbodimentInfoOpen = async () => {
      try {
        setError("");
        if (!experiment) throw new Error("Experiment data unavailable.");
        if (!accessToken) throw new Error("Unable to get user data");
        const res = await getEmbodimentById(
          accessToken,
          experiment.embodiment_id
        );
        if (!res.data) {
          throw new Error("Embodiment data not returned by database.");
        }
        setEmbodiment(res.data);
      } catch (err) {
        console.error(err);
        handleClose();
        setError(
          "Sorry, we were unable to retrieve the embodiment data. Please try again soon."
        );
      }
    };

    accessToken && experiment && handleEmbodimentInfoOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, experiment, setError]);

  useEffect(() => {
    async function fetchBrowserInfo() {
      const info = await getBrowserInfo();
      setBrowserInfo(info);
    }
    fetchBrowserInfo();
  }, []);

  return (
    <Dialog
      open={embodiment && embodimentInfoOpen}
      onClose={handleClose}
      aria-labelledby="embodiment-title"
      maxWidth="lg"
    >
      <DialogTitle
        id="embodiment-title"
        sx={{ display: "flex", gap: "10px", alignItems: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            color: "accents.main",
          }}
        >
          <FontAwesomeIcon icon={faRobot} />
        </Box>
        {"Embodiment Info"}
        <IconButton
          style={{ position: "absolute", right: 7, top: 7, padding: 3 }}
          onClick={handleClose}
          size="small"
        >
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ width: { xs: "100%", lg: "40vw" }, maxWidth: "100%" }}
      >
        {embodimentInfoOpen && (
          <NewExperimentEmbodimentCard
            row={embodiment}
            browserInfo={browserInfo}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default NavbarEmbodimentInfo;
