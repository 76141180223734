import { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import CustomDialog from "../CustomDialog";
import { useAuth } from "../../util/auth";
import {
  changeVersion,
  getAllVersions,
  stopExperiment,
} from "../../api/experimentManagement";

const NavbarVersionsChange = ({
  isOpen,
  setIsOpen,
  setError,
  setMessage,
  experimentId,
}) => {
  const auth = useAuth();
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  // Fetch versions
  useEffect(() => {
    async function fetchVersions() {
      try {
        const accessToken = auth.user?.accessToken;
        if (!accessToken) {
          throw new Error("Unable to validate user.");
        }
        const res = await getAllVersions(accessToken, experimentId);
        if (!res.data || !Array.isArray(res.data) || res.data.length === 0) {
          throw new Error(
            "Version data from db is empty or not an array:",
            res.data
          );
        }
        setVersions(res.data);
      } catch (err) {
        console.error(err);
        setError("Unable to fetch versions. Please reload if issues persist.");
        setIsOpen(false);
      }
    }
    experimentId && fetchVersions();
  }, [auth, experimentId, setError, setIsOpen]);

  // Handle confirm selection of a version
  async function handleSelect() {
    try {
      await changeVersion(
        auth.user?.accessToken,
        experimentId,
        selectedVersion
      );
      setMessage(
        "Version successfully changed. Redirecting to brain loading page..."
      );
      setIsOpen(false);
      const accessToken = auth.user?.accessToken;
      if (!accessToken) {
        throw new Error("Unable to validate user.");
      }
      await stopExperiment(accessToken, experimentId);
      window.location.href = `/brain-loading?id=${experimentId}`;
    } catch (err) {
      console.error(err);
      setError(
        "Error changing version or restarting experiment. Please reload if issues persist."
      );
      setIsOpen(false);
    }
  }

  return (
    <>
      <CustomDialog
        header="Change Version"
        text="Versions are listed from latest to oldest. Changing your version can resolve or introduce issues. Once you confirm a version change, your experiment will be stopped and restarted."
        maxWidth="md"
        isOpen={isOpen && versions.length > 0}
        handleClose={() => setIsOpen(false)}
        richText={
          <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 2 }}>
            {versions.map((version) => (
              <Card
                key={version.version_id}
                sx={{
                  position: "relative",
                  overflow: "visible",
                  width: "100%",
                  "&:hover .hoverButton": {
                    opacity: 1,
                  },
                  border: 1,
                  borderColor: version.current ? "primary.main" : "transparent",
                }}
              >
                {/* "Current" topper */}
                {version.current && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "-10px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      px: 1,
                      py: 0.5,
                      border: 1,
                      borderColor: "primary.main",
                      borderRadius: "20px",
                      bgcolor: "background.default",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.7rem",
                        fontWeight: "600",
                        textTransform: "uppercase",
                        color: "primary.main",
                      }}
                    >
                      Current
                    </Typography>
                  </Box>
                )}
                {/* Content */}
                <CardContent
                  sx={{
                    width: "100%",
                    pt: "16px",
                    pb: "16px !important",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: ".9rem",
                    }}
                  >
                    {/* {"_id": "66a3ccf59b6ce6a2e8c47954", 
                    "created_at": "2024-07-26T16:21:09.808000", 
                    "current": true, 
                    "version_id": "feagi-core-v0.0.0-dev.11_feagi-connector-v1.3.0-nrs.1-dev.0"} */}
                    <Typography>
                      <Typography variant="span" sx={{ fontWeight: "bold" }}>
                        FEAGI Core Version:
                      </Typography>{" "}
                      {version.feagi_core_version}
                    </Typography>
                    <Typography>
                      <Typography variant="span" sx={{ fontWeight: "bold" }}>
                        FEAGI Connector Version:
                      </Typography>{" "}
                      {version.feagi_connector_version}
                    </Typography>
                    <Typography>
                      <Typography variant="span" sx={{ fontWeight: "bold" }}>
                        Release Date:
                      </Typography>{" "}
                      {version.release_date}
                    </Typography>
                  </Box>
                  {!version.current && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        setSelectedVersion(version.version_id);
                        setConfirmOpen(true);
                      }}
                      className="hoverButton"
                      sx={{
                        opacity: { xs: 1, md: 0 },
                        transition: { md: "opacity 0.1s ease" },
                        fontWeight: "600",
                      }}
                    >
                      Choose
                    </Button>
                  )}
                </CardContent>
              </Card>
            ))}
          </Box>
        }
      />
      {/* Confirmation dialog */}
      <CustomDialog
        header="Change your FEAGI version?"
        text="Are you sure you want to change versions? Your experiment will be stopped and restarted with your selected version."
        isOpen={confirmOpen}
        handleClose={() => setConfirmOpen(false)}
        confirmAction={() => {
          setConfirmOpen(false);
          handleSelect();
        }}
      />
    </>
  );
};

export default NavbarVersionsChange;
