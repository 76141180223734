import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import NewExperimentGenome from "../../new-experiment/NewExperimentGenome";

const GenomeAugment = ({
  currentAugmentOpen,
  handleCurrentAugmentClose,
  error,
  setError,
  setAwaitAugment,
  isCalling,
  setIsCalling,
}) => {
  return (
    <Dialog
      open={currentAugmentOpen}
      onClose={handleCurrentAugmentClose}
      aria-labelledby="genome-augment-title"
      maxWidth="lg"
    >
      <DialogTitle id="genome-augment-title">
        {"Import Genome"}
        <IconButton
          style={{ position: "absolute", right: 7, top: 7, padding: 3 }}
          onClick={handleCurrentAugmentClose}
          size="small"
        >
          <CancelIcon />
        </IconButton>
        <Typography sx={{ mt: "5px" }}>
          Select a genome to import. It will{" "}
          <span style={{ fontWeight: "600" }}>not</span> replace your current
          genome, but augment it.
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          minWidth: { xs: "95vw", md: "960px" },
        }}
      >
        <NewExperimentGenome
          error={error}
          setError={setError}
          handleCurrentAugmentClose={handleCurrentAugmentClose}
          setAwaitAugment={setAwaitAugment}
          setIsCalling={setIsCalling}
        />
      </DialogContent>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isCalling}
      >
        <CircularProgress />
      </Backdrop>
    </Dialog>
  );
};

export default GenomeAugment;
