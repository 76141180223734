import React from "react";
import { Box } from "@mui/material";
import BrainLoading from "../BrainLoading";

const PlayFeagiLoading = ({
  embodimentId,
  setSessionId,
  setClusterId,
  setSessionOpen,
  setSessionLoading,
  isAbove2XL,
  isSmOrSmaller,
}) => {
  return (
    <Box
      sx={{
        p: "8px 40px",
        width: "100%",
        height: "100%",
        maxWidth: isAbove2XL ? "900px" : isSmOrSmaller ? "300px" : "600px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1,
        border: "1px solid",
        borderColor: "accents.main",
      }}
    >
      <BrainLoading
        parentEmbodimentId={embodimentId}
        parentSetSessionId={setSessionId}
        parentSetClusterId={setClusterId}
        setSessionOpen={setSessionOpen}
        setSessionLoading={setSessionLoading}
      />
    </Box>
  );
};

export default PlayFeagiLoading;
