import { useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import { getUser } from "../api/userManagement";
import { hideGenome, shareGenome } from "../api/genomeManagement";
import CustomDialog from "./CustomDialog";
import CustomSnackbar from "../util/CustomSnackbar";

const ShareGenome = ({
  accessToken,
  shareOpen,
  setShareOpen,
  genome,
  updateGenome,
}) => {
  const [snackbar, setSnackbar] = useState({ message: "", severity: "" });
  const [displayName, setDisplayName] = useState("");
  const [foundUser, setFoundUser] = useState({});
  const [userToRemove, setUserToRemove] = useState({});
  const [confirmAddOpen, setConfirmAddOpen] = useState(false);
  const [confirmRemoveOpen, setConfirmRemoveOpen] = useState(false);

  const confirmAdd = async () => {
    try {
      const res = await shareGenome(
        accessToken,
        foundUser?.display_name,
        genome.genome_id
      );
      if (!res.data?.length) {
        throw new Error(
          "Database may not have updated correctly. No collaborators returned."
        );
      }
      const newGenome = {
        ...genome,
        shared_with: res.data,
      };
      updateGenome(newGenome);
      //   setCollaborators(res.data);
      setConfirmAddOpen(false);
    } catch (err) {
      console.error("Error sharing with user:", err);
      setSnackbar({
        message:
          "An error occurred sharing. Please retry if you don't see the user in your shared list on reload.",
        severity: "error",
      });
    }
  };

  const confirmRemove = async () => {
    try {
      const res = await hideGenome(
        accessToken,
        userToRemove.display_name,
        genome.genome_id
      );
      const newGenome = {
        ...genome,
        shared_with: res.data,
      };
      updateGenome(newGenome);
      setConfirmRemoveOpen(false);
    } catch (err) {
      console.error("Error removing user:", err);
      setSnackbar({
        message:
          "An error occurred unsharing. Please retry if the user remains in your shared list on reload.",
        severity: "error",
      });
    }
  };

  const handleClickRemove = (user) => {
    setUserToRemove(user);
    setConfirmRemoveOpen(true);
  };

  const searchForUser = async () => {
    try {
      const res = await getUser(accessToken, displayName);
      if (res.data?.display_name) {
        setFoundUser(res.data);
        setConfirmAddOpen(true);
      }
    } catch (err) {
      console.error("Error searching for user:", err);
      setSnackbar({
        message: "A server error occurred performing the user search.",
        severity: "error",
      });
    }
  };

  return (
    <>
      <CustomDialog
        header="Share Genome"
        isOpen={shareOpen}
        handleClose={() => setShareOpen(false)}
        richText={
          <Box sx={{ minWidth: { xs: "90vw", sm: "500px" }, mt: 1 }}>
            <TextField
              label="User display name"
              value={displayName}
              type="search"
              variant="outlined"
              fullWidth
              onChange={(e) => setDisplayName(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && searchForUser()}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={searchForUser} disabled={!displayName}>
                    <Search />
                  </IconButton>
                ),
              }}
            />
            <Typography variant="h6" sx={{ my: 2 }}>
              Collaborators
            </Typography>
            <List>
              {genome.shared_with?.length ? (
                genome.shared_with.map((collaborator, index) => {
                  if (!collaborator.display_name) return null;
                  return (
                    <ListItem
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                        "&:hover": {
                          backgroundColor: "background.dark",
                        },
                      }}
                    >
                      <ListItemAvatar sx={{ minWidth: 0 }}>
                        <Avatar
                          src={collaborator.avatar_obj?.img_url}
                          alt={collaborator.avatar_obj?.img_alt_text}
                        />
                      </ListItemAvatar>
                      <ListItemText primary={collaborator.display_name} />
                      <IconButton
                        onClick={() => handleClickRemove(collaborator)}
                      >
                        <Close />
                      </IconButton>
                    </ListItem>
                  );
                })
              ) : (
                <Typography
                  sx={{
                    fontSize: ".85rem",
                    fontStyle: "italic",
                    color: "accents.light",
                  }}
                >
                  No current collaborators.
                </Typography>
              )}
            </List>

            {/* Confirm adding user */}
            {confirmAddOpen && (
              <CustomDialog
                header={`Share your genome with this user?`}
                isOpen={confirmAddOpen}
                confirmAction={confirmAdd}
                cancelAction={() => setConfirmAddOpen(false)}
                handleClose={() => setConfirmAddOpen(false)}
                richText={
                  <Box
                    sx={{
                      p: 0.5,
                      display: "flex",
                      alignItems: "center",
                      gap: { xs: 1, md: 2 },
                      borderRadius: "5px",
                      "&:hover": {
                        backgroundColor: "background.dark",
                      },
                    }}
                  >
                    <Avatar
                      src={foundUser?.avatar_obj?.img_url}
                      alt={foundUser?.avatar_obj?.img_alt_text}
                    />
                    <Typography>{foundUser?.display_name}</Typography>
                  </Box>
                }
              />
            )}

            {/* Confirm removing user */}
            {confirmRemoveOpen && (
              <CustomDialog
                header={`Remove this collaborator?`}
                isOpen={confirmRemoveOpen}
                confirmAction={confirmRemove}
                cancelAction={() => setConfirmRemoveOpen(false)}
                handleClose={() => setConfirmRemoveOpen(false)}
                richText={
                  <Box
                    sx={{
                      p: 0.5,
                      display: "flex",
                      alignItems: "center",
                      gap: { xs: 1, md: 2 },
                      borderRadius: "5px",
                      "&:hover": {
                        backgroundColor: "background.dark",
                      },
                    }}
                  >
                    <Avatar
                      src={userToRemove?.avatar_obj?.img_url}
                      alt={userToRemove?.avatar_obj?.img_alt_text}
                    />
                    <Typography>{userToRemove.display_name}</Typography>
                  </Box>
                }
              />
            )}
          </Box>
        }
      />
      <CustomSnackbar
        message={snackbar.message}
        setMessage={setSnackbar}
        severity={snackbar.severity}
      />
    </>
  );
};

export default ShareGenome;
