import { logger } from "../util/logger";
import { apiRequestExternal } from "./apiRequestExternal";

// get versions of all running things
export async function getAgents(sessionId, clusterId) {
  try {
    // Validate args
    if (!sessionId) throw new Error("Missing sessionId argument.");
    if (!clusterId) throw new Error("Missing clusterId argument.");

    // Return custom response data
    const response = await apiRequestExternal(
      `https://${sessionId}-feagi.${clusterId}.neurorobotics.studio/v1/agent/list`,
      "GET",
      null,
      null
    );

    logger("Response in getAgents function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getAgents function:", error);
    throw error;
  }
}
