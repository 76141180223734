import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import CustomDateRangePicker from "../../util/CustomDateRangePicker";
import {
  Check as CheckIcon,
  Close as CloseIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@mui/icons-material";

const Filters = ({
  filtersAnchorEl,
  filtersOpen,
  handleFiltersClose,
  filterList,
  handleSubcategoryClick,
  handleDateRangeFilter,
  selectedFilters,
  selectedSubcategory,
  toggleFilter,
}) => {
  return (
    <>
      {/* IMPORTANT: Docs: "A flexbox bug prevents text-overflow: ellipsis from working in a flexbox layout. Workaround: add noWrap to Typography*/}
      <Menu
        id="filter-menu"
        anchorEl={filtersAnchorEl}
        open={filtersOpen}
        onClose={handleFiltersClose}
        MenuListProps={{
          "aria-labelledby": "filter-button",
          padding: "0",
        }}
        PaperProps={{
          sx: (theme) => ({
            display: "flex",
            maxHeight: "500px", // Adjust height to prevent jumping
            overflow: "hidden", // Prevents the entire menu from scrolling
            padding: "0",
            border: `1px solid ${theme.palette.accents.main}`,
            "& ul": {
              padding: "0 !important",
            },
          }),
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.accents.main}`,
            paddingLeft: "10px",
          })}
        >
          <Typography fontWeight="600">Filters</Typography>
          <IconButton onClick={handleFiltersClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display="flex"
          height="400px"
          width="400px"
          sx={{ overflowY: "hidden" }}
        >
          {/* Main categories filter (left side) */}
          <Box
            sx={(theme) => ({
              width: "50%",
              overflowY: "auto",
              maxHeight: "100%",
              borderRight: `1px solid ${theme.palette.accents.main}`,
            })}
          >
            {Object.entries(filterList).map(([category, subcategories]) => (
              <Fragment key={category}>
                {/* Main category (highest level; not selectable, just header) */}
                <Typography
                  variant="subtitle1"
                  sx={(theme) => ({
                    fontWeight: "600",
                    padding: "6px 0 6px 10px",
                    borderBottom: `1px solid ${theme.palette.accents.main}`,
                  })}
                >
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </Typography>
                {/* Main category subcategory (indented) */}
                {Object.keys(subcategories).map((subcategory) => (
                  <MenuItem
                    key={subcategory}
                    onClick={() =>
                      handleSubcategoryClick(category, subcategory)
                    }
                    sx={(theme) => ({
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "8px 16px 8px 30px",
                      borderBottom: `1px solid ${theme.palette.accents.main}`,
                      backgroundColor:
                        selectedSubcategory.category === category &&
                        selectedSubcategory.subcategoryKey === subcategory
                          ? theme.palette.primary.superDark
                          : undefined,
                      "&:hover": {
                        backgroundColor: theme.palette.primary.superDark,
                      },
                    })}
                  >
                    <Typography>
                      {subcategory.charAt(0).toUpperCase() +
                        subcategory.slice(1)}
                    </Typography>
                    {selectedSubcategory.category === category &&
                      selectedSubcategory.subcategoryKey === subcategory && (
                        <Box sx={{ marginLeft: "auto", display: "flex" }}>
                          <KeyboardArrowRightIcon />
                        </Box>
                      )}
                  </MenuItem>
                ))}
              </Fragment>
            ))}
          </Box>
          {/* Subcategories filter (right side) */}
          <Box
            sx={{
              width: "50%",
              overflowY: "auto",
              maxHeight: "100%",
              "&::-webkit-scrollbar": {
                boxShadow: "#0e1320 0px 0px 6px inset",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgb(59, 67, 86)",
                outline: "rgb(47 62 91) solid 1px",
                "&:hover": {
                  backgroundColor: "rgb(76 86 110)",
                },
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "rgb(42, 45, 52)",
                boxShadow: "rgb(59, 67, 86) 0px 0px 6px inset",
              },
            }}
          >
            {/* note: "type" and "values" are object keys from backend */}
            {selectedSubcategory.subcategory &&
              selectedSubcategory.subcategory.type === "list" && (
                <List sx={{ padding: "0" }}>
                  {selectedSubcategory.subcategory.values.map((value) => (
                    <ListItem
                      key={value}
                      sx={(theme) => ({
                        cursor: "pointer",
                        padding: "4px 0",
                        paddingLeft: "5px",
                        borderBottom: `1px solid ${theme.palette.accents.main}`,
                        backgroundColor: selectedFilters[
                          selectedSubcategory.category
                        ]?.[selectedSubcategory.subcategoryKey]?.includes(value)
                          ? theme.palette.primary.superDark
                          : undefined,
                        "&:hover": {
                          backgroundColor: theme.palette.primary.superDark,
                        },
                      })}
                      onClick={() =>
                        toggleFilter(
                          selectedSubcategory.category,
                          selectedSubcategory.subcategoryKey,
                          value
                        )
                      }
                    >
                      <CheckIcon
                        sx={{
                          visibility: selectedFilters[
                            selectedSubcategory.category
                          ]?.[selectedSubcategory.subcategoryKey]?.includes(
                            value
                          )
                            ? "visible"
                            : "hidden",
                          marginRight: "3px",
                        }}
                      />
                      <ListItemText
                        primary={value}
                        sx={{ overflowWrap: "break-word", pr: "10px" }}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            {selectedSubcategory.subcategory &&
              selectedSubcategory.subcategory.type === "date_range" && (
                <CustomDateRangePicker
                  initialDates={
                    selectedFilters[selectedSubcategory.category]?.[
                      selectedSubcategory.subcategoryKey
                    ]
                  }
                  onDateRangeChange={(newDateRange) => {
                    handleDateRangeFilter(
                      selectedSubcategory.category,
                      selectedSubcategory.subcategoryKey,
                      newDateRange
                    );
                  }}
                />
              )}
            {/* Handle other types like int_range etc. */}
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default Filters;
