import { initializeApp, getApps, getApp } from "firebase/app";
import { logger } from "./logger";

let app;

if (getApps().length === 0) {
  logger("initializing Firebase app");
  // Initialize Firebase app
  app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    // appId: process.env.REACT_APP_FIREBASE_APP_ID,
  });
} else {
  logger("using existing Firebase app");
  logger("app:", app);
  // Use existing app if already initialized
  app = getApp();
}

export const firebaseApp = app;
