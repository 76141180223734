import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import CustomDialog from "../../CustomDialog";
import DisplayMarkdown from "../../../util/DisplayMarkdown";
import { getEmbodimentIntegrationInstructions } from "../../../api/embodimentManagement";

const NavbarEmbodimentGuidance = ({
  accessToken,
  id,
  title,
  open,
  setOpen,
}) => {
  const [markdown, setMarkdown] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Fetch guide markdown from backend
  useEffect(() => {
    async function fetchGuide() {
      setIsLoading(true);
      try {
        const res = await getEmbodimentIntegrationInstructions(accessToken, id);
        setMarkdown(res.data);
      } catch (error) {
        console.error("Error fetching guide:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchGuide();
  }, [accessToken, id]);

  const richTextContent = isLoading ? (
    <CircularProgress />
  ) : (
    <DisplayMarkdown markdownFromDb={markdown} />
  );

  return (
    <CustomDialog
      header={`${title && title} Integration Notes`}
      maxWidth="lg"
      richText={richTextContent}
      isOpen={open}
      handleClose={() => setOpen(false)}
    />
  );
};

export default NavbarEmbodimentGuidance;
