// Start an experiment
export default async function callStartExperiment(
  accessToken,
  experimentId,
  setLoading,
  setError,
  setMessage
) {
  setMessage("");
  setError("");

  // if (browserInfo.browser !== "Safari" && browserInfo.os === "MacOS") {
  //   setError(
  //     `Sorry, the brain visualizer will not run properly in ${browserInfo.browser} on a Mac OS. Please use Safari.`
  //   );
  //   return;
  // }
  // console.log("deets: ", accessToken, experimentId);

  try {
    setLoading(true);
    // const res = await launchExperiment(accessToken, experimentId);
    // console.log("start", res);
    // const sessionId = res.session_id;
    // if (!sessionId)
    //   throw new Error("Error retrieving experiment URL from server");
    // localStorage.setItem("sessionId", sessionId);
    // setButtonText("Stop");
    window.location.href = `/brain-loading?id=${experimentId}`;
    // router.push(`/brain-loading?id=${experimentId}`);
    // let loadedGenome = false;
    // let timeoutId;
    // const intervalId = setInterval(async () => {
    //   try {
    //     const status = await getSessionFeagiStatus(accessToken, sessionId);
    //     console.log("status", status);
    //     if (status.brain_readiness) {
    //       console.log("brain ready");
    //       clearInterval(intervalId); // stop checking once burst_engine is true
    //       clearTimeout(timeoutId); // clear timeout as well, as it's not needed anymore
    //       localStorage.removeItem("collectedData");
    //       localStorage.removeItem("title");
    //       window.location.href = `/brain-visualizer/${sessionId}`;
    //       // router.push(`/brain-visualizer/${sessionId}`);
    //     }
    //     if (status.burst_engine && !loadedGenome) {
    //       console.log("calling deployGenome");
    //       await deployGenome(sessionId);
    //       loadedGenome = true;
    //     }
    //   } catch (error) {
    //     console.error(error);
    //     clearInterval(intervalId); // stop checking in case of an error
    //     clearTimeout(timeoutId); // clear timeout as well, as it's not needed anymore
    //     setError("Error launching experiment. Please try again.");
    //     setLoading(false);
    //   }
    // }, 1000); // checks every 1000 milliseconds (1 second)
    // // timeout after x minutes
    // timeoutId = setTimeout(() => {
    //   console.log("Checking timed out after 5 minutes");
    //   clearInterval(intervalId);
    //   setLoading(false);
    // }, 5 * 60 * 1000); // x minutes in milliseconds
    // don't let start experiment if another started, make button grey: give them option to stop other experiment from here
  } catch (error) {
    // add error handling
    console.error(error);
    setError("Error starting experiment. Please try again.");
    setLoading(false);
  }
  // try {
  //   const res = await launchExperiment(accessToken, experimentId);
  //   console.log("start", res);
  //   const sessionId = res.session_id;
  //   if (!sessionId)
  //     throw new Error("Error retrieving experiment URL from server");
  //   // localStorage.setItem("sessionId", sessionId);
  //   // window.location.href = `/brain-visualizer/${sessionId}`;
  //   setButtonText("Stop");
  //   router.push(`/brain-visualizer/${sessionId}`);
  // } catch (error) {
  //   console.log(error);
  //   setLoading(false);
  //   setError("Error launching experiment. " + error.message);
  // }
}
