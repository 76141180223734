const createFile = async (data, fileName) => {
  const blob = await data.blob(); // Get Blob instead of JSON
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");
  link.href = blobUrl;
  link.setAttribute("download", fileName); // Name for the file

  // Append to HTML, auto-click it (this will download the file) and then remove it
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Revoke the Blob URL to free up memory
  URL.revokeObjectURL(blobUrl);
};

export default createFile;
