import React from "react";
import { Box, Typography } from "@mui/material";
import CustomDialog from "../CustomDialog";
import PlayIcons from "./PlayIcons";
import GetOne from "../GetOne";
import { useNavigate } from "react-router-dom";
import isValidUrl from "../../util/isValidUrl";

const PlayEmbodimentModal = ({
  accessToken,
  embodiment,
  handleClose,
  shouldNavigate,
}) => {
  const navigate = useNavigate();

  function handleConfirm() {
    // if (
    //   selectedEmbodiment.agent_type === "nrs-bt-device" ||
    //   selectedEmbodiment.agent_type === "user-ws-device"
    // ) {
    //   navigate(`/brain-loading?embodiment=${selectedEmbodiment.embodiment_id}`);
    // } else {
    navigate(`/play/${embodiment?.embodiment_id}`);
    // }
    // if (accessToken) {
    //   navigate(`/brain-loading?embodiment=${selectedEmbodiment.embodiment_id}`);
    // } else {
    // navigate(`/play/${selectedEmbodiment.embodiment_id}`);
    // }
  }

  return (
    <CustomDialog
      isOpen={embodiment}
      handleClose={handleClose}
      confirmAction={shouldNavigate ? handleConfirm : null}
      cancelAction={shouldNavigate ? handleClose : null}
      richText={
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderRadius: "5px",
            backgroundColor: "background.paper",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography sx={{ fontSize: "1.2rem", fontWeight: 600 }}>
                {embodiment.embodiment_title}
              </Typography>
              <Typography sx={{ fontSize: ".8rem", fontWeight: 500 }}>
                by {embodiment.manufacturer}
              </Typography>
              {embodiment.play_obj?.requirements?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}
                >
                  <Typography>Requirements:</Typography>{" "}
                  <PlayIcons items={embodiment.play_obj.requirements} />
                </Box>
              )}
              {embodiment.play_obj?.platforms?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}
                >
                  <Typography>Supported Platforms:</Typography>{" "}
                  <PlayIcons items={embodiment.play_obj.platforms} />
                </Box>
              )}
              {embodiment.environment_type === "physical" && (
                <Box sx={{ display: "flex" }}>
                  {embodiment.product_url &&
                    isValidUrl(embodiment.product_url) && (
                      <GetOne
                        accessToken={accessToken}
                        embodiment={embodiment}
                        size="small"
                      />
                    )}
                </Box>
              )}
            </Box>
            <img
              src={embodiment.image_url}
              alt={embodiment.embodiment_title}
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
                objectFit: "contain",
              }}
              crossorigin={shouldNavigate ? "" : "anonymous"}
            />
          </Box>
          <Typography sx={{ mt: 2, fontWeight: 500 }}>
            {embodiment.description}
          </Typography>
        </Box>
      }
    />
  );
};

export default PlayEmbodimentModal;
