// gridPoints are two x and two y coordinates. Lines drawn from these create a 9-square grid.
// row, col tell in which of the 9 regions to place a box, where [0,0] is bottom left
// The below function draws lines to form one visual square in the grid

export default function getBorderlines(gridPoints, row, col) {
  if (!gridPoints || row == null || col == null) {
    console.error(
      "Missing grid points, row, or col in getBorderlines:",
      gridPoints,
      row,
      col
    );
    return {};
  }

  // Make sure coords have order of [min, max]
  const xMinMax = gridPoints.x.sort((a, b) => a - b);
  const yMinMax = gridPoints.y.sort((a, b) => a - b);

  // Create an array of 4 options for x and y
  const xOptions = [0, ...xMinMax, 100];
  const yOptions = [0, ...yMinMax, 100];

  // Assign values for drawing lines
  const left = xOptions[row];
  const right = xOptions[row + 1];
  const top = yOptions[yOptions.length - 1 - 1 - col];
  const bottom = yOptions[yOptions.length - 1 - col];

  return { top, left, right, bottom };
}
