import { logger } from "../util/logger";
import { apiRequestExternal } from "./apiRequestExternal";

// get stimulation period ("Returns the time it takes for each burst to execute in seconds.")
export async function getStimulationPeriod(sessionId, clusterId) {
  try {
    // Validate args
    if (!sessionId) throw new Error("Missing session ID.");
    if (!clusterId) throw new Error("Missing clusterId argument.");

    // Return custom response data
    const response = await apiRequestExternal(
      `https://${sessionId}-feagi.${clusterId}.neurorobotics.studio/v1/burst_engine/stimulation_period`,
      "GET",
      null,
      null
    );
    logger("Response in getStimulationPeriod function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getStimulationPeriod function:", error);
    throw error;
  }
}
