import { Box } from "@mui/material";

const DrawGrid = ({ gridCoords }) => {
  return (
    <>
      {gridCoords.x.map((xOffset, index) => (
        <Box
          key={`x-${index}`}
          sx={{
            position: "absolute",
            top: 0,
            left: `${xOffset}%`,
            width: "2px",
            height: "100%",
            backgroundColor: "rgb(16 18 20 / 48%)",
            pointerEvents: "none",
          }}
        />
      ))}
      {/* Horizontal lines for Y offsets */}
      {gridCoords.y.map((yOffset, index) => (
        <Box
          key={`y-${index}`}
          sx={{
            position: "absolute",
            top: `${yOffset}%`,
            left: 0,
            width: "100%",
            height: "2px",
            backgroundColor: "rgb(16 18 20 / 48%)",
            pointerEvents: "none",
          }}
        />
      ))}
    </>
  );
};

export default DrawGrid;
