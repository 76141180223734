import { Button } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import { externalProductClick } from "../api/embodimentManagement";

const GetOne = ({ accessToken, embodiment, size = "medium" }) => {
  if (!embodiment) return null;

  async function handleGetOneClick() {
    try {
      window.open(embodiment.product_url, "_blank");
      await externalProductClick(accessToken, embodiment.embodiment_id);
    } catch (err) {
      console.error("Error informing server of product click:", err);
    }
  }

  return (
    <Button
      size={size}
      variant="contained"
      color="secondary"
      onClick={handleGetOneClick}
      sx={{
        minWidth: "120px",
        padding:
          size === "small" ? "4px 8px" : { xs: "4px 8px", md: "6px 16px" },
        fontSize: size === "small" ? ".75rem" : { xs: ".65rem", md: ".875rem" },
        fontWeight: "600",
      }}
    >
      Get One!{" "}
      <OpenInNew
        sx={{
          ml: "3px",
          opacity: ".5",
          fontSize: { xs: ".75rem", md: "1rem" },
        }}
      />
    </Button>
  );
};

export default GetOne;
