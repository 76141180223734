import { Box, Button } from "@mui/material";
import CustomLink from "../../util/CustomLink";

function AuthFooter(props) {
  return (
    <Box
      style={{
        fontSize: "0.9rem",
        textAlign: "center",
      }}
    >
      {props.type === "signup" &&
        !props.signupSuccess &&
        !props.shouldVerify && (
          <>
            {/* {props.showAgreement && (
            <Box mt={1} mb={2} sx={{ color: "accents.light" }}>
              By signing up, you are agreeing to our{" "}
              <LinkMui component={Link} to={props.termsPath}>
                Terms of Service
              </LinkMui>{" "}
              and{" "}
              <LinkMui component={Link} to={props.privacyPolicyPath}>
                Privacy Policy
              </LinkMui>
              .
            </Box>
          )} */}

            <Box
              sx={{
                mb: 2,
                width: "100%",
                padding: props.isSmOrSmaller ? "5px 0" : "10px 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                borderRadius: "5px",
                backgroundColor: "background.dark",
                fontSize: props.isSmOrSmaller ? ".8rem" : "1rem",
                fontWeight: "500",
              }}
            >
              {props.signinText}
              {props.handleCloseModal ? (
                <Button onClick={() => props.setType("signin")}>
                  {props.signinAction}
                </Button>
              ) : (
                <CustomLink href={props.signinPath} type="mui">
                  {props.signinAction}
                </CustomLink>
              )}
            </Box>
          </>
        )}

      {props.type === "signin" && !props.shouldVerify && (
        <>
          <Box
            sx={{
              width: "100%",
              marginTop: "8px",
              padding: "10px 0",
              display: "flex",
              justifyContent: "center",
              alignItems: props.handleCloseModal ? "center" : "end",
              gap: "5px",
              borderRadius: "5px",
              backgroundColor: "background.paper",
              fontSize: "1rem",
              fontWeight: "500",
            }}
          >
            {props.signupText}
            {props.handleCloseModal ? (
              <Button onClick={() => props.setType("signup")}>
                {props.signupAction}
              </Button>
            ) : (
              <CustomLink
                href={props.signupPath}
                type="mui"
                sx={{ fontSize: "1.1rem" }}
              >
                {props.signupAction}
              </CustomLink>
            )}
          </Box>

          {props.forgotPassAction && !props.shouldVerify && (
            <>
              <br />
              {props.handleCloseModal ? (
                <Button onClick={() => props.setType("forgotpass")}>
                  {props.forgotPassAction}
                </Button>
              ) : (
                <CustomLink href={props.forgotPassPath} type="mui">
                  {props.forgotPassAction}
                </CustomLink>
              )}
            </>
          )}
        </>
      )}

      {props.type === "forgotpass" && (
        <Box mt={2}>
          {props.signinText}
          <Box
            component="span"
            sx={{ display: "inline-block", width: (theme) => theme.spacing(1) }}
          />
          {props.handleCloseModal ? (
            <Button onClick={() => props.setType("signin")}>
              {props.signinAction}
            </Button>
          ) : (
            <CustomLink href={props.signinPath} type="mui">
              {props.signinAction}
            </CustomLink>
          )}
        </Box>
      )}
    </Box>
  );
}

export default AuthFooter;
