import { useState, useRef, useEffect } from "react";
import { Card, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dragger from "../Dragger";
import {
  startScreenCapture,
  stopScreenCapture,
} from "./utils/screenCaptureFunctions";
import config from "../../config";

const ScreenCapture = ({
  screenCaptureOpen,
  setScreenCaptureOpen,
  sessionId,
  clusterId,
  setError,
}) => {
  const [websocket, setWebsocket] = useState(null);
  const [videoStream, setVideoStream] = useState(null);
  const [videoElement, setVideoElement] = useState(null);
  const feagiCanvasRef = useRef(null);
  const userCanvasRef = useRef(null);

  // stop/start screen capture on toggle of NavEmbodiment switch
  useEffect(() => {
    if (screenCaptureOpen) {
      startScreenCapture(
        setScreenCaptureOpen,
        feagiCanvasRef,
        userCanvasRef,
        websocket,
        setWebsocket,
        videoStream,
        setVideoStream,
        videoElement,
        setVideoElement,
        sessionId,
        clusterId,
        setError
      );
    } else if (!screenCaptureOpen && videoStream) {
      stopScreenCapture(
        setScreenCaptureOpen,
        websocket,
        setWebsocket,
        videoStream,
        setVideoStream,
        videoElement,
        setVideoElement
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenCaptureOpen]);

  return (
    <>
      {screenCaptureOpen && (
        <Dragger handle=".dragHandle">
          <Card
            style={{
              position: "absolute",
              display: screenCaptureOpen ? "block" : "none",
              border: "1px solid black",
              backgroundColor: "black",
            }}
          >
            <div
              className="dragHandle"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "2px",
                backgroundColor: "#313131",
                cursor: "move",
              }}
            >
              <IconButton
                onClick={() =>
                  stopScreenCapture(
                    setScreenCaptureOpen,
                    websocket,
                    setWebsocket,
                    videoStream,
                    setVideoStream,
                    videoElement,
                    setVideoElement
                  )
                }
                onMouseDown={(e) => e.stopPropagation()}
                style={{
                  padding: "4px",
                  width: "16px",
                  height: "16px",
                  fontSize: "16px",
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <canvas
              ref={feagiCanvasRef}
              width={config.canvasWidth}
              height={config.canvasHeight}
              style={{ display: "none" }}
            ></canvas>
            <canvas
              ref={userCanvasRef}
              width="300"
              height="300"
              style={{ backgroundColor: "black" }}
            ></canvas>
          </Card>
        </Dragger>
      )}
    </>
  );
};

export default ScreenCapture;
