import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Box } from "@mui/material";
import logo from "../img/neuraville-logo.png";
// Utils
import { useAuth } from "../util/auth";
// Components
import BadgeHandler from "./BadgeHandler";
import NavbarWrapper from "./navbar/NavbarWrapper";
// Pages
import AuthPage from "../pages/auth";
import BookPage from "../pages/book";
import BrainLoading from "./BrainLoading";
import BrainVisualizerPage from "../pages/brain-visualizer";
import Contact from "./Contact";
import EmbodimentPage from "../pages/embodiment";
import Footer from "./Footer";
import Hub from "./Hub";
import LabPage from "../pages/lab";
import LegalPage from "../pages/legal";
import NewExperimentPage from "../pages/new-experiment";
import NotFoundPage from "../pages/404";
import OAuthCallbackHandler from "./auth/OAuthCallbackHandler";
import OAuthFailureHandler from "./auth/OAuthFailureHandler";
import PlaySelectPage from "../pages/play-select";
import PlayPage from "../pages/play-game";
import PostMessageTest from "../pages/post-message";
import RegionUnsupportedPage from "../pages/region-unsupported";
import SettingsPage from "../pages/settings";
import ServerDown from "./ServerDown";
import UserProfilePage from "../pages/user-profile";
import SnackbarHandler from "../util/SnackbarHandler";

const RedirectIfUser = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const pathSegments = window.location.pathname.split("/");
  const baseRoute = pathSegments[1];
  const authType = pathSegments[2];
  const validAuthTypes = ["signin", "signup", "forgotpass"];
  const hasSignedIn = localStorage.getItem("hasSignedIn");

  useEffect(() => {
    if (auth?.user?.accessToken) {
      navigate("/lab");
      // #business
      // if (auth.user?.roles?.includes("business")) {
      //   history.replace("/pricing");
      // } else {
      // }
    } else if (hasSignedIn && baseRoute === "") {
      navigate("/auth/signin");
    }
    setIsLoading(false);
  }, [auth, auth?.user, navigate, hasSignedIn, baseRoute]);

  if (isLoading) {
    return null;
  }

  if (baseRoute === "auth" && !validAuthTypes.includes(authType)) {
    return <NotFoundPage />;
  }

  // return <IndexPage />;
  return <AuthPage />;
};

const AppGuts = ({
  badges,
  setBadges,
  snackbar,
  setSnackbar,
  navbarRef,
  navbarHeight,
}) => {
  // Embodiments
  const [webcamOpen, setWebcamOpen] = useState(false);
  const [freeNoveOpen, setFreeNoveOpen] = useState(false);
  const [mycobotOpen, setMycobotOpen] = useState(false);
  const [cozmoOpen, setCozmoOpen] = useState(false);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "auto 1fr auto", // Header, content, footer
        minHeight: "100vh",
        backgroundColor: useLocation().pathname.startsWith("/auth")
          ? "black"
          : "background.default",
      }}
    >
      <NavbarWrapper
        color="primary"
        logo={logo}
        webcamOpen={webcamOpen}
        setWebcamOpen={setWebcamOpen}
        freeNoveOpen={freeNoveOpen}
        setFreeNoveOpen={setFreeNoveOpen}
        mycobotOpen={mycobotOpen}
        setMycobotOpen={setMycobotOpen}
        cozmoOpen={cozmoOpen}
        setCozmoOpen={setCozmoOpen}
        ref={navbarRef}
      />
      <Box
        sx={{
          // flexGrow: 1,
          minHeight: {
            xs: "calc(100vh - 136px - 64px)",
            lg: "calc(100vh - 72px - 64px)",
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Routes>
          <Route path="/" element={<Navigate to="/lab" replace />} />

          <Route path="/test" element={<PostMessageTest />} />

          <Route
            path="/auth/oauth_callback/success"
            element={<OAuthCallbackHandler />}
          />

          <Route
            path="/auth/oauth_callback/failure"
            element={<OAuthFailureHandler />}
          />

          <Route path="/auth/:type" element={<RedirectIfUser />} />

          <Route path="/book/:type" element={<BookPage />} />

          <Route path="/brain-loading" element={<BrainLoading />} />

          <Route
            path="/brain-visualizer"
            element={
              <BrainVisualizerPage
                webcamOpen={webcamOpen}
                setWebcamOpen={setWebcamOpen}
                navbarHeight={navbarHeight}
              />
            }
          />

          {/* <Route path="/business" element={RedirectIfUser} /> */}

          <Route path="/contact" element={<Contact />} />

          <Route path="/contact/:type" element={<Contact />} />

          <Route path="/embodiment" element={<EmbodimentPage />} />

          <Route path="/hub" element={<Hub />} />

          <Route path="/lab" element={<LabPage />} />

          <Route path="/legal/:section" element={<LegalPage />} />

          <Route path="/new-experiment" element={<NewExperimentPage />} />

          <Route path="/play/:id" element={<PlayPage />} />

          <Route exact path="/play" element={<PlaySelectPage />} />

          {/* <Route path="/pricing" element={<PricingPage} /> */}

          <Route
            path="/region-unsupported"
            element={<RegionUnsupportedPage />}
          />

          <Route path="/server-down" element={<ServerDown />} />

          <Route path="/settings/:section" element={<SettingsPage />} />

          <Route path="/user/:id/genomes" element={<UserProfilePage />} />

          <Route path="/user/:id" element={<UserProfilePage />} />

          <Route path="/404" element={<NotFoundPage />} />

          <Route path="*" element={<NotFoundPage />} />

          {/* <Route
            path="/purchase/:plan"
            element={<PurchasePage}
          /> */}

          {/* <Route
          path="/firebase-action"
          element={<FirebaseActionPage}
        /> */}

          {/* <Route path="/instance" element={<instancePage} /> */}

          {/* <Route
            path="/business/auth/:type"
            element={<RedirectIfUser}
          /> */}
        </Routes>
      </Box>
      <Footer
        size=""
        bgImage=""
        bgImageOpacity={1}
        description="Creating brains for robots"
        copyright={`© ${new Date().getFullYear()} Neuraville Inc.`}
        logo={logo}
        sticky={true}
      />

      {/* Badge notification popup(s) */}
      {Array.isArray(badges) && badges.length > 0 && (
        <BadgeHandler badges={badges} setBadges={setBadges} />
      )}

      {/* Snackbar notification */}
      {snackbar && snackbar.message && (
        <SnackbarHandler content={snackbar} onClose={() => setSnackbar(null)} />
      )}
    </Box>
  );
};

export default AppGuts;
