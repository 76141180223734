import { Box, Typography } from "@mui/material";
import SlideUpBox from "../../../util/SlideUpBox";

const GetStarted = ({ isSmOrSmaller }) => {
  return (
    <SlideUpBox>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {" "}
        <Box
          sx={{
            position: "relative",
            mt: 8,
            mb: { xs: 0, md: 8 },
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {/* Flavor bg image */}
          {/* {isSmOrSmaller && (
      <Box
        sx={{
          position: "absolute",
          top: { xs: "70px", md: "-210px" },
          right: { xs: "-580px", md: "-700px" },
          width: "746px",
          height: "476px",
          opacity: { xs: 0.2, md: 0.1 },
        }}
      >
        <img
          src={blueConnections}
          alt=""
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        />
      </Box>
    )} */}
          <Box
            sx={{
              zIndex: 10,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "1.5rem", md: "2rem" },
                fontWeight: "700",
              }}
            >
              Get started now
            </Typography>{" "}
            <Typography sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}>
              Learn an incredible new way to build AI,{" "}
              <Typography
                component="span"
                color="primary"
                sx={{
                  fontSize: { xs: "1rem", md: "1.3rem" },
                  fontWeight: "600",
                }}
              >
                advancing democratic AI
              </Typography>{" "}
              and gaining cutting-edge skills. Train{" "}
              <Typography
                component="span"
                color="primary"
                sx={{
                  fontSize: { xs: "1rem", md: "1.3rem" },
                  fontWeight: "600",
                }}
              >
                virtual characters or robots
              </Typography>{" "}
              to do things you’ve never seen them do before, and share with the
              world!{" "}
            </Typography>
            <Typography sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}>
              {!isSmOrSmaller && "Sign up on the right to begin your journey."}
            </Typography>
          </Box>
        </Box>
      </Box>
    </SlideUpBox>
  );
};

export default GetStarted;
