import { useEffect } from "react";
import { sendToIframe, listenToIframe } from "../../util/iframeMessaging";
// import { logger } from "../../util/logger";

const usePostMessage = (isPhysical, embodimentId, setSendMessage) => {
  useEffect(() => {
    if (isPhysical) return;

    const iframe = document.querySelector(`iframe[title="${embodimentId}"]`);

    const sendFunction = (data) => {
      const jsonData = JSON.stringify(data);
      // logger("postmessage sending to game iframe:", jsonData);
      sendToIframe(iframe, jsonData);
    };

    // Set the send function
    setSendMessage(() => sendFunction);

    // Set up listener for messages from the iframe
    const removeListener = listenToIframe((event) => {
      // console.log("Received message:", event.data);
    });

    // const intervalId = setInterval(() => {
    //   const randomMessage = { type: "TEST_MESSAGE", data: "test" };
    //   sendFunction(randomMessage);
    //   console.log("Sent message:", randomMessage);
    // }, 3000);

    // Clean up on unmount
    return () => {
      // clearInterval(intervalId);
      removeListener();
    };
  }, [isPhysical, embodimentId, setSendMessage]);
};

export default usePostMessage;
