import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import ExperimentCard from "../lab-experiments/ExperimentCard";
import { StyledButton, StyledPopover } from "../../styles/NavbarDropdownStyles";
import { Cancel as CancelIcon, Info as InfoIcon } from "@mui/icons-material";
import { getUserExperiments } from "../../api/userManagement";
import NavbarVersions from "./NavbarVersions";

const NavbarExperiment = ({
  accessToken,
  experimentId,
  experimentTitle,
  sessionId,
  clusterId,
  setError,
  setMessage,
  awaitAugment,
  isSmOrSmaller,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // eslint-disable-line no-unused-vars
  // Entire dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const id = menuOpen ? "experiment-menu" : undefined;
  // Experiment info dialog
  const [open, setOpen] = useState(false);
  const [isRunning, setIsRunning] = useState(true);
  const [experiment, setExperiment] = useState({});
  // Versions dialog
  const [versionsOpen, setVersionsOpen] = useState(false);

  // Open embodiment dropdown
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close embodiment dropdown
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Close experiment dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Open versions dialog
  const handleVersionsOpen = () => {
    handleMenuClose();
    setVersionsOpen(true);
  };

  // Open info dialog
  const handleInfoOpen = () => {
    handleMenuClose();
    setOpen(true);
  };

  // Clear error & message
  const clearAlerts = () => {
    setError("");
    setMessage("");
  };

  // Fetch experiment info
  const fetchExperiment = async () => {
    try {
      clearAlerts();
      const res = await getUserExperiments(accessToken);
      const experiments = res.data.results;
      const foundExperiment = experiments.find(
        (experiment) => experiment.experiment_id === experimentId
      );
      if (foundExperiment) {
        setExperiment(foundExperiment);
      } else {
        throw new Error("Experiment not found in server results.");
      }
    } catch (err) {
      console.error(err);
      setError("Sorry, there was an error fetching your experiment info.");
    }
  };

  useEffect(() => {
    experimentId && fetchExperiment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experimentId]);

  return (
    <>
      <StyledButton
        onClick={handleMenuClick}
        disabled={awaitAugment || !experiment.experiment_id || !accessToken}
        sx={{ minWidth: { xs: "24px", md: "64px" } }}
      >
        {isSmOrSmaller ? "Exp" : "Experiment"}
      </StyledButton>
      <StyledPopover
        id={id}
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", gap: "10px" }}
        >
          {/* Title & info icon */}
          <Box
            display="flex"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <Typography
              sx={{
                maxWidth: "120px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {experimentTitle || "Loading..."}
            </Typography>
            <Tooltip title="Experiment info">
              <IconButton onClick={handleInfoOpen}>
                <InfoIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
          </Box>
          {/* Versions */}
          <Button size="small" variant="outlined" onClick={handleVersionsOpen}>
            Versions
          </Button>
        </Box>
      </StyledPopover>

      {/* Info Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="title"
        maxWidth="lg"
      >
        <DialogTitle id="title">
          {/* icon here like in embodiment & genome popup? */}
          {"Experiment Info"}
          <IconButton
            style={{ position: "absolute", right: 7, top: 7, padding: 3 }}
            onClick={handleClose}
            size="small"
          >
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minWidth: { xs: "80vw", lg: "40vw" } }}>
          {open && experiment.experiment_id && (
            <ExperimentCard
              experiment={experiment}
              setError={setError}
              setMessage={setMessage}
              clearAlerts={clearAlerts}
              setLoading={setLoading}
              isRunning={isRunning}
              setIsRunning={setIsRunning}
              setExperiments={fetchExperiment}
              refreshExperiments={fetchExperiment}
              navigate={navigate}
              isBrainVis={true}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Versions dialog */}
      <NavbarVersions
        versionsOpen={versionsOpen}
        setVersionsOpen={setVersionsOpen}
        sessionId={sessionId}
        clusterId={clusterId}
        experimentId={experiment?.experiment_id}
        setError={setError}
        setMessage={setMessage}
      />
    </>
  );
};

export default NavbarExperiment;
