// export default Dragger;
import { useCallback, useEffect, useState } from "react";

const Dragger = ({ children, top = "50px" }) => {
  const [pos, setPos] = useState({ x: 0, y: 72 });
  const [dragging, setDragging] = useState(false);
  const [rel, setRel] = useState(null); // position relative to the cursor

  const onMouseDown = (e) => {
    // console.log("in mouse down");
    // only left mouse button
    if (e.button !== 0) return;
    // console.log("e.button is ", e.button);
    let posX = e.pageX - pos.x;
    let posY = e.pageY - pos.y;
    setDragging(true);
    setRel({ x: posX, y: posY });
    e.stopPropagation();
    e.preventDefault();
  };

  const onMouseMove = useCallback(
    (e) => {
      if (!dragging) return;
      // console.log("onMouseMove");
      setPos({
        x: e.pageX - rel.x,
        y: e.pageY - rel.y,
      });
      e.stopPropagation();
      e.preventDefault();
    },
    [dragging, rel]
  ); // dependency array for the useCallback

  useEffect(() => {
    const onMouseUp = (e) => {
      // console.log("released");
      setDragging(false);
      e.stopPropagation();
      e.preventDefault();
    };

    if (dragging) {
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", onMouseUp);
    } else {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    }

    // cleaning up the effect
    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [dragging, onMouseMove]);

  // useEffect(() => {
  //   console.log("pos: ", pos);
  //   console.log("rel: ", rel);
  // }, [pos, rel]);

  return (
    <>
      <div
        onMouseDown={onMouseDown}
        style={{
          position: "absolute",
          top: top,
          transform: `translate(${pos.x}px, ${pos.y}px)`,
        }}
      >
        {children}
      </div>
      {dragging && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1000,
          }}
        />
      )}
    </>
  );
};

export default Dragger;
