let showSnackbar = null;

// Make the Snackbar function available to all files
export const setSnackbarUpdater = (updater) => {
  showSnackbar = updater;
};

// Trigger the Snackbar
export const updateSnackbar = (snackbar) => {
  showSnackbar && showSnackbar(snackbar);
};
