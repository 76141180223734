import NewWindowEmbodiment from "../components/NewWindowEmbodiment";
import { requireAuth } from "../util/auth";
import Meta from "./../components/Meta";

function EmbodimentPage(props) {
  return (
    <>
      <Meta title="Standalone Embodiment Page" />
      <NewWindowEmbodiment />
    </>
  );
}

export default requireAuth(EmbodimentPage);
