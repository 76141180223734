const cameraCapabilities = {
  capabilities: {
    input: {
      camera: {
        0: {
          custom_name: "media_capture",
          disabled: false,
          eccentricity_control: {
            "X offset percentage": 33,
            "Y offset percentage": 33,
          },
          feagi_index: 0,
          index: "00",
          mirror: true,
          modulation_control: {
            "X offset percentage": 66,
            "Y offset percentage": 66,
          },
          threshold_default: 50,
        },
      },
    },
  },
};

export default cameraCapabilities;
