import { EmailAuthProvider } from "firebase/auth";
import { useState } from "react";
import { TextField, Typography } from "@mui/material";
import { useAuth } from "./auth";
import CustomDialog from "../components/CustomDialog";
import EmailToken from "./EmailToken";

const ReauthModal = ({
  reauthOpen,
  setReauthOpen,
  originalEmail,
  email,
  setEmail,
  handleSuccess,
}) => {
  const auth = useAuth();
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Validate email as user types
  const handleEmailChange = (event) => {
    const value = event.target.value;
    if (value.length > 254) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email cannot exceed 254 characters",
      }));
    } else {
      setFormErrors((prevErrors) => ({ ...prevErrors, email: null }));
      setEmail(value);
    }
  };

  // Reauthenticate, then set as submitted to display email token component
  const handleSubmit = async () => {
    try {
      if (originalEmail === email) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: "This is already the email for your account.",
        }));
        return;
      }
      const credential = EmailAuthProvider.credential(
        auth.user.email,
        password
      );
      await auth.reauthenticateWithCredential(credential);
      setIsSubmitted(true);
      setPassword("");
    } catch (err) {
      console.error(err);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        password: "Incorrect password",
      }));
    }
  };

  return (
    <CustomDialog
      header="Change Email"
      text={
        !isSubmitted
          ? "Please enter your new email, and reenter your password for security. A token will be sent to your new email."
          : `A token has been sent to ${email}. Please check your inbox and enter it below.`
      }
      isOpen={reauthOpen}
      handleClose={() => setReauthOpen(false)}
      confirmAction={!isSubmitted ? handleSubmit : null}
      richText={
        <>
          {!isSubmitted ? (
            <form>
              <TextField
                variant="outlined"
                type="email"
                label="New Email"
                placeholder="user@example.com"
                value={email}
                onChange={handleEmailChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
                fullWidth
                sx={{ mt: 2 }}
              />
              <TextField
                variant="outlined"
                type="password"
                label="Confirm Password"
                placeholder="Password"
                value={password}
                error={!!formErrors.password}
                helperText={formErrors.password}
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
                sx={{ mt: 2 }}
              />
            </form>
          ) : (
            <>
              <Typography sx={{ mt: 2, fontWeight: "600" }}>
                NOTE: You will be signed out upon completion, and will need to
                sign in with your new email.
              </Typography>
              <EmailToken email={email} handleSuccess={handleSuccess} />
            </>
          )}
        </>
      }
    />
  );
};

export default ReauthModal;
