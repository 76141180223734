import { Box, Typography } from "@mui/material";
import { memo, useState } from "react";
import formatDate from "../util/formatDate";

const UserBadges = memo(({ badges }) => {
  const [flippedBadge, setFlippedBadge] = useState(null);

  const handleFlip = (img_url) => {
    setFlippedBadge(flippedBadge === img_url ? null : img_url);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {/* <Typography
        sx={{
          fontSize: "0.85rem",
          color: "accents.light",
        }}
      >
        Achievements
      </Typography> */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: 2,
        }}
      >
        {!badges?.length && (
          <Typography
            sx={{
              fontSize: "0.85rem",
              fontStyle: "italic",
              color: "accents.light",
            }}
          >
            No badges yet earned.
          </Typography>
        )}
        {badges?.map((badge) => {
          if (!badge.img_url || !badge.name) return null;

          return (
            <Box
              key={badge.img_url}
              onMouseEnter={() => handleFlip(badge.img_url)}
              onMouseLeave={() => handleFlip(badge.img_url)}
              onClick={() => handleFlip(badge.img_url)}
              sx={{
                position: "relative",
                width: { xs: "100px", md: "125px" },
                height: { xs: "140px", md: "165px" },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                border: 1,
                borderRadius: "5px",
                borderColor: "background.light",
                perspective: "1000px",
                transformStyle: "preserve-3d",
                transition: "transform 0.6s",
                transform:
                  flippedBadge === badge.img_url
                    ? "rotateY(180deg)"
                    : "rotateY(0deg)",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  p: 1,
                  width: "100%",
                  height: "100%",
                  backfaceVisibility: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={badge.img_url}
                  alt={badge.img_alt_text}
                  style={{ maxWidth: "100%", objectFit: "contain" }}
                />
                <Typography
                  sx={{
                    height: "32px",
                    mt: "5px",
                    fontSize: { xs: ".5rem", md: ".65rem" },
                    fontWeight: "600",
                    textTransform: "uppercase",
                    wordBreak: "break-word",
                    color: "white",
                  }}
                >
                  {badge.name}
                </Typography>
                {badge.earned_date && (
                  <Typography
                    sx={{
                      fontSize: { xs: ".45rem", md: ".6rem" },
                      color: "accents.light",
                    }}
                  >
                    {formatDate(badge.earned_date)}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  p: 1,
                  backfaceVisibility: "hidden",
                  transform: "rotateY(180deg)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "background.dark",
                }}
              >
                <Typography
                  sx={{
                    mt: "5px",
                    fontSize: { xs: ".5rem", md: ".65rem" },
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  Details
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: ".45rem", md: ".6rem" },
                    wordBreak: "break-word",
                    textAlign: "center",
                    color: "accents.light",
                  }}
                >
                  {badge.description}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
});

export default UserBadges;
