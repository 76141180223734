import { Box, Typography } from "@mui/material";
import blueGenome from "../../../img/landing/midjourney-genome.jpg";
import blueDog from "../../../img/landing/midjourney-dog.png";
import bluePool from "../../../img/landing/midjourney-pool.png";
import blueConnections from "../../../img/landing/midjourney-connections-abstract.jpeg";
import SlideUpBox from "../../../util/SlideUpBox";

const Method = ({ isSmOrSmaller }) => {
  const methodSteps = [
    {
      imageSrc: blueGenome,
      imageAlt: "glowing blue digital genome",
      title: "Start with a digital genome",
      description:
        "that has the genetic code to develop an artificial brain. Genomes range from basic templates to complex pre-developed models.",
    },
    {
      imageSrc: blueDog,
      imageAlt: "glowing blue dog",
      title: "Train the brain",
      description:
        "using a variety of biologically inspired learning techniques (think Pavlov’s dog).",
    },
    {
      imageSrc: bluePool,
      imageAlt: "glowing neural connections",
      title: "Add your genome to the pool",
      description: "and watch other users build on your creation.",
    },
  ];

  return (
    <SlideUpBox>
      <Box
        sx={{
          position: "relative",
          mt: { xs: 2, md: 10 },
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {isSmOrSmaller && (
          <Box
            sx={{
              position: "absolute",
              top: "-270px",
              left: "-350px",
              width: "746px",
              height: "476px",
              opacity: 0.2,
            }}
          >
            <img
              src={blueConnections}
              alt=""
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        )}
        <Box
          sx={{
            zIndex: 10,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "1.5rem", md: "2rem" },
              fontWeight: "700",
            }}
          >
            How does it work?
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "1rem", md: "1.3rem" },
              color: "accents.light",
            }}
          >
            Codelessly develop AI brains from the ground up.{" "}
            <Typography
              component="span"
              color="primary"
              sx={{
                fontWeight: "600",
                fontSize: { xs: "1rem", md: "1.3rem" },
              }}
            >
              Take on the future
            </Typography>{" "}
            with technology that puts you in the driver’s seat of AI
            development.
          </Typography>
        </Box>
        <Box
          sx={{
            zIndex: 10,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "flex-end",
            gap: { xs: 6, md: 2, xl: 4 },
          }}
        >
          {methodSteps.map((step, index) => (
            <Box
              key={step.title}
              sx={{
                width: "100%",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={step.imageSrc}
                  alt={step.imageAlt}
                  style={{
                    height: isSmOrSmaller ? "200px" : "300px",
                    maxWidth: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: 2, md: 1, xl: 2 },
                  minHeight: { xs: null, md: "200px" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "1.2rem", md: "1rem", xl: "1.2rem" },
                    fontWeight: "500",
                  }}
                >
                  {index + 1}. {step.title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "1rem", md: ".9rem", xl: "1rem" },
                    color: "accents.light",
                  }}
                >
                  {step.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </SlideUpBox>
  );
};

export default Method;
