import {
  getExperimentInfo,
  stopExperiment,
} from "../../../api/experimentManagement";

export default async function callStopExperiment(
  accessToken,
  experimentId,
  setLoading,
  setError,
  setMessage,
  setButtonText,
  setIsRunning,
  setExperiments,
  refreshExperiments
) {
  setError("");
  setMessage("");
  setLoading(true);
  try {
    await stopExperiment(accessToken, experimentId);
    const experimentInfo = await getExperimentInfo(experimentId, accessToken);
    if (
      experimentInfo.data.state === "running" ||
      experimentInfo.data.state === "initializing"
    ) {
      throw new Error("Error stopping experiment. Please try again.");
    }
    setButtonText("Start");
    setLoading(false);
    setMessage("Experiment successfully stopped.");
    localStorage.removeItem("sessionId");
    await refreshExperiments(accessToken, setExperiments, setError, setLoading);
    setIsRunning(false);
  } catch (error) {
    console.error(error);
    setLoading(false);
    setError(error.message);
  }
}
