import { useState } from "react";
import { Button } from "@mui/material";
import { shutEmDown } from "../api/userManagement";
import FullscreenLoader from "./FullscreenLoader";

const ShutDownButton = ({ accessToken, setMessage, successAction }) => {
  const [fullscreenLoader, setFullscreenLoader] = useState(false);

  async function handleShutEmDown() {
    try {
      setFullscreenLoader(true);
      await shutEmDown(accessToken);
      successAction();
    } catch (err) {
      console.error(err);
      setMessage({
        type: "general",
        text: "An error occurred shutting down your experiment(s). Please try again, or return to the lab via the navbar if issues persist.",
        severity: "error",
      });
    } finally {
      setFullscreenLoader(false);
    }
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={handleShutEmDown}
        sx={{
          color: "white",
          backgroundColor: "red.main",
          "&:hover": {
            backgroundColor: "red.light",
          },
        }}
      >
        Close Sessions
      </Button>

      {fullscreenLoader && (
        <FullscreenLoader message="Shutting down sessions..." />
      )}
    </>
  );
};

export default ShutDownButton;
