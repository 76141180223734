import { produce } from "immer";

function updateCapabilitiesControl(
  capabilities,
  controlType,
  newValues,
  cameraIndex = 0
) {
  // Check args
  const validControlTypes = ["eccentricity_control", "modulation_control"];

  if (!validControlTypes.includes(controlType)) {
    console.error(
      `Invalid control type. Must be one of: ${validControlTypes.join(", ")}`
    );
    return;
  }

  const camera = capabilities?.capabilities?.input?.camera?.[cameraIndex];
  if (!camera) {
    console.error(`Camera with index ${cameraIndex} not found.`);
    return;
  }

  // Copy capabilities with immer to avoid mutating state or crazy drilling
  const newCapabilities = produce(capabilities, (draft) => {
    const camera = draft.capabilities.input.camera[cameraIndex];
    camera[controlType] = {
      ...camera[controlType],
      ...newValues,
    };
  });

  return newCapabilities;
}

export default updateCapabilitiesControl;
