import { useState, useEffect } from "react";
import { useAuth } from "../../util/auth";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  useTheme,
} from "@mui/material";
import physicalEnvironment from "../../img/environment-physical.png";
import virtualEnvironment from "../../img/environment-virtual.png";
import { getEnvironments } from "../../api/environmentManagement";
import PageLoader from "../PageLoader";

const NewExperimentEnvironment = ({
  onComplete,
  collectedData,
  setCollectedData,
  setError,
}) => {
  const auth = useAuth();
  const theme = useTheme();
  const [environments, setEnvironments] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    try {
      const accessToken = auth?.user?.accessToken;

      const fetchData = async () => {
        const res = await getEnvironments(accessToken);
        if (res.data?.results) {
          setEnvironments(res.data.results);
        } else {
          throw new Error("Did not receive environment data from DB!");
        }
      };

      fetchData();
    } catch (error) {
      console.error(error);
      setError(
        "Sorry, there was a problem retrieving environment data. Please try again later."
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleExperimentSelection(title, id) {
    setError("");
    setCollectedData({
      ...collectedData,
      environmentTitle: title,
      environmentId: id,
    });
    onComplete();
  }

  if (environments.length === 0) {
    return (
      <Box display="flex" gap="30px" width="100%" marginTop="30px">
        <PageLoader />
      </Box>
    );
  }

  return (
    <>
      <Box
        display="flex"
        width="100%"
        marginTop="30px"
        sx={{ gap: { xs: "15px", md: "30px" } }}
      >
        {environments.map((environment) => (
          <Card
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() =>
              handleExperimentSelection(
                environment.environment_title,
                environment.environment_id
              )
            }
          >
            {/* <CardMedia
              component="img"
              sx={{
                maxWidth: "80%",
                objectFit: "cover",
                margin: "auto",
                paddingTop: "24px",
              }}
              image={
                environment.environment_title === "Virtual"
                  ? virtualEnvironment
                  : physicalEnvironment
              }
              alt="photo of desk with electronics"
            /> */}
            <Box
              sx={{
                width: "100%",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                padding: { xs: "15px", md: "50px" },
                pb: { xs: 0, md: "30px" },
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  width: { xs: "90%", md: "80%" },
                  height: "auto",
                  objectFit: "cover",
                }}
                image={
                  environment.environment_title === "Virtual"
                    ? virtualEnvironment
                    : physicalEnvironment
                }
                alt="photo of desk with electronics"
              />
            </Box>
            <CardContent sx={{ px: { xs: 1, md: 2 } }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                textAlign="center"
              >
                {environment.environment_title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: { xs: "0.8rem", md: ".875rem" },
                  textAlign: "center",
                  color: theme.palette.text.secondary,
                }}
              >
                {environment.description}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </>
  );
};

export default NewExperimentEnvironment;
