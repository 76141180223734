import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import PlayHtmlAppMaker from "./PlayHtmlAppMaker";
import PlayFeagiLoading from "./PlayFeagiLoading";
import { useAuth } from "../../util/auth";
import SignupModal from "../../util/SignupModal";

const PlayFeagi = ({
  embodimentId,
  sessionId,
  setSessionId,
  clusterId,
  setClusterId,
  config,
  isSmOrSmaller,
  isAbove2XL,
  dimensions,
}) => {
  const auth = useAuth();
  const accessToken = auth.user?.accessToken;
  const [sessionOpen, setSessionOpen] = useState(false);
  const [sessionLoading, setSessionLoading] = useState(false);
  const [signupModalOpen, setSignupModalOpen] = useState(false);

  useEffect(() => {
    if (sessionId && clusterId && accessToken) {
      setSessionOpen(true);
    }
  }, [sessionId, clusterId, accessToken]);

  const handleClick = () => {
    if (accessToken) {
      setSessionLoading(true);
    } else {
      setSignupModalOpen(true);
    }
  };

  return (
    <Box
      sx={{
        flex: 1,
        width: "100%",
        height: "100%",
        maxHeight: isAbove2XL ? undefined : "400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: { xs: "center", md: "flex-start" },
        gap: 2,
      }}
    >
      <Typography variant="h3" sx={{ fontSize: "1.3rem", fontWeight: "600" }}>
        {config?.label || "AI"}
      </Typography>
      {sessionOpen && !sessionLoading && config ? (
        <PlayHtmlAppMaker
          player={2}
          feagiIndex={config.capabilities?.input?.camera?.[0]?.feagi_index}
          outputFeagiIndex={
            config.capabilities?.output?.motion_control?.[0]?.feagi_index
          }
          outputMotorFeagiIndex={
            config.capabilities?.output?.motor?.[0]?.feagi_index
          }
          port="9056"
          embodimentId={embodimentId}
          sessionId={sessionId}
          clusterId={clusterId}
          isSmOrSmaller={isSmOrSmaller}
          isAbove2XL={isAbove2XL}
          dimensions={dimensions}
        />
      ) : sessionLoading ? (
        <PlayFeagiLoading
          embodimentId={embodimentId}
          setSessionId={setSessionId}
          setClusterId={setClusterId}
          setSessionLoading={setSessionLoading}
          setSessionOpen={setSessionOpen}
          isAbove2XL={isAbove2XL}
          isSmOrSmaller={isSmOrSmaller}
        />
      ) : (
        <Box
          sx={{
            position: "relative",
            height: "100%",
            width: "100%",
            minHeight: "200px",
            maxWidth: isAbove2XL ? "900px" : isSmOrSmaller ? "300px" : "600px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid",
            borderColor: "primary.lowOpacity",
            backgroundColor: "background.dark",
          }}
        >
          {/* <img
            src={gestureGuy}
            alt="Webcam with grid areas highlighted by a cartoon man moving his hands"
            crossOrigin="anonymous"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          /> */}
          <Button size="small" variant="contained" onClick={handleClick}>
            Activate AI
          </Button>
        </Box>
      )}

      {/* Signup modal */}
      {signupModalOpen && (
        <SignupModal open={signupModalOpen} setOpen={setSignupModalOpen} />
      )}
    </Box>
  );
};

export default PlayFeagi;
