import React from "react";
import { Box, Card, CardMedia, CardContent, Typography } from "@mui/material";

const SummaryCard = ({ title, imageSrc, altText }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: "1",
      }}
    >
      <Card
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "15px",
          backgroundImage: "none",
          bgcolor: "transparent",
          border: "1px solid",
          borderColor: "accents.superDark",
          "&.MuiCard-root:hover": {
            bgcolor: "transparent",
          },
        }}
      >
        <CardMedia
          component="img"
          sx={{
            margin: "auto",
            paddingTop: "24px",
            objectFit: "contain",
            width: "100%",
            maxWidth: { xs: "250px", md: "350px" },
            maxHeight: { xs: "250px", md: "300px" },
            height: { xs: "auto", md: "150px" },
          }}
          src={imageSrc}
          alt={altText}
          crossOrigin="anonymous"
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            textAlign="center"
            fontSize="1.2rem"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {title}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SummaryCard;
