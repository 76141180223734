import Meta from "./../components/Meta";
import UserProfile from "../components/UserProfile";

function UserProfilePage(props) {
  return (
    <>
      <Meta
        title="User Profile"
        description="Profile for a specific Neurorobotics Studio user"
      />
      <UserProfile />
    </>
  );
}

export default UserProfilePage;
