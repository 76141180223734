import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useAuth } from "../../util/auth";
import CustomSnackbar from "../../util/CustomSnackbar";
import ShutDownButton from "../../util/ShutDownButton";
import {
  getEmbodimentDefaultPlayObject,
  getEmbodiments,
} from "../../api/embodimentManagement";
import {
  getLaunchCriteria,
  isSystemOverloaded,
} from "../../api/experimentManagement";
import PageLoader from "../PageLoader";
import PlayIcons from "./PlayIcons";
import PlayEmbodimentModal from "./PlayEmbodimentModal";

const PlaySelect = () => {
  const auth = useAuth();
  const accessToken = auth?.user?.accessToken;
  const [tokenChecked, setTokenChecked] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({
    text: "",
    severity: "info",
  });
  const [disabled, setDisabled] = useState({ type: null, message: null });
  const [embodiments, setEmbodiments] = useState([]);
  const [defaultPlayObj, setDefaultConfig] = useState({});
  const [selectedEmbodiment, setSelectedEmbodiment] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setTokenChecked(true);
    }, "1000");
  }, []);

  // Fetch embodiments & launch criteria on load
  useEffect(() => {
    async function callFetchEmbodiments() {
      try {
        const res = await getEmbodiments({
          accessToken,
          limit: 30,
          playable:
            process.env.REACT_APP_NODE_ENV === "production" ? true : null,
        });

        if (!res?.data) throw new Error("Did not receive data from server");

        setEmbodiments(res.data);

        const defaultObjRes = await getEmbodimentDefaultPlayObject(accessToken);
        defaultObjRes?.data && setDefaultConfig(defaultObjRes.data); // includes .configuration, .platform, .requirements
      } catch (err) {
        console.error(err);
        setMessage({
          text: "An error occurred fetching the play embodiments. Please reload, or return to the lab via the navbar if issues persist.",
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    }

    async function callGetLaunchCriteria() {
      try {
        if (!accessToken) {
          const res = await isSystemOverloaded();
          if (!res?.data) throw new Error("Did not receive data from server");
          if (res.data.system_overload) {
            setDisabled({
              type: "system_overload",
              message:
                "The system is currently overloaded. Please try again soon.",
            });
          }
        } else {
          const res = await getLaunchCriteria(accessToken);
          if (!res?.data) throw new Error("Did not receive data from server");
          const {
            system_overload,
            // max_experiment_count_reached,
            max_concurrent_experiments_reached,
          } = res.data;

          if (system_overload) {
            setDisabled({
              type: "system_overload",
              message:
                "The system is currently overloaded. Please try again soon.",
            });
          }
          // else if (max_experiment_count_reached) {
          //   setDisabled(
          //     "You have reached the maximum number of experiments. You can delete one in the lab to clear up Play space."
          //   );
          // }
          else if (max_concurrent_experiments_reached) {
            setDisabled({
              type: "max_concurrent_experiments_reached",
              message:
                "You already have an experiment or play session running. Would you like to shut it down so you can play?",
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    }

    if (tokenChecked) {
      callFetchEmbodiments();
      if (accessToken) {
        callGetLaunchCriteria();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenChecked]);

  function cardMaker(emb) {
    const playObj = emb.play_obj || defaultPlayObj;

    if (emb.agent_type === "no-agent") return null;

    return (
      <Card
        key={emb.id}
        sx={{
          maxWidth: { xs: "100%", md: "175px" },
          mb: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          cursor: disabled?.type ? "not-allowed" : "pointer",
          pointerEvents: disabled?.type ? "none" : "auto",
          opacity: disabled?.type ? 0.5 : 1,
        }}
        onClick={() => !disabled?.type && handleSelect(emb)}
      >
        {/* Icons */}
        <CardContent
          sx={{
            py: 1,
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 0.5,
          }}
        >
          {playObj?.requirements?.length > 0 ? (
            <PlayIcons items={playObj.requirements} />
          ) : (
            <Box sx={{ height: "24px" }} />
          )}
        </CardContent>

        {/* Image */}
        <CardMedia
          component="img"
          height="100"
          image={emb.image_url}
          alt={emb.embodiment_title}
          sx={{ height: "150px", px: 2, py: 1, objectFit: "contain" }}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Divider
            sx={{ backgroundColor: "background.default", height: "5px" }}
          />

          {/* Title */}
          <Tooltip title={emb.embodiment_title}>
            <CardHeader
              title={emb.embodiment_title}
              titleTypographyProps={{
                sx: {
                  maxWidth: "100%",
                  fontSize: "1.2rem",
                  fontWeight: 500,
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
              sx={{
                py: 1,
                ".MuiCardHeader-content": {
                  width: "100%",
                },
              }}
            />
          </Tooltip>
        </Box>
      </Card>
    );
  }

  function handleSelect(emb) {
    setSelectedEmbodiment(emb);
  }

  if (loading) return <PageLoader />;

  return (
    <Box
      sx={{
        mt: 3,
        px: { xs: 3, md: 6, lg: 24, xl: 48 },
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {/* <SectionHeader title="Plays" size={4} textAlign="center" /> */}
      <Box
        sx={{
          width: "100%",
          padding: "7px",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "5px",
        }}
      >
        <Box sx={{ mb: 3, flex: 1, display: "flex", alignItems: "center" }}>
          <Button
            variant="outlined"
            size="small"
            startIcon={<ArrowBack />}
            title="Return to Lab"
            href="/lab"
            sx={{ maxWidth: "80px" }}
          >
            Lab
          </Button>
        </Box>
        <Typography
          variant="h4"
          sx={{
            flex: 2,
            textAlign: "center",
          }}
        >
          Plays
        </Typography>
        <Box sx={{ flex: 1 }}></Box>
      </Box>
      {disabled?.type && (
        <Box
          sx={{
            width: "100%",
            my: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Alert severity="warning">{disabled.message}</Alert>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
            }}
          >
            {disabled.type === "max_concurrent_experiments_reached" ? (
              <ShutDownButton
                accessToken={accessToken}
                setMessage={setMessage}
                successAction={() => {
                  setDisabled({
                    type: "",
                    message: "",
                  });
                  setMessage({
                    text: "Your existing experiment(s) have been successfully shut down. You can start a new play.",
                    severity: "success",
                  });
                }}
              />
            ) : (
              <Button variant="contained" onClick={() => navigate(`/lab`)}>
                Return to Lab
              </Button>
            )}
          </Box>
        </Box>
      )}

      {embodiments?.length > 0 && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(auto-fit, minmax(125px, auto))",
              md: "repeat(auto-fit, minmax(175px, auto))",
            },
            justifyContent: "start",
            gap: 3,
          }}
        >
          {embodiments.map((emb) => {
            const isPhysical =
              emb.agent_type === "nrs-bt-device" ||
              emb.agent_type === "user-ws-device";
            if (
              isPhysical &&
              (!Array.isArray(emb.personalities) ||
                emb.personalities.length < 1)
            ) {
              return null;
            }
            return cardMaker(emb);
          })}
        </Box>
      )}

      {/* Embodiment modal */}
      {selectedEmbodiment && (
        <PlayEmbodimentModal
          accessToken={accessToken}
          embodiment={selectedEmbodiment}
          handleClose={() => setSelectedEmbodiment(null)}
          shouldNavigate={true}
        />
      )}

      {/* Info / Error message */}
      {message && (
        <CustomSnackbar
          message={message.text}
          setMessage={setMessage}
          autoHide={12000}
          severity={message.severity}
        />
      )}
    </Box>
  );
};

export default PlaySelect;
