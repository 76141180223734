import { useParams } from "react-router-dom";
import Meta from "./../components/Meta";
import Book from "../components/Book";
import NotFoundPage from "./404";
import { getTutorialData } from "../api/tutorials";
import { getDemosData } from "../api/demos";

function BookPage() {
  const { type } = useParams();

  let fetchData;
  if (type === "tutorials") {
    fetchData = getTutorialData;
  } else if (type === "demos") {
    fetchData = getDemosData;
  } else {
    console.error("Invalid type or missing type in URL:", type);
    return <NotFoundPage />;
  }

  return (
    <>
      <Meta title={`NRS ${type === "tutorials" ? "Tutorials" : "Demos"}`} />
      <Book fetchData={fetchData} header={type} />
    </>
  );
}

export default BookPage;
