// import aauoxsin from "../../img/em-aauoxsin.png"; // robot arm
// import kxrhxvtv from "../../img/em-bsfuiref.png"; // cutebot microbit
// import kbxamajf from "../../img/em-kbxamajf.png"; // freenove
// import nbnhzdqc from "../../img/em-nbnhzdqc.png"; // drone
// import dgocpqka from "../../img/em-zkmemyyd.png"; // pong

// const imageMap = {
//   aauoxsin: aauoxsin,
//   kxrhxvtv: kxrhxvtv,
//   kbxamajf: kbxamajf,
//   nbnhzdqc: nbnhzdqc,
//   dgocpqka: dgocpqka,
// };

import { forwardRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  ReportProblemOutlined,
} from "@mui/icons-material";
import { useAuth } from "../../util/auth";
import isValidUrl from "../../util/isValidUrl";
import { logger } from "../../util/logger";
import GetOne from "../GetOne";

const NewExperimentEmbodimentCard = forwardRef(
  ({ row, onComplete, collectedData, setCollectedData, browserInfo }, ref) => {
    const auth = useAuth();
    const [showMore, setShowMore] = useState(false);

    function handleSelect(row) {
      logger("selected ", row);
      if (setCollectedData) {
        setCollectedData({
          ...collectedData,
          embodimentId: row.embodiment_id,
          embodimentTitle: row.embodiment_title,
          embodimentUrl: row.image_url,
          embodimentGenomeId: row.barebones_genome_id,
        });
      }
      onComplete(row.embodiment_id, row.embodiment_title);
    }

    return (
      <Card
        ref={ref ? ref : undefined}
        sx={{
          width: "100%",
          "&:hover .hoverButton": {
            opacity: 1,
          },
        }}
      >
        <CardContent
          sx={{
            width: "100%",
            pt: "16px",
            pb: "16px !important",
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          {/* Left Column */}
          <Box display="flex" flexDirection="column" sx={{ width: "70%" }}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                overflow="hidden"
                width="80%"
              >
                {/* Title */}
                <Typography
                  fontSize="20px"
                  sx={{
                    marginBottom: "10px",
                    ...(!showMore
                      ? {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                        }
                      : {}),
                  }}
                >
                  <strong>{row.embodiment_title}</strong>{" "}
                  {row.manufacturer && (
                    <Typography
                      variant="span"
                      sx={{ color: "accents.light", fontSize: ".9rem" }}
                    >
                      by {row.manufacturer}
                    </Typography>
                  )}
                </Typography>
                {/* Environment */}
                {row.environment_type && (
                  <Typography sx={{ marginBottom: "10px" }}>
                    <span
                      sx={{
                        ...(!showMore
                          ? {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "100%",
                            }
                          : {}),
                      }}
                    >
                      <strong>Environment:</strong>
                    </span>{" "}
                    {row.environment_type}
                  </Typography>
                )}
                {/* Species */}
                {row.species && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    gap="50px"
                  >
                    <Typography
                      sx={{
                        marginBottom: "10px",
                        width: "100%",
                        ...(!showMore
                          ? {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "100%",
                            }
                          : {}),
                      }}
                    >
                      <strong>Species:</strong> {row.species}
                    </Typography>
                  </Box>
                )}
                {/* Feature tags */}
                {row.tags.length > 0 && (
                  <Box display="flex" gap="5px" width="100%">
                    <Typography sx={{ marginBottom: "10px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          width: "100%",
                          flexWrap: "wrap",
                          maxHeight: !showMore ? "35px" : "none",
                          overflow: !showMore ? "hidden" : "visible",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "700",
                            minHeight: { xs: "35px", md: "0" },
                          }}
                        >
                          Features:
                        </Typography>
                        {row.tags.map((tag, index) => {
                          if (!tag.trim().length) return null;
                          return (
                            <>
                              <Chip
                                key={index}
                                label={tag}
                                sx={{
                                  "&.MuiChip-root": {
                                    maxWidth: "100%",
                                    borderRadius: "5px",
                                    backgroundColor: "accents.dark",
                                    "& .MuiChip-label": {
                                      color: "white",
                                      fontSize: {
                                        xs: "0.7rem",
                                        md: "0.8125rem",
                                      },
                                      fontWeight: "bold",
                                      whiteSpace: "normal",
                                    },
                                  },
                                }}
                              ></Chip>
                            </>
                          );
                        })}
                      </Box>
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            {/* Description */}
            {row.description && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  sx={
                    showMore
                      ? {}
                      : {
                          display: "block",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                        }
                  }
                >
                  <strong>Description:</strong> {row.description}
                </Typography>
              </Box>
            )}
          </Box>

          {/* Right Column */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-end",
              width: "30%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                alignItems: { xs: "flex-end", md: "center" },
              }}
            >
              <Box
                className="hoverButton"
                sx={{
                  opacity: { xs: 1, sm: 0 },
                  transition: "opacity 0.1s ease",
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: "10px",
                }}
              >
                {/* Bluetooth unsupported warning */}
                {row.agent_type === "nrs-bt-device" &&
                  !browserInfo?.isMobile &&
                  (browserInfo?.browser === "Safari" ||
                    browserInfo?.browser === "Firefox") && (
                    <Tooltip title="Your browser does not support Bluetooth, which this embodiment requires. Please use Chrome">
                      <ReportProblemOutlined
                        color="warning"
                        sx={{
                          opacity: "0.4",
                          fontSize: { xs: "1.8rem", md: "2.1875rem" },
                        }}
                      />
                    </Tooltip>
                  )}
                {/* External product button */}
                {row.product_url && isValidUrl(row.product_url) && (
                  <GetOne
                    accessToken={auth.user?.accessToken}
                    embodiment={row}
                  />
                )}
                {/* Choose button */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: { xs: "flex-end", md: "center" },
                    gap: onComplete ? 2 : 0,
                  }}
                >
                  {onComplete && (
                    <Button
                      variant="contained"
                      onClick={() => handleSelect(row)}
                      sx={{
                        padding: { xs: "4px 8px", md: "6px 16px" },
                        minWidth: { xs: "100%", md: "75px" },
                        fontSize: { xs: ".65rem", md: ".875rem" },
                        fontWeight: "600",
                      }}
                    >
                      Choose
                    </Button>
                  )}
                </Box>
              </Box>
              {/* Embodiment image */}
              <Box
                sx={{
                  alignSelf: "flex-end",
                  width: "120px",
                  height: "70px",
                  display: "flex",
                  justifyContent: { xs: "flex-end", md: "center" },
                }}
              >
                {row.image_url && (
                  <img
                    src={row.image_url}
                    alt={row.embodiment_title}
                    crossOrigin="anonymous"
                    style={{
                      width: "100%",
                      maxWidth: "95px",
                      height: "auto",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                  />
                )}
              </Box>
            </Box>
            {/* Show more button */}
            {!showMore ? (
              <Box display="flex" justifyContent="flex-end">
                <Tooltip title="Show more embodiment details">
                  <Button
                    variant="text"
                    sx={{
                      minWidth: 0,
                      padding: 0,
                      fontSize: "1rem",
                      textTransform: "none",
                      color: "accents.main",
                    }}
                    onClick={() => setShowMore((prev) => !prev)}
                  >
                    <ExpandMoreIcon />
                  </Button>
                </Tooltip>
              </Box>
            ) : (
              // Show less button
              <Box display="flex" justifyContent="end">
                <Tooltip title="See less embodiment details">
                  <Button
                    sx={{
                      minWidth: 0,
                      padding: 0,
                      fontSize: "1rem",
                      textTransform: "none",
                      color: "accents.main",
                    }}
                    onClick={() => setShowMore((prev) => !prev)}
                  >
                    <ExpandLessIcon />
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    );
  }
);

export default NewExperimentEmbodimentCard;
