export default function formatFilename(input) {
  let formatted = input
    // Convert to lowercase
    .toLowerCase()
    // Remove .json from the end
    .replace(/\.json$/, "");

  // Find the first dash and temporarily replace it with a unique placeholder (keeping "genome-" that composer adds to all beginning names)
  const firstDashIndex = formatted.indexOf("-");
  if (firstDashIndex !== -1) {
    formatted =
      formatted.substring(0, firstDashIndex) +
      "§§§" +
      formatted.substring(firstDashIndex + 1);
  }

  // Replace spaces, dots, and remaining dashes with underscores
  formatted = formatted.replace(/\s+|\./g, "_").replace(/-/g, "_");

  // Restore the first dash from the placeholder
  formatted = formatted.replace("§§§", "-");

  // Remove all non-acceptable filename characters
  formatted = formatted
    .replace(/[\\/:*?"<>|\r\n]+/g, "")
    // Remove leading or trailing underscores which might be left after cleaning
    .replace(/^_+|_+$/g, "");

  // Truncate to avoid OS filename length restrictions
  return formatted.substring(0, 255);
}
