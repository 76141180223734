let badgeUpdater = null;

// Make the React state setter available to all files
export const setBadgeUpdater = (updater) => {
  badgeUpdater = updater;
};

// Set a rec'd badge so it will show in popup
export const updateBadges = (badges) => {
  badgeUpdater && badgeUpdater(badges);
};
