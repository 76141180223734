import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import BadgePopup from "./BadgePopup";

const BadgeHandler = ({ badges, setBadges }) => {
  const [visibleBadges, setVisibleBadges] = useState(badges);

  useEffect(() => {
    if (visibleBadges.length < 1) {
      setBadges(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleBadges]);

  const handleBadgeClose = (badgeToClose) => {
    setVisibleBadges((prevBadges) =>
      prevBadges.filter((badge) => badge !== badgeToClose)
    );
  };

  return (
    <Box sx={{ position: "relative" }}>
      {visibleBadges.map((badge, index) => (
        <BadgePopup
          key={index}
          open={true}
          onClose={() => handleBadgeClose(badge)}
          badge={badge}
        />
      ))}
    </Box>
  );
};

export default BadgeHandler;
