// EXAMPLE USAGE
// const regions = [
//   [0, 2],
//   [2, 0],
//   [1, 1],
// ];
// regionsToCommands(regions)
// regionsToCommands returns { motion_control: { '0': { move_up: 1, move_left: 1, move_down: 1, move_right: 1 } }}

// The regions are:
// [0, 2], [1, 2], [2, 2]
// [0, 1], [1, 1], [2, 1]
// [0, 0], [1, 0], [2, 0]

function regionsToCommands(regions, value = 1) {
  const motionObj = { motion_control: { 0: {} } };
  const motorObj = { motor: { 0: null, 1: null } };
  let hasMotion = false;
  let hasMotor = false;

  for (const [x, y] of regions) {
    // Top row
    if (y === 2) {
      motionObj.motion_control[0].move_up = value;
      motorObj.motor[0] = value;
      hasMotion = hasMotor = true;
    }

    // Bottom row
    if (y === 0) {
      motionObj.motion_control[0].move_down = value;
      motorObj.motor[1] = value;
      hasMotion = hasMotor = true;
    }

    // Left column
    if (x === 0) {
      motionObj.motion_control[0].move_left = value;
      hasMotion = true;
    }

    // Right column
    if (x === 2) {
      motionObj.motion_control[0].move_right = value;
      hasMotion = true;
    }
  }

  if (hasMotion || hasMotor) {
    const controls = {};
    if (hasMotion) controls.motion_control = motionObj.motion_control;
    if (hasMotor) controls.motor = motorObj.motor;
    return controls;
  } else {
    return null;
  }
}

export default regionsToCommands;
