import { useNavigate, useLocation } from "react-router-dom";

const useParamsUpdater = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const updateQueryParams = (newParams, replace = true) => {
    const searchParams = new URLSearchParams(location.search);

    // Add or replace query params
    Object.keys(newParams).forEach((key) => {
      if (replace) {
        searchParams.set(key, newParams[key]);
      } else {
        searchParams.append(key, newParams[key]);
      }
    });

    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: replace,
    });
  };

  return updateQueryParams;
};

export default useParamsUpdater;
