export default async function getBrowserInfoForDb() {
  const userAgent = navigator.userAgent;
  const highEntropyVals =
    await window.navigator?.userAgentData?.getHighEntropyValues([
      "architecture",
    ]);

  return {
    userAgent,
    highEntropyVals,
  };
}
