import { styled } from "@mui/material/styles";
import { Button, IconButton, Popover } from "@mui/material";

export const StyledPopover = styled(Popover)({
  "& .MuiPopover-paper": {
    minWidth: "300px",
    maxWidth: "500px",
    padding: "15px",
  },
});

export const StyledButton = styled(Button)({
  color: "white",
  fontWeight: "bold",
});

export const StyledIconButton = styled(IconButton)({
  padding: "4px",
  fontSize: "1rem",
});
