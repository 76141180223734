import { useState } from "react";
import { Box, Container, Link, Typography } from "@mui/material";
import NewExperimentGenome from "./new-experiment/NewExperimentGenome";
import SectionHeader from "./SectionHeader";
import CustomSnackbar from "../util/CustomSnackbar";
import { OpenInNew } from "@mui/icons-material";
// import FeaturedGenomes from "./FeaturedGenomes";

const Hub = () => {
  const [snackbar, setSnackbar] = useState({ message: "", severity: "" });

  const customSetError = (message) => {
    setSnackbar({ message, severity: "error" });
  };

  return (
    <Container sx={{ maxWidth: "90vw", height: "100%", mt: 2 }}>
      <SectionHeader
        title="Download Brains for Robots"
        superheader="FEAGI Hub"
        size={4}
        textAlign="center"
        alignSelf="center"
        bgImage="linear-gradient(to right, #5b008e, #00868b)"
      />
      <Box sx={{ mt: 2, mb: 5 }}>
        <Typography sx={{ fontWeight: 500 }}>
          Download and share artificial genomes with the community. Visit the{" "}
          <Link
            href="https://github.com/feagi/feagi"
            target="_blank"
            rel="noopener noreferrer"
          >
            FEAGI Github repo
            <OpenInNew
              fontSize="small"
              sx={{ mr: "5px", fontSize: "0.9rem" }}
            />
          </Link>
          to access the open-source project behind Neurorobotics Studio.
        </Typography>
      </Box>

      {/* Featured */}
      {/* <Box
        sx={{
          mt: 2,
          mb: 4,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: { xs: "center", sm: "flex-start" },
          gap: 2,
        }}
      >
        <Typography
          variant="h3"
          sx={{ fontSize: { xs: "1.3rem", md: "1.7rem" }, fontWeight: 500 }}
        >
          Featured Genomes
        </Typography>
        <FeaturedGenomes setSnackbar={setSnackbar} />
      </Box> */}

      {/* All */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: { xs: "center", sm: "flex-start" },
          gap: 2,
        }}
      >
        <Typography
          variant="h3"
          sx={{ fontSize: { xs: "1.3rem", md: "1.7rem" }, fontWeight: 500 }}
        >
          Browse All Genomes
        </Typography>
        <NewExperimentGenome setError={customSetError} isHub={true} />
      </Box>

      {/* Snackbar */}
      {snackbar.message && (
        <CustomSnackbar
          message={snackbar.message}
          setMessage={setSnackbar}
          severity={snackbar.severity}
        />
      )}
    </Container>
  );
};

export default Hub;
