import { useParams } from "react-router-dom";
import Meta from "./../components/Meta";
import SettingsSection from "./../components/settings/SettingsSection";
import { requireAuth } from "./../util/auth";

function SettingsPage(props) {
  const { section } = useParams();

  return (
    <>
      <Meta title="Settings" />
      <SettingsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        section={section}
        key={section}
      />
    </>
  );
}

export default requireAuth(SettingsPage);
