// Generic dialog component. Children represents custom actions

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useState } from "react";

const CustomDialog = ({
  header,
  text,
  richText,
  buttonText,
  isOpen,
  handleClose,
  confirmAction,
  cancelAction,
  actionsChildren,
  maxWidth = "sm",
}) => {
  const [loading, setLoading] = useState(false);

  async function handleConfirm() {
    setLoading(true);
    await confirmAction(); // expected to handle dialog closure
    setLoading(false);
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
    >
      {/* Title & close button */}
      <DialogTitle id="alert-dialog-title">
        <Box
          sx={{
            display: "flex",
            justifyContent: header ? "space-between" : "flex-end",
            alignItems: "center",
            gap: 1,
          }}
        >
          {header ? (
            <Typography variant="h6" sx={{ fontSize: "1.4rem" }}>
              {header}
            </Typography>
          ) : null}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      {/* Description text */}
      {(text || richText) && (
        <DialogContent sx={{ pt: 1 }}>
          {text && (
            <DialogContentText id="alert-dialog-description">
              {text}
            </DialogContentText>
          )}
          {richText}
        </DialogContent>
      )}

      {/* Buttons */}
      {(cancelAction || confirmAction || actionsChildren) && (
        <DialogActions sx={{ padding: "8px 20px 16px" }}>
          {actionsChildren}
          {(cancelAction || confirmAction) && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent:
                  cancelAction && confirmAction ? "space-between" : "flex-end",
              }}
            >
              {cancelAction && (
                <Button
                  onClick={cancelAction}
                  variant="outlined"
                  disabled={loading}
                >
                  Cancel
                </Button>
              )}
              {confirmAction && (
                <Button
                  onClick={handleConfirm}
                  variant="contained"
                  disabled={loading}
                  autoFocus
                >
                  {loading ? (
                    <CircularProgress size={18} />
                  ) : buttonText ? (
                    buttonText
                  ) : (
                    "Proceed"
                  )}
                </Button>
              )}
            </Box>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CustomDialog;
