import { useState } from "react";
// import { useAuth } from "../../util/auth";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Cancel as CancelIcon, Speed as SpeedIcon } from "@mui/icons-material";

const ExperimentCardMemory = ({ isEditing, roles }) => {
  // const { user } = useAuth();
  // const isPro = user?.roles.includes("pro-user");
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* Button */}
      <Tooltip title="Change allocated memory for this experiment [PRO]">
        <span>
          <IconButton
            // sx={{
            //   padding: 0,
            //   "&& .MuiIconButton-root": {
            //     padding: "4px",
            //     fontSize: "1rem",
            //   },
            //   "&& .MuiButtonBase-root": {
            //     padding: "4px",
            //   },
            // }}
            onClick={() => setOpen(true)}
            // disabled={!isPro}
            disabled={
              isEditing ||
              (!roles?.includes("pro-user") &&
                !roles?.includes("ultimate-user"))
            }
          >
            <SpeedIcon />
          </IconButton>
        </span>
      </Tooltip>

      {/* Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog"
      >
        <DialogTitle
          id="dialog-title"
          sx={{ paddingBottom: "5px", backgroundColor: "background.default" }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {"Change Memory Tier"}
            <IconButton size="small" onClick={handleClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          <Box component="hr" sx={{ borderColor: "background.light" }} />
        </DialogTitle>
        <DialogContent
          sx={{
            minWidth: "450px",
            // paddingTop: "10px !important",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            backgroundColor: "background.default",
          }}
        >
          <Typography>Change this experiment's memory capacity.</Typography>
          <Box display="flex" flexDirection="column" gap="10px">
            <Card>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Turbo
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Memory capacity of ten million gajillion.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
            <Card>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Ludicrous
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Memory capacity of ten billion million gajillion.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Box>
          <Button
            variant="outlined"
            sx={{ alignSelf: "flex-end" }}
            onClick={() =>
              console.log(
                `Selection: ${true}. Need to call backend to freeze & close dialog on success.`
              )
            }
            // disabled={user.role !== "pro"}
          >
            Confirm
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ExperimentCardMemory;
