import { useState, useRef, useEffect } from "react";
import { Box, IconButton } from "@mui/material";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";

export default function ImageCarousel({ cards, handleSelect }) {
  const scrollRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  //   const [showRightArrow, setShowRightArrow] = useState(true);

  const scrollTo = (direction) => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      const maxScroll = scrollWidth - clientWidth;

      if (direction === "right") {
        if (scrollLeft >= maxScroll) {
          // If at the end, scroll back to the start
          scrollRef.current.scrollTo({ left: 0, behavior: "smooth" });
        } else {
          scrollRef.current.scrollBy({ left: clientWidth, behavior: "smooth" });
        }
      } else {
        scrollRef.current.scrollBy({ left: -clientWidth, behavior: "smooth" });
      }
    }
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollLeft } = scrollRef.current;
      //   const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      //   setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 5);
      setShowLeftArrow(scrollLeft > 0);
    }
  };

  useEffect(() => {
    const currentRef = scrollRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
      return () => currentRef.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <IconButton
        onClick={() => scrollTo("left")}
        sx={{
          flexShrink: 0,
          mr: 1,
          pl: "-10px",
          opacity: showLeftArrow ? 1 : 0.5,
        }}
        disabled={!showLeftArrow}
      >
        <ArrowCircleLeft />
      </IconButton>

      <Box sx={{ flex: 1, overflow: "hidden" }}>
        <Box
          ref={scrollRef}
          sx={{
            display: "flex",
            gap: 3,
            overflowX: "auto",
            scrollSnapType: "x mandatory",
            "&::-webkit-scrollbar": { display: "none" },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {cards.map((card) => (
            <Box
              key={card.img_url}
              sx={{
                flexShrink: 0,
                width: "95px",
                height: "100%",
                scrollSnapAlign: "start",
                cursor: "pointer",
                "&:hover": { opacity: 0.7 },
              }}
              onClick={() => handleSelect(card)}
            >
              <img
                src={card.img_url}
                alt={card.img_alt_text || "Avatar image"}
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "150px",
                  objectFit: "contain",
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <IconButton
        onClick={() => scrollTo("right")}
        sx={{ flexShrink: 0, ml: 1, pr: "-10px" }}
        // disabled={!showRightArrow}
      >
        <ArrowCircleRight />
      </IconButton>
    </Box>
  );
}
