import { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs"; // Import dayjs library for date parsing

export default function CustomDateRangePicker({
  initialDates,
  onDateRangeChange,
}) {
  // Parse the initialDates string into an array with startDate and endDate
  const initialDateRange =
    typeof initialDates === "string" ? initialDates.split(" - ") : [null, null];
  const [startDate, setStartDate] = useState(initialDateRange[0]);
  const [endDate, setEndDate] = useState(initialDateRange[1]);

  // Update state when initialDates changes (in case it's not loaded immediately)
  useEffect(() => {
    const initialDateRange =
      typeof initialDates === "string"
        ? initialDates.split(" - ")
        : [null, null];
    setStartDate(initialDateRange[0]);
    setEndDate(initialDateRange[1]);
  }, [initialDates]);

  // Set start date on user selection
  const handleStartDateChange = (date) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    if (endDate && formattedDate > endDate) {
      setStartDate(null);
    } else {
      setStartDate(formattedDate);
      if (onDateRangeChange) {
        onDateRangeChange([formattedDate, endDate]);
      }
    }
  };

  // Set end date on user selection
  const handleEndDateChange = (date) => {
    // Convert date object to string in YYYY-MM-DD format
    let formattedDate = date ? date.format("YYYY-MM-DD") : null;
    // Nullify selection if end date is earlier than start date
    if (startDate && formattedDate < startDate) formattedDate = null;
    // Set end date
    setEndDate(formattedDate);
    if (onDateRangeChange) {
      onDateRangeChange([startDate, formattedDate]);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "6px 10px",
          marginTop: "10px",
        }}
      >
        <DatePicker
          label="Start Date"
          value={startDate ? dayjs(startDate) : null}
          onChange={handleStartDateChange}
          renderInput={(props) => <TextField {...props} />}
        />
        <DatePicker
          label="End Date"
          value={endDate ? dayjs(endDate) : null}
          onChange={handleEndDateChange}
          renderInput={(props) => <TextField {...props} />}
        />
      </Box>
    </LocalizationProvider>
  );
}
