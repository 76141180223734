import { useRef, useEffect, useState } from "react";
import { Box } from "@mui/material";

const SlideUpBox = ({
  children,
  startDelay = 0,
  transitionSpeed = "0.5s",
  style,
  animationType = "slideUp",
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        // setIsVisible is now called every time the entry intersects, allowing for repeated animations
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1, // When to trigger animation
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const animationStyles = {
    opacity: isVisible ? 1 : 0,
    transform:
      animationType === "slideUp"
        ? isVisible
          ? "translateY(0px)"
          : "translateY(20px)"
        : "translateY(0px)",
    transition: `opacity ${transitionSpeed} ease-out${
      animationType === "slideUp"
        ? `, transform ${transitionSpeed} ease-out`
        : ""
    }`,
    transitionDelay: `${startDelay}s`,
    ...style,
  };

  return (
    <Box ref={ref} sx={animationStyles}>
      {children}
    </Box>
  );
};

export default SlideUpBox;
