import React, { useEffect, useState } from "react";
import { ArrowBack, Info } from "@mui/icons-material";
import {
  Box,
  Button,
  // CircularProgress,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PlayBehaviors from "./PlayBehaviors";
import PlayPersonality from "./PlayPersonality";
import PlayVirtualGames from "./PlayVirtualGames";
import PlayWebcam from "./PlayWebcam";
import usePostMessage from "./usePostMessage";
import CustomDialog from "../CustomDialog";
import DisplayMarkdown from "../../util/DisplayMarkdown";
import SignupModal from "../../util/SignupModal";
import PlayFeagiLoading from "./PlayFeagiLoading";
import MagicLink from "../MagicLink";
import brain from "../../img/brain.png";
import connection from "../../img/connection.png";
import { getAgents } from "../../api/feagiAgent";
import PlayEmbodimentModal from "./PlayEmbodimentModal";

const PlayConnected = ({
  accessToken,
  setSnackbar,
  queryParameters,
  sessionId,
  setSessionId,
  clusterId,
  setClusterId,
  embodiment,
  embodimentId,
  embodimentType,
  config,
  hasInstructions,
  setInstructionsOpen,
  setLeavePlayPath,
  physicalConnected,
  setPhysicalConnected,
  setIsBluetoothOpen,
}) => {
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down("sm"));
  // @ts-ignore
  const isAbove2XL = useMediaQuery(theme.breakpoints.up("2xl"));
  const isFromBV = queryParameters.get("ref") === "brain-visualizer";
  const isPhysical =
    embodimentType === "nrs-bt-device" || embodimentType === "user-ws-device";
  const [connectInstructionsOpen, setConnectInstructionsOpen] = useState(false);
  const [personality, setPersonality] = useState({});
  const [sessionOpen, setSessionOpen] = useState(false);
  const [sessionLoading, setSessionLoading] = useState(false);
  const [signupModalOpen, setSignupModalOpen] = useState(false);
  const [embodimentInfoOpen, setEmbodimentInfoOpen] = useState(false);
  const [link, setLink] = useState("");

  // Postmessage
  const [sendMessage, setSendMessage] = useState(null); // send messages to iframe
  usePostMessage(isPhysical, embodimentId, setSendMessage); // set up window listener/sender

  // FEAGI connection/session
  useEffect(() => {
    if (sessionId && clusterId) {
      setSessionOpen(true);
    }
  }, [sessionId, clusterId]);

  // const connectFeagi = () => {
  //   if (accessToken) {
  //     setSessionLoading(true);
  //   } else {
  //     setSignupModalOpen(true);
  //   }
  // };

  // Open FEAGI session & trigger WS connection (via adding session/cluster params) once physical robot connects
  useEffect(() => {
    if (physicalConnected && accessToken && !sessionOpen) {
      setSessionLoading(true);
    }
  }, [accessToken, physicalConnected, sessionOpen]);

  useEffect(() => {
    if (embodiment?.personalities?.[0]) {
      setPersonality(embodiment.personalities[0]);
    }
  }, [embodiment, isPhysical]);

  const handlePlaysClick = () => {
    if (embodimentId) {
      setLeavePlayPath("/play");
    } else {
      window.location.href = "/play";
    }
  };

  const handleSessionClick = () => {
    if (!accessToken) {
      setSignupModalOpen(true);
    } else {
      setSessionLoading(true);
    }
  };

  const handleConnectClick = () => {
    if (!accessToken) {
      setSignupModalOpen(true);
    } else {
      if (embodimentType === "nrs-bt-device") {
        if (physicalConnected) {
          setPhysicalConnected(false);
          // setIsMicrobitOpen(false);
        } else {
          setIsBluetoothOpen(true);
        }
      } else if (embodimentType === "user-ws-device") {
        setInstructionsOpen(true);
      }
    }
  };

  const handleConnectClaim = async () => {
    try {
      setSnackbar({ message: "", severity: "info" });
      const res = await getAgents(sessionId, clusterId);
      if (res?.data?.includes(embodiment.controller_id)) {
        setPhysicalConnected(true);
      } else {
        setSnackbar({
          message:
            "It seems your robot is not connected. Please review the instructions and retry.",
          severity: "error",
        });
      }
    } catch (err) {
      console.error(err);
      setSnackbar({
        message:
          "Sorry, there was an error confirming the connection. Please reload if issues persists.",
        severity: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        px: { xs: 3, md: 4, lg: 10, xl: 24 },
        py: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      {/* 📦 TOP CONTAINER (back to plays, title/how to play, connect/mute) 📦 */}
      <Box
        sx={{
          width: "100%",
          mb: isSmOrSmaller ? 0 : 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "center", md: "flex-start" },
          gap: 2,
        }}
      >
        {/* back to Plays */}
        <Box sx={{ flex: 1 }}>
          {!isFromBV && (
            <Button
              variant="outlined"
              size="small"
              startIcon={<ArrowBack />}
              title="Return to Play Select"
              onClick={handlePlaysClick}
            >
              Plays
            </Button>
          )}
        </Box>

        {/* Play notes button OR title */}
        {isPhysical && (
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setInstructionsOpen(true)}
              disabled={!hasInstructions}
              title={
                hasInstructions
                  ? "View play instructions"
                  : "Instructions unavailable"
              }
            >
              Help
            </Button>
          </Box>
        )}
        {!isPhysical && !isSmOrSmaller && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <Typography
              sx={{
                flex: 2,
                fontSize: "1.2rem",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              {embodiment.embodiment_title}
            </Typography>
            <IconButton
              size="small"
              onClick={() => setInstructionsOpen(true)}
              disabled={!hasInstructions}
            >
              <Info />
            </IconButton>
          </Box>
        )}

        {/* AI Activated button OR mute */}
        <Box
          sx={{
            flex: 1,
            // order: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <Box
            sx={{
              py: 0.5,
              px: 1,
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              gap: 1,
              backgroundColor: "background.dark",
            }}
          >
            {/* <EmojiObjects
              sx={{
                color: sessionOpen ? "primary.main" : "accents.main",
              }}
            /> */}
            <img
              src={brain}
              alt="brain"
              style={{
                width: "100%",
                maxWidth: "20px",
                height: "100%",
                maxHeight: "20px",
                objectFit: "contain",
                opacity: sessionOpen ? 1 : 0.3,
              }}
              crossOrigin="anonymous"
            />
            <Typography
              sx={{
                fontSize: "0.8125rem",
                fontWeight: "500",
                textTransform: "uppercase",
                color: sessionOpen ? "primary.main" : "accents.main",
              }}
            >
              {sessionLoading
                ? "Awakening Brain..."
                : sessionOpen
                ? "Brain Active"
                : "Brain Asleep"}
            </Typography>
          </Box>
          {/* {isPhysical ? (
            <Button
              size="small"
              variant="outlined"
              onClick={connectFeagi}
              disabled={sessionOpen || sessionLoading}
            >
              {sessionLoading ? (
                <CircularProgress size={20} />
              ) : sessionOpen ? (
                "AI Activated!"
              ) : (
                "Activate AI"
              )}
            </Button>
          ) : (
            <>
              <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={toggleMute} sx={{ color: "primary.main" }}>
              {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </IconButton>
          </Box> 
            </>
          )} */}
        </Box>
      </Box>

      {/* 📦 TOP-MIDDLE CONTAINER - Virtual title on small screens */}
      {!isPhysical && isSmOrSmaller && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <Typography
            sx={{
              flex: 2,
              fontSize: "1.2rem",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {embodiment.embodiment_title}
          </Typography>
          <IconButton
            size="small"
            onClick={() => setInstructionsOpen(true)}
            disabled={!hasInstructions}
          >
            <Info />
          </IconButton>
        </Box>
      )}

      {/* 📦 MIDDLE CONTAINER (robot image & title OR virtual P1-P2, gesture monitor) 📦 */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: {
            xs: isPhysical ? "column" : "column-reverse",
            md: "row-reverse",
          },
          justifyContent: "center",
          gap: 4,
        }}
      >
        {/* Title & image OR virtual games */}
        {isPhysical ? (
          <Box
            sx={{
              width: "100%",
              mt: 2,
              mb: 3,
              padding: { xs: "15px", sm: 0 },
              display: "flex",
              flexDirection: "column",
              gap: 3,
              borderRadius: "5px",
              backgroundColor: { xs: "background.dark", sm: "transparent" },
            }}
          >
            {/* Title */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: "600",
                  }}
                >
                  {embodiment.embodiment_title}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => setEmbodimentInfoOpen(true)}
                >
                  <Info />
                </IconButton>
              </Box>
              {embodiment.manufacturer && (
                <Typography>by {embodiment.manufacturer}</Typography>
              )}
            </Box>
            {/* Image & connect and info buttons */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: { xs: 1, md: 2 },
              }}
            >
              {/* Image */}
              {embodiment.image_url && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    crossOrigin="anonymous"
                    src={embodiment.image_url}
                    alt={embodiment.embodiment_title}
                    style={{
                      maxWidth: "150px",
                      maxHeight: "150px",
                      objectFit: "contain",
                      opacity: physicalConnected ? 1 : 0.3,
                    }}
                    crossorigin="anonymous"
                  />
                </Box>
              )}
              {/* Buttons */}
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                {embodimentType === "nrs-bt-device" && (
                  <Button
                    onClick={handleConnectClick}
                    size="small"
                    variant="outlined"
                  >
                    {physicalConnected ? "Disconnect" : "Connect"}
                  </Button>
                )}
                <Button
                  onClick={() => {
                    if (!accessToken) {
                      setSignupModalOpen(true);
                    } else {
                      setConnectInstructionsOpen(true);
                    }
                  }}
                  size="small"
                  variant="outlined"
                >
                  {embodimentType === "nrs-bt-device"
                    ? "How to Connect"
                    : "Connect"}
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          // Virtual games
          <PlayVirtualGames
            config={config}
            embodiment={embodiment}
            clusterId={clusterId}
            setClusterId={setClusterId}
            sessionId={sessionId}
            setSessionId={setSessionId}
            isSmOrSmaller={isSmOrSmaller}
            isAbove2XL={isAbove2XL}
          />
        )}

        {/* Webcam for Virtual */}
        {config?.webcam && !isPhysical && (
          <PlayWebcam
            config={config.webcam}
            setSnackbar={setSnackbar}
            isAbove2XL={isAbove2XL}
            sendMessage={sendMessage}
            sessionId={sessionId}
            clusterId={clusterId}
          />
        )}
      </Box>

      {/* 📦 BOTTOM ELEMENTS (physical only) 📦 */}
      {isPhysical && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 2, md: 4, xl: 8 },
          }}
        >
          {/* Webcam */}
          {config?.webcam && (
            <Box sx={{ mb: { xs: 3, md: 0 }, flex: 1 }}>
              <PlayWebcam
                config={config.webcam}
                setSnackbar={setSnackbar}
                isAbove2XL={undefined}
                sendMessage={sendMessage}
                physicalConnected={physicalConnected}
                // sessionOpen={sessionOpen}
                sessionId={sessionId}
                clusterId={clusterId}
              />
            </Box>
          )}
          <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
            {/* Personality */}
            <PlayPersonality
              personality={personality}
              setPersonality={setPersonality}
              embodiment={embodiment}
              embodimentId={embodimentId}
              accessToken={accessToken}
              setSnackbar={setSnackbar}
              isSessionValid={sessionOpen}
            />
            {/* Behaviors */}
            {Array.isArray(
              // @ts-ignore
              personality?.behaviors
            ) &&
              // @ts-ignore
              personality.behaviors.length > 0 && (
                <PlayBehaviors
                  accessToken={accessToken}
                  setSnackbar={setSnackbar}
                  sessionId={sessionId}
                  embodimentId={embodimentId}
                  // @ts-ignore
                  personalityId={personality.personality_id}
                  // @ts-ignore
                  behaviors={personality.behaviors}
                  physicalConnected={physicalConnected}
                  sessionOpen={sessionOpen}
                />
              )}
          </Box>
        </Box>
      )}

      {/* Signup modal */}
      {signupModalOpen && (
        <SignupModal open={signupModalOpen} setOpen={setSignupModalOpen} />
      )}

      {/* Connection logic */}
      {sessionLoading && (
        <Box sx={{ visibility: "hidden" }}>
          <PlayFeagiLoading
            embodimentId={embodimentId}
            setSessionId={setSessionId}
            setClusterId={setClusterId}
            setSessionOpen={setSessionOpen}
            setSessionLoading={setSessionLoading}
            isAbove2XL={isAbove2XL}
            isSmOrSmaller={isSmOrSmaller}
          />
        </Box>
      )}

      {/* How to Connect modal */}
      {connectInstructionsOpen && (
        <CustomDialog
          isOpen={connectInstructionsOpen}
          handleClose={() => setConnectInstructionsOpen(false)}
          header={`Connecting ${embodiment.embodiment_title}`}
          richText={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              {embodimentType === "user-ws-device" && (
                <Box
                  sx={{
                    mt: "5px",
                    minHeight: "56px",
                    alignSelf: "flex-start",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MagicLink
                    accessToken={accessToken}
                    embodimentId={embodimentId}
                    setError={(message) => {
                      setSnackbar({
                        message: message,
                        severity: "error",
                      });
                    }}
                    link={link}
                    setLink={setLink}
                  />
                </Box>
              )}
              {/* Instructions */}
              <Typography
                component="h3"
                sx={{
                  alignSelf: "flex-start",
                  fontSize: "1.2rem",
                  fontWeight: 500,
                }}
              >
                Instructions
              </Typography>
              <Box
                sx={{
                  px: 2,
                  maxHeight: { xs: "40vh", sm: "45vh" },
                  width: "100%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  backgroundColor: "background.dark",
                  border: "1px solid",
                  borderColor: "accents.dark",
                  borderRadius: "4px",
                }}
              >
                <DisplayMarkdown
                  markdownFromDb={embodiment.connectivity_notes}
                />
              </Box>

              {/* FEAGI & robot indicators */}
              {embodimentType === "user-ws-device" && (
                <Box
                  sx={{
                    mt: 2,
                    width: "100%",
                    display: "flex",
                    flexDirection: isSmOrSmaller ? "column" : "row",
                    alignItems: isSmOrSmaller ? "flex-start" : "center",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  {/* FEAGI connection */}
                  <Box
                    sx={{
                      p: isSmOrSmaller ? undefined : "3px 10px",
                      height: "55px",
                      minWidth: "250px",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      borderRadius: "4px",
                      backgroundColor: isSmOrSmaller
                        ? undefined
                        : "background.dark",
                    }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        minWidth: "70px",
                        display: "flex",
                        justifyContent: "center",
                        opacity: !sessionLoading && sessionOpen ? 1 : 0.3,
                        transition: "opacity 1s ease-in-out",
                        animation: sessionLoading
                          ? "fadeInOut 3s ease-in-out infinite alternate"
                          : "none",
                        "@keyframes fadeInOut": {
                          "0%": {
                            opacity: 0.3,
                          },
                          "50%": {
                            opacity: 1,
                          },
                          "100%": {
                            opacity: 0.3,
                          },
                        },
                      }}
                    >
                      <img
                        src={brain}
                        alt="brain"
                        style={{
                          height: "100%",
                          maxWidth: "100%",
                          objectFit: "contain",
                        }}
                        crossOrigin="anonymous"
                      />
                    </Box>
                    <Button
                      variant={
                        !sessionLoading && sessionOpen
                          ? "outlined"
                          : "contained"
                      }
                      size="small"
                      sx={{
                        pointerEvents:
                          !sessionLoading && sessionOpen ? "none" : "auto",
                      }}
                      onClick={handleSessionClick}
                    >
                      {sessionLoading
                        ? "Awakening..."
                        : sessionOpen
                        ? "Brain Activated"
                        : "Awaken Brain"}
                    </Button>
                  </Box>

                  {/* Connector */}
                  <Box
                    sx={{
                      width: "70px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={connection}
                      alt="connection"
                      style={{
                        maxWidth: "30px",
                        maxHeight: "30px",
                        objectFit: "contain",
                        opacity: !sessionLoading && sessionOpen ? 1 : 0.3,
                        transform: isSmOrSmaller ? undefined : "rotate(90deg)",
                      }}
                    />
                  </Box>

                  {/* Robot connection */}
                  <Box
                    sx={{
                      p: isSmOrSmaller ? undefined : "3px 10px",
                      height: "55px",
                      minWidth: "250px",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      borderRadius: "4px",
                      backgroundColor: isSmOrSmaller
                        ? undefined
                        : "background.dark",
                    }}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        minWidth: "70px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {embodiment?.image_url ? (
                        <img
                          src={embodiment.image_url}
                          alt={embodiment.embodiment_title}
                          style={{
                            height: "100%",
                            maxWidth: "100%",
                            objectFit: "contain",
                            opacity: physicalConnected ? 1 : 0.3,
                          }}
                          crossOrigin="anonymous"
                          onClick={() => setEmbodimentInfoOpen(true)}
                        />
                      ) : (
                        <>Robot</>
                      )}
                    </Box>
                    <Button
                      variant={
                        !sessionLoading && sessionOpen
                          ? "contained"
                          : "outlined"
                      }
                      size="small"
                      onClick={handleConnectClaim}
                      disabled={sessionLoading || !sessionOpen}
                      title={
                        !sessionLoading && sessionOpen
                          ? ""
                          : "First awaken the brain"
                      }
                    >
                      {physicalConnected
                        ? "Robot Connected"
                        : "Test Connection"}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          }
        />
      )}

      {/* Embodiment info modal */}
      {embodimentInfoOpen && (
        <PlayEmbodimentModal
          accessToken={accessToken}
          embodiment={embodiment}
          handleClose={() => setEmbodimentInfoOpen(false)}
          shouldNavigate={false}
        />
      )}
    </Box>
  );
};

export default PlayConnected;
