export function logger(...args) {
  if (
    process.env.REACT_APP_NODE_ENV === "staging" ||
    process.env.REACT_APP_NODE_ENV === "local"
  ) {
    console.log(...args);
  }
}

export function localLogger(...args) {
  if (process.env.REACT_APP_NODE_ENV === "local") {
    console.log(...args);
  }
}
