import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Webcam from "../embodiments-code/Webcam";
import gestureGuy from "../../img/gesture-guy.png";

const PlayWebcam = ({
  config,
  setSnackbar,
  isAbove2XL,
  sendMessage,
  physicalConnected,
  sessionId,
  clusterId,
}) => {
  const [webcamOpen, setWebcamOpen] = useState(false);

  const customSetError = (message) => {
    setSnackbar({ message, severity: "error" });
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        maxHeight: isAbove2XL ? undefined : "400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: { xs: "center", md: "flex-start" },
        gap: 2,
      }}
    >
      <Typography
        variant="h3"
        sx={{ fontSize: { xs: "1rem", md: "1.3rem" }, fontWeight: "600" }}
      >
        Gesture Monitor
      </Typography>
      {webcamOpen ? (
        <Webcam
          webcamOpen={webcamOpen}
          setWebcamOpen={setWebcamOpen}
          setError={customSetError}
          configFromDb={config}
          isEmbedded={true}
          sendMessage={sendMessage}
          sessionId={sessionId}
          clusterId={clusterId}
        />
      ) : (
        <Box
          sx={{
            position: "relative",
            height: "100%",
            width: "100%",
            minHeight: "200px",
            maxWidth: isAbove2XL ? "900px" : "600px",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            // borderRadius: "5px",
            // border: "1px solid #343434",
            // backgroundColor: "#191c1f",
          }}
        >
          <img // not using bgimage so this can be easily switched to an MUI icon or anything
            src={gestureGuy}
            alt="Webcam with grid areas highlighted by a cartoon man moving his hands"
            crossOrigin="anonymous"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
          <Button
            size="small"
            variant="contained"
            sx={{ mt: { xs: 1, md: 2 } }}
            onClick={() => setWebcamOpen(true)}
            disabled={physicalConnected === false}
          >
            Open Camera
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PlayWebcam;
