import { Link } from "react-router-dom";
import { Box, Button, Container, Typography } from "@mui/material";
import Section from "../components/Section";
import Meta from "./../components/Meta";
import SectionHeader from "../components/SectionHeader";
import disappointedRobot from "../img/robot-bewildered-transparent.png";

function NotFoundPage(props) {
  return (
    <>
      <Meta title="404" />
      <Section>
        <Container
          maxWidth="md"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SectionHeader
            title="Page Not Found"
            subtitle=""
            size={4}
            textAlign="center"
            sx={{
              backgroundColor: "rgb(16, 18, 20) !important",
              color: "rgb(255, 255, 255) !important",
            }}
          />
          <Box
            marginTop="10px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ color: "rgb(255, 255, 255)" }}
          >
            <Typography>The requested page doesn't seem to exist.</Typography>
            <Box sx={{ width: { xs: "300px", md: "400px" }, display: "flex" }}>
              <img
                src={disappointedRobot}
                alt="bewildered robot"
                style={{ width: "100%", objectFit: "contain" }}
              />
            </Box>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button variant="outlined">Go home</Button>
            </Link>
          </Box>
        </Container>
      </Section>
    </>
  );
}

export default NotFoundPage;
