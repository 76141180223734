import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UserBadge from "./UserBadge";

const BadgePopup = ({ badge, open, onClose }) => {
  if (!open || !badge || !badge.img_url) return null;

  return (
    <Box
      sx={{
        zIndex: 10000,
        position: "fixed",
        right: 16,
        bottom: 16,
        maxWidth: 250,
        boxShadow: 3,
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid",
        borderColor: "accents.dark",
        borderRadius: "5px",
        backgroundColor: "background.superDark",
      }}
    >
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography sx={{ fontSize: "1rem", fontWeight: "500" }}>
          New achievement!
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
          sx={{ color: "accents.light" }}
        >
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </Box>
      <UserBadge badge={badge} />
    </Box>
  );
};

export default BadgePopup;
