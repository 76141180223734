import { logger } from "../util/logger";
import { apiRequestExternal } from "./apiRequestExternal";
import { apiValidateArguments } from "./apiValidateArguments";

// Get brain region titles
export async function getRegionTitles(sessionId, clusterId) {
  try {
    // Validate args
    apiValidateArguments({ sessionId, clusterId });

    // Return custom response data
    const response = await apiRequestExternal(
      `https://${sessionId}-feagi.${clusterId}.neurorobotics.studio/v1/region/region_titles`,
      "GET",
      null,
      null
    );

    logger("Response in getRegionTitles function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in getRegionTitles function:", error);
    throw error;
  }
}

// Export brain region
export async function exportRegion(
  accessToken,
  sessionId,
  genomeId,
  regionId,
  regionTitle
) {
  try {
    // Validate args
    apiValidateArguments({
      accessToken,
      sessionId,
      genomeId,
      regionId,
      regionTitle,
    });

    // Return custom response data
    const response = await apiRequestExternal(
      `${process.env.REACT_APP_COMPOSER_URL}/v1/public/regional/auth/user/genomes/export_brain_region`,
      "POST",
      {
        session_id: sessionId,
        experiment_genome_id: genomeId,
        brain_region_id: regionId,
        brain_region_title: regionTitle,
      },
      { Authorization: accessToken }
    );

    logger("Response in exportRegion function:", response);
    return response;

    // Handle errors
  } catch (error) {
    console.error("Error in exportRegion function:", error);
    throw error;
  }
}
