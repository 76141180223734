import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import NewExperimentGenome from "../../new-experiment/NewExperimentGenome";

const GenomeChange = ({
  genomeChangeOpen,
  handleGenomeChangeClose,
  error,
  setError,
}) => {
  return (
    <Dialog
      open={genomeChangeOpen}
      onClose={handleGenomeChangeClose}
      aria-labelledby="genome-change-title"
      maxWidth="lg"
    >
      <DialogTitle id="genome-change-title">
        {"Replace Genome"}
        <IconButton
          style={{ position: "absolute", right: 7, top: 7, padding: 3 }}
          onClick={handleGenomeChangeClose}
          size="small"
        >
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          minWidth: { xs: "95vw", md: "960px" },
        }}
      >
        <NewExperimentGenome
          error={error}
          setError={setError}
          handleSeedChangeClose={handleGenomeChangeClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default GenomeChange;
