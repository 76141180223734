import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import DisplayMarkdown from "../../../util/DisplayMarkdown";
import { getEmbodimentConnectivityInstructions } from "../../../api/embodimentManagement";

const EmbodimentInstructionsBluetooth = ({
  accessToken,
  browser,
  embodimentName,
  embodimentId,
  instructionsOpen,
  setIsBluetoothOpen,
  handleClose,
  setError,
}) => {
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    async function fetchInstructions() {
      try {
        const res = await getEmbodimentConnectivityInstructions(
          accessToken,
          embodimentId
        );
        const markdownFromDb = res.data;
        setMarkdown(markdownFromDb);
      } catch (err) {
        console.error(err);
        setError(
          "Sorry, there was an error fetching the instructions. Please reload if the issue persists."
        );
      }
    }

    accessToken && embodimentId && fetchInstructions();
  }, [accessToken, embodimentId, setError]);

  function handleConnect() {
    setIsBluetoothOpen(true);
    handleClose();
  }

  return (
    <Dialog
      open={markdown && instructionsOpen}
      onClose={handleClose}
      aria-labelledby="embodiment-title"
      maxWidth="lg"
    >
      <DialogTitle
        id="embodiment-title"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Robot icon & title text */}
        <Box display="flex" gap="5px">
          <FontAwesomeIcon
            icon={faRobot}
            style={{ color: "#616161", marginRight: "10px" }}
          />
          <Typography>{embodimentName} Connection Instructions</Typography>
        </Box>
        {/* Close button */}
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Instructions from db */}
      <DialogContent style={{ minWidth: "40vw" }}>
        {(browser === "Safari" || browser === "Firefox") && (
          <Alert severity="warning" sx={{ width: "100%", mb: 2 }}>
            As of our last knowledge, your browser does not support Bluetooth
            connections. If connection does not work, please switch to Chrome.
          </Alert>
        )}
        <DisplayMarkdown markdownFromDb={markdown} />
      </DialogContent>

      {/* Confirm/cancel */}
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleConnect} variant="contained" autoFocus>
          Connect
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmbodimentInstructionsBluetooth;
