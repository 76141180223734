import { useState, useRef, useLayoutEffect, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "../styles/theme";
// Top-level error catcher
import ErrorBoundary from "../components/ErrorBoundary";
// Utils
import { AuthProvider } from "./../util/auth";
import { setBadgeUpdater } from "../util/badgeUpdater";
import AppGuts from "../components/AppGuts";
import { setSnackbarUpdater } from "../util/snackbarUpdater";

function App() {
  // Badges
  const [badges, setBadges] = useState(null);
  const [snackbar, setSnackbar] = useState({ message: null, severity: "info" });

  // Give global access to badge setter
  useEffect(() => {
    setBadgeUpdater(setBadges);
    setSnackbarUpdater(setSnackbar);
  }, []);

  // Navbar height to calculate iFrame to fill 100% combined
  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarRef = useRef();

  useLayoutEffect(() => {
    if (navbarRef && navbarRef.current) {
      setNavbarHeight(navbarRef.current.offsetHeight);
    }
  }, [navbarRef]);

  return (
    <ErrorBoundary>
      {/* <QueryClientProvider> */}
      <ThemeProvider>
        <AuthProvider>
          <BrowserRouter>
            <AppGuts
              badges={badges}
              setBages={setBadges}
              snackbar={snackbar}
              setSnackbar={setSnackbar}
              navbarRef={navbarRef}
              navbarHeight={navbarHeight}
            />
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
      {/* </QueryClientProvider> */}
    </ErrorBoundary>
  );
}

export default App;
