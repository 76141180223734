import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Star, StarBorder, Whatshot } from "@mui/icons-material";

const ContactForm = ({ title, fields, onSubmit }) => {
  const [formValues, setFormValues] = useState(
    fields.reduce((obj, item) => ({ ...obj, [item.name]: "" }), {})
  );
  const [error, setError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const isAllRequiredFilled = fields.every((field) => {
    if (!field.required) return true;
    const value = formValues[field.name];
    return field.type === "rating" ? value > 0 : value;
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!isAllRequiredFilled) {
        throw new Error("Please fill all required fields.");
      }
      await onSubmit(formValues);
      setIsSubmitted(true);
    } catch (err) {
      console.error(err);
      setError(err.message || "Sorry, an error occurred. Please retry.");
    }
  };

  if (isSubmitted) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Typography sx={{ marginTop: "100px", fontSize: "1.3rem" }}>
          Thank you for your message!
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        mt: 2,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Typography variant="h4">{title}</Typography>

      {error && (
        <Alert severity="error" sx={{ maxWidth: "600px" }}>
          {error}
        </Alert>
      )}

      <form
        onSubmit={handleSubmit}
        style={{
          width: "100%",
          maxWidth: "600px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        {fields.map((field) => {
          if (field.type === "select") {
            return (
              <Box key={field.name}>
                <InputLabel id={`${field.name}-label`}>
                  {field.label}
                </InputLabel>
                <Select
                  labelId={`${field.name}-label`}
                  name={field.name}
                  value={formValues[field.name]}
                  required={field.required}
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                >
                  {field.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            );
          } else if (field.type === "rating") {
            return (
              <Box key={field.name}>
                <Typography component="legend">
                  {field.label}
                  {field.required ? " *" : ""}
                </Typography>
                <Rating
                  name={field.name}
                  value={formValues[field.name]}
                  required={field.required}
                  size="large"
                  icon={
                    field.label === "Severity" ? (
                      <Whatshot
                        sx={{
                          color: "rgb(250 95 0)",
                        }}
                        fontSize="inherit"
                      />
                    ) : (
                      <Star fontSize="inherit" />
                    )
                  }
                  emptyIcon={
                    field.label === "Severity" ? (
                      <Whatshot
                        sx={{
                          color: "accents.main",
                        }}
                        fontSize="inherit"
                      />
                    ) : (
                      <StarBorder
                        sx={{
                          color: "accents.main",
                        }}
                        fontSize="inherit"
                      />
                    )
                  }
                  sx={{ marginTop: "5px" }}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: { name: field.name, value: newValue },
                    });
                  }}
                />
              </Box>
            );
          } else {
            return (
              <TextField
                key={field.name}
                name={field.name}
                label={field.label}
                type={field.type || "text"}
                multiline={field.type === "textarea"}
                minRows={field.type === "textarea" ? 4 : undefined}
                value={formValues[field.name]}
                required={field.required}
                inputProps={{ maxLength: 1000 }}
                onChange={handleChange}
              />
            );
          }
        })}
        <Button type="submit" variant="contained" sx={{ alignSelf: "center" }}>
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default ContactForm;
